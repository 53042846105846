import { EditorFeatureRestrictionKeySchema } from '@miri-unicorn/miricanvas-api-client';
import { z } from '@utils/functions/zod_util';

const editorFeatures = EditorFeatureRestrictionKeySchema.options;

export type EditorFeatureRestriction = z.infer<typeof EditorFeatureRestrictionKeySchema>;

// 이 아래는 2.0에서 구현하지 않는 editorFeature
const deprecatedEditorFeatures = [
  'TAB_CROWDPIC', // ! deprecated
  'TAB_DESIGNHISTORY', // ! deprecated
  'TAB_DEV_TOOL', // ! deprecated
  'TAB_ELEMENT_DEPRECATED', // ! deprecated
  'TAB_EXTRACT_TEXT_TO_CSV', // ! deprecated
  'TAB_GUIDE_BACKGROUND_COLOR_FILTER', // ! deprecated
  'TAB_IMAGE_DOWNLOAD', // ! deprecated
  'TAB_IMAGE_SEARCH', // ! deprecated
  'TAB_LAUNCHER_TEST', // ! deprecated
  'TAB_LAYER', // ! deprecated
  'TAB_MORE', // ! deprecated
  'TAB_NEW_FRAME', // ! deprecated
  'TAB_PEXELS', // ! deprecated
  'TAB_PHOTO', // ! deprecated
  'TAB_PHOTO_DEV', // ! deprecated
  'TAB_PIXABAY', // ! deprecated
  'TAB_PRODUCTOPTION', // ! deprecated
  'TAB_TEMPLATEDETAIL', // ! deprecated
  'TAB_TEST_NET', // ! deprecated
  'TAB_TEXT_SHUFFLE_PRESET', // ! deprecated
  'TAB_TEXTSTYLE_REPLACED', // ! deprecated
  'TAB_THEME_COLOR_TEST', // ! deprecated
  'TAB_UPLOAD_BULK_ELEMENT', // ! deprecated
  'TAB_TEMPLATE_CREATOR', // ! deprecated
  'TAB_VARIANT_EDITOR', // ! deprecated
  'TAB_VIDEO_GROUP\r', // ! deprecated
  'TAB_WIRESTOCK', // ! deprecated
  'TAB_ADMIN', // ! deprecated
  'TAB_BACKGROUND_AUDIO_TEST', // ! deprecated
  'TAB_BIZTEMPLATE', // ! deprecated
  'TAB_CHATTING_SAMPLE', // ! deprecated
  'TAB_CONNECTOR_LINE_SHAPE', // ! deprecated
  'NEW_MOBILE_TOOLBOX', // ! deprecated
] as const;

export const PanelKeyEditorFeatureSchema = z.enum([
  'TAB_AI_DRAWING', // AI 드로잉
  'TAB_AUDIO', // 오디오
  'TAB_BACKGROUND', // 배경
  'TAB_ELEMENT', // 요소
  'TAB_LOGO', // 로고
  'TAB_PHOTO_GROUP', // 사진
  'TAB_PICK', // 찜
  'TAB_TEMPLATE', // 템플릿
  'TAB_QRANDBARCODE', // QR / 바코드
  'TAB_TEXT', // 텍스트
  'TAB_THEME', // 테마
  'TAB_UPLOAD', // 업로드
  'TAB_VIDEO_GROUP', // 비디오
  'TAB_WORK', // 작업공간
  'TAB_CREATOR', // 크리에이터
  'TAB_DATA', // 데이터
] as const);
export type PanelKeyEditorFeature = z.infer<typeof PanelKeyEditorFeatureSchema>;

export const ElementPanelTabKeyEditorFeatureSchema = z.enum([
  'TAB_ANI',
  'TAB_CHART',
  'TAB_ELEMENT_COLLECTION',
  'TAB_ELEMENT_KEYWORD_ICON',
  'TAB_ELEMENTALL',
  'TAB_FIGURE',
  'TAB_FRAME',
  'TAB_GRID',
  'TAB_ILLUSTRATION',
  'TAB_LINE',
  'TAB_COMBINATION',
] as const);
export type ElementPanelTabKeyEditorFeature = z.infer<typeof ElementPanelTabKeyEditorFeatureSchema>;

export const BackgroundPanelTabKeyEditorFeatureSchema = z.enum([
  'TAB_BACKGROUND_IMAGE',
  'TAB_BACKGROUND_PATTERN',
] as const);

export const VideoPanelTabKeyEditorFeatureSchema = z.enum(['TAB_VIDEO', 'TAB_YOUTUBE'] as const);

export const QrAndBarcodePanelTabKeyEditorFeatureSchema = z.enum([
  'TAB_QRCODE',
  'TAB_BARCODE',
] as const);

export const TextPanelTabKeyEditorFeatureSchema = z.enum([
  'TAB_TEXTCOMBINATION',
  'TAB_TEXTSPECIAL',
  'TAB_TEXTSTYLE',
] as const);

export const LauncherParamEditorFeatureSchemaWithoutDeprecated = z.enum([
  ...editorFeatures,
  ...PanelKeyEditorFeatureSchema.options,
  ...ElementPanelTabKeyEditorFeatureSchema.options,
  ...BackgroundPanelTabKeyEditorFeatureSchema.options,
  ...VideoPanelTabKeyEditorFeatureSchema.options,
  ...QrAndBarcodePanelTabKeyEditorFeatureSchema.options,
  ...TextPanelTabKeyEditorFeatureSchema.options,
]);

export const LauncherParamEditorFeatureSchema = z.enum([
  ...LauncherParamEditorFeatureSchemaWithoutDeprecated.options,
  ...deprecatedEditorFeatures,
]);

export type LauncherParamEditorFeature = z.infer<typeof LauncherParamEditorFeatureSchema>;
