/** 패널 > 필터에서 노출되는 색상들 */
export const SearchFilterColorList: HexColor[] = [
  '#ff5252',
  '#ff9e48',
  '#FEE60D',
  '#a9ea2e',
  '#0dc84d',
  '#93f2dd',
  '#3880ff',
  '#2f41f9',
  '#995eff',
  '#ff84ea',
  '#ffffff',
  '#bfbfbf',
  '#000000',
];
