import { LauncherParamEditorFeature } from '@business/editor/launcher_param/_types/editorFeatureSchema';
import { getRootStore } from '@configs/jotai/RootProvider';
import { constantCaseToPascalCase } from '@miri-unicorn/miricanvas-utils/functions';
import { atom, useAtom, useAtomValue } from 'jotai';
import { selectAtom } from 'jotai/utils';
import { useMemo } from 'react';

export const disabledLauncherParamEditorFeatureAtom = atom<Set<LauncherParamEditorFeature>>(
  new Set<LauncherParamEditorFeature>()
);

export type IsEnabledEditorFeature<T extends LauncherParamEditorFeature> =
  `is${ConstantCaseToPascalCase<T>}Enabled`;

export const makeKeyFromEditorFeature = <T extends LauncherParamEditorFeature>(feature: T) =>
  `is${constantCaseToPascalCase(feature)}Enabled` as const;

const mapFeaturesToEnabledState = <T extends LauncherParamEditorFeature>(
  features: ReadonlyArray<T>,
  disabledFeatures: Set<T>
): { [key in IsEnabledEditorFeature<T>]: boolean } => {
  return features.reduce((map, feature) => {
    const key = makeKeyFromEditorFeature(feature);
    map[key] = !disabledFeatures.has(feature);
    return map;
  }, {} as { [key in IsEnabledEditorFeature<T>]: boolean });
};

/**
 * 디자인 오픈 옵션에 의한 에디터 기능 제약 조회
 * @example
 * const { isTextstylePresetEnabled } = useEditorFeature(['TEXTSTYLE_PRESET']);
 */
export const useEditorFeature = <T extends LauncherParamEditorFeature>(
  features: ReadonlyArray<T>
): {
  readonly [key in IsEnabledEditorFeature<(typeof features)[number]>]: boolean;
} => {
  return useAtomValue(
    useMemo(
      () =>
        selectAtom(disabledLauncherParamEditorFeatureAtom, (disabledFeatures) => {
          return mapFeaturesToEnabledState(features, disabledFeatures);
        }),
      // features기 배열인데 변경될때마다 실행 할 필요가 없어서 의도적으로 빈배열을 넣어줌.
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    )
  );
};

export const getEditorFeatureSnapshotValue = <T extends LauncherParamEditorFeature>(
  features: ReadonlyArray<T>
): {
  readonly [key in IsEnabledEditorFeature<(typeof features)[number]>]: boolean;
} => {
  const disabledFeatures = getRootStore().get(disabledLauncherParamEditorFeatureAtom);
  return mapFeaturesToEnabledState(features, disabledFeatures);
};
export const useDisabledEditorFeatureStore = () => {
  return useAtom(disabledLauncherParamEditorFeatureAtom);
};
