import { StorageKey, StorageValueWithExpiresKey } from '../constants/storageKey';
import { LogUtil } from './log_util';

export const getLocalStorageValue = (key: StorageKey) => {
  if (typeof window !== 'undefined') {
    return window.localStorage.getItem(key);
  }
};

export const getLocalStorageValueJson = <T>(key: StorageKey): Nullable<T> => {
  if (typeof window !== 'undefined') {
    const str = window.localStorage.getItem(key);
    if (!str) {
      return;
    }

    try {
      return JSON.parse(str) as Nullable<T>;
    } catch (e) {
      LogUtil.error.dev('로컬 스토리지 파싱 오류', e);
      return;
    }
  }
};

export const setLocalStorageValue = (key: StorageKey, value: Serializable) => {
  if (typeof window !== 'undefined') {
    try {
      return window.localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
      LogUtil.error.dev('로컬 스토리지 저장 오류', e);
    }
  }
};

export const removeLocalStorageValue = (key: StorageKey) => {
  if (typeof window !== 'undefined') {
    return window.localStorage.removeItem(key);
  }
};

export type LocalStorageValueWithExpires = { value: string; expires: number };
/**
 * 만료된 키인지 확인하는 함수
 * 만료되었다면 로컬스토리지에서 해당 키를 제거함
 *
 * @see 1.0 LocalStorageAgent.getValueWithExpires() 참고
 */
export const isExpiredKey = (
  key: StorageValueWithExpiresKey,
  keyFormatArgs?: KeyFormatArgs
): boolean => {
  const valueWithExpires = getLocalStorageValueJson<LocalStorageValueWithExpires>(
    keyFormatArgs ? (formatKey(key, keyFormatArgs) as StorageKey) : key
  );
  if (!valueWithExpires) {
    return true;
  }

  if (Date.now() > valueWithExpires.expires) {
    removeLocalStorageValue(key);
    return true;
  }

  return false;
};

/**
 * 기한과 함께 로컬 스토리지에 저장
 * !이때 기한에서 sec 은 1입니다.
 * 예) 현재 시각이 4분 15초이고, expiresParams.minute 이 1이면 만료일은 5분 1초입니다.
 * @see 1.0 LocalStorageAgent.setValueWithExpires() 참고
 */
export const setLocalStorageValueWithExpires = ({
  key,
  value,
  expiresParams,
  keyFormatArgs,
}: {
  key: StorageValueWithExpiresKey;
  value: string;
  expiresParams: { day?: number; hour?: number; minute?: number };
  keyFormatArgs?: KeyFormatArgs;
}) => {
  const date = new Date();
  if (expiresParams.day) {
    date.setDate(date.getDate() + expiresParams.day);
  }
  if (expiresParams.hour) {
    date.setHours(date.getHours() + expiresParams.hour);
  }
  if (expiresParams.minute) {
    date.setMinutes(date.getMinutes() + expiresParams.minute);
  }
  date.setSeconds(1);

  setLocalStorageValue(keyFormatArgs ? (formatKey(key, keyFormatArgs) as StorageKey) : key, {
    value: value,
    expires: date.getTime(),
  });
};

/**
 * @see 1.0 LocalStorageAgent.KeyFormatArgs
 */
type KeyFormatArgs = (string | number)[];

/**
 * @see 1.0 LocalStorageAgent.formatKey
 */
const formatKey = (key: string, keyFormatArgs: KeyFormatArgs): string => {
  return keyFormatArgs.reduce<string>(
    (prev, arg, idx) => prev.replace(new RegExp(`$${idx}`, 'g'), `${arg}`),
    key
  );
};
