import { useManualLoginListener } from '@business/user/_services/_hooks/useManualLoginListener';
import { useAuthenticatedUserRecentlyViewedTemplate } from '@domain/template/recently_viewed/_business/_hooks/useAuthenticatedUserRecentlyViewedTemplate';

/**
 * 수동 로그인 이벤트 발생 후 최근 본 템플릿을 업데이트하는 커스텀 훅
 */
export const useUpdateRecentlyViewedTemplateManualLogin = () => {
  const { updateAfterLogin } = useAuthenticatedUserRecentlyViewedTemplate();
  useManualLoginListener({ onSuccess: updateAfterLogin });
};
