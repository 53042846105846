import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import { BackgroundDesignResourceType, Tier } from '@miri-unicorn/miricanvas-api-client';
import {
  BACKGROUND_TAB_TYPE,
  BackgroundTabKey,
} from '@business/design_resource_bar/tab/_constants/backgroundTabType';
import { getRootStore } from '@configs/jotai/RootProvider';
import { SearchFilterColorList } from '@ui/common/_constants/SearchFilterColorList';
import { CustomColorId, getFilterColorById } from '@ui/common/_utils/color_filter_util';
import { SearchType } from '@ui/design/_common/_type/leftPanelSearchType';
import { panelSearchKeywordAtomFamily } from '@ui/design/_common/design_resource_panel/_stores/_common/panelSearchKeywordAtomFamily';
import { isSearchingRecentPanelAtomFamily } from '@ui/design/_common/design_resource_panel/_stores/_common/isSearchingRecentInPanelAtomFamily';

/** 배경 패널의 색상칩에서 표현되는 기본 색상 */
const DEFAULT_PAGE_BACKGROUND_COLOR = '#ffffff';
const DEFAULT_BACKGROUND_TAB = 'PICTURE';

const keywordAtom = panelSearchKeywordAtomFamily('BACKGROUND');
const typeListAtom = atom<BackgroundDesignResourceType[]>(['BACKGROUND_PICTURE']);
const filteredColorIdxAtom = atom<number | CustomColorId | null>(null);
const filteredCustomColorAtom = atom<string | null>(null);
const tierListAtom = atom<Tier[]>([]);
/** 캔버스에 현재 페이지의 색상 */
const pageBackgroundColorAtom = atom<string>(DEFAULT_PAGE_BACKGROUND_COLOR);
const backgroundTabAtom = atom<BackgroundTabKey>(DEFAULT_BACKGROUND_TAB);
const canEditBackgroundAtom = atom<boolean>(false);

const backgroundSearchAtom = atom<SearchType<BackgroundDesignResourceType>>((get) => ({
  keyword: get(keywordAtom),
  typeList: get(typeListAtom),
  color:
    getFilterColorById(
      SearchFilterColorList,
      get(filteredCustomColorAtom),
      get(filteredColorIdxAtom)
    )?.replace(/^#/g, '') ?? '',
  /** 임시처리 추가 필요 */
  // tierList: get(tierListAtom),
}));

const isSearchingRecentAtom = isSearchingRecentPanelAtomFamily('BACKGROUND');

export const useBackgroundSearchKeywordStore = () => {
  const [backgroundKeyword, setBackgroundKeyword] = useAtom(keywordAtom);
  return {
    backgroundKeyword,
    setBackgroundKeyword,
  };
};

export const getBackgroundSearchKeywordSnapshotValue = () => getRootStore().get(keywordAtom);

export const useBackgroundTabStore = () => {
  const [selectedTab, setSelectedTab] = useAtom(backgroundTabAtom);

  const selectedTabType = BACKGROUND_TAB_TYPE.find((tab) => tab.tabKey === selectedTab)!;

  return {
    selectedTab,
    selectedTabType,
    setSelectedTab,
  };
};

export const getBackgroundTabSnapshotValue = () => getRootStore().get(backgroundTabAtom);

export const useBackgroundTypeListStore = () => {
  const [backgroundTypeList, setBackgroundTypeList] = useAtom(typeListAtom);
  return { backgroundTypeList, setBackgroundTypeList };
};

export const useBackgroundTierListStore = () => {
  const [tierList, setTierList] = useAtom(tierListAtom);
  return { tierList, setTierList };
};

/** 검색 시, 사용되는 필터의 색상 */
export const useBackgroundColorStore = () => {
  const [filteredColorIdx, setFilteredColorIdx] = useAtom(filteredColorIdxAtom);
  const [filteredCustomColor, setFilteredCustomColor] = useAtom(filteredCustomColorAtom);
  return {
    filteredColorIdx,
    setFilteredColorIdx,
    filteredCustomColor,
    setFilteredCustomColor,
  };
};

/** 캔버스에 현재 페이지의 색상 */
export const usePageBackgroundColorStore = () => {
  const [pageBackgroundColor, setPageBackgroundColor] = useAtom(pageBackgroundColorAtom);

  return { pageBackgroundColor, setPageBackgroundColor };
};

export const useCanEditBackgroundStore = () => {
  const [canEditBackground, setCanEditBackground] = useAtom(canEditBackgroundAtom);

  return { canEditBackground, setCanEditBackground };
};

export const useBackgroundRecentSearchStore = () => {
  const [isSearchingRecent, setIsSearchingRecent] = useAtom(isSearchingRecentAtom);

  return {
    isSearchingRecent,
    setIsSearchingRecent,
  };
};

export const useBackgroundSearchStore = () => {
  const backgroundSearch = useAtomValue(backgroundSearchAtom);
  const backgroundKeyword = useAtomValue(keywordAtom);
  const backgroundColorIdx = useAtomValue(filteredColorIdxAtom);
  const backgroundTierList = useAtomValue(tierListAtom);

  const [isSearchingRecent, setIsSearchingRecent] = useAtom(isSearchingRecentAtom);
  const isSearching =
    Boolean(backgroundColorIdx) ||
    Boolean(backgroundKeyword) ||
    Boolean(backgroundTierList?.length) ||
    isSearchingRecent;

  return {
    isSearchingRecent,
    backgroundSearch,
    isSearching,
    setIsSearchingRecent,
  };
};

export const useSetBackgroundPanelTab = () => useSetAtom(backgroundTabAtom);
