import { useEventListener } from '@event/_hooks/useEventListener';
import { getSignInType } from '@configs/google_analytics/features/modal/signin/utils/signInType';

/**
 * 미캔 로그인 방식은 UI를 통한 수동 로그인 방식과 자동 로그인 방식이 존재한다.
 * 해당 훅은 UI를 통한 수동 로그인 방식을 감지하는 훅이다.
 * 즉, 자동 로그인은 감지하지 않는다.
 */
export const useManualLoginListener = ({ onSuccess }: { onSuccess: () => void }) => {
  /**
   * UI를 통한 수동 로그인
   */
  useEventListener('ACTION_SIGN_IN_USER', (params) => {
    if (params.data.type === '수동') {
      onSuccess();
    }
  });

  /**
   * 회원가입으로 인한 수동 로그인
   * 소셜 회원가입은 즉시 로그인까지 완료되므로, 해당 경우를 위한 조건
   */
  useEventListener('ACTION_SIGN_UP_USER', (params) => {
    if (getSignInType(params.data.social_type) === '수동' && params.data.social_type !== 'EMAIL') {
      onSuccess();
    }
  });
};
