"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _OnChangeWebUserSettingPayload = require("./OnChangeWebUserSettingPayload");
Object.keys(_OnChangeWebUserSettingPayload).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _OnChangeWebUserSettingPayload[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _OnChangeWebUserSettingPayload[key];
    }
  });
});