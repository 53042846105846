import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';

export const makeGAParamsActionClickTemplateWorkspace: GAParameterMakerFnType<
  'ACTION_CLICK_TEMPLATE_WORKSPACE'
> = (payload) => {
  return {
    log_type: 'action_template_workspace',
    event: 'action_template_workspace',
    search_status: payload.data.search_status,
    action_item: payload.data.action_item.toString(),
    search_type: payload.data.search_type,
    search_template_type: payload.data.search_template_type,
    search_term: payload.data.search_term,
    license: payload.data.license,
    priority: payload.data.priority.toString(),
    priority2: payload.data.priority2.toString(),
    designer: payload.data.designer,
    source_term: payload.data.source_term,
    search_keyword_category: payload.data.search_keyword_category,
    account_id: payload.data.account_id?.toString(),
    plan_type: payload.data.plan_type,
    team_idx: payload.data.team_idx?.toString(),
    source_tab: payload.data.source_tab,
  };
};
