import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { GAParam } from '@configs/google_analytics/types/GACommonTypes';
import {
  GTMTriggerEventParams,
  GTMUserParams,
} from '@configs/google_analytics/types/GTMTriggerEventParams';

export const makeGAParamsOnboardingTutorialUploadStep: GAParameterMakerFnType<
  'ACTION_ONBOARDING_TUTORIAL_UPLOAD_STEP'
> = (payload) => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '온보딩튜토리얼',
    location3: '3단계',
    location4: payload.data.location4,
    action1: payload.data.action1,
    label1: `에디터_튜토리얼말풍선_업로드_${payload.data.label1}`,
  } as ActionOnboardingTutorialElementReturnPayload;
};

export type ActionOnboardingTutorialElementReturnPayload = GAParam<
  GTMTriggerEventParams & GTMUserParams
>;
