export const pxToPt = (pxValue: number, dpi = 96) => {
  const ptValue = (pxValue * 72) / dpi;
  return ptValue;
};

export const ptToPx = (ptValue: number, dpi = 96) => {
  const pxValue = (ptValue * dpi) / 72;
  return pxValue;
};

export const convert = (nowValue: number, min: number, max: number) => {
  const oldRange = max - min;
  return {
    to: (_min: number, _max: number) => {
      const newRange = _max - _min;
      return ((nowValue - min) * newRange) / oldRange + min;
    },
  };
};
export const convertInch = (unit: string, width: number, height: number) => {
  if (unit === 'MM') {
    return mmToInch(width, height);
  } else if (unit === 'PX') {
    return pxToInch(width, height);
  } else if (unit === 'CM') {
    return cmToInch(width, height);
  }
  return { newWidth: width, newHeight: height };
};

export const mmToInch = (width: number, height: number) => {
  const newWidth = width / 25.4;
  const newHeight = height / 25.4;
  return { newWidth, newHeight };
};

export const cmToInch = (width: number, height: number) => {
  const newWidth = width / 2.54;
  const newHeight = height / 2.54;
  return { newWidth, newHeight };
};

export const pxToInch = (width: number, height: number, dpi = 96) => {
  const newWidth = width / dpi;
  const newHeight = height / dpi;
  return { newWidth, newHeight };
};

export const inchToMm = (width: number, height: number) => {
  const newWidth = width * 25.4;
  const newHeight = height * 25.4;
  return { newWidth, newHeight };
};

export const inchToCm = (width: number, height: number) => {
  const newWidth = width * 2.54;
  const newHeight = height * 2.54;
  return { newWidth, newHeight };
};

export const inchToPx = (width: number, height: number, dpi = 96) => {
  const newWidth = width * dpi;
  const newHeight = height * dpi;
  return { newWidth, newHeight };
};
