import { useGetUserSnapshot } from '@business/user/_queries/useUserQuery';
import { getSelectedTeamIdxFromUser } from '@business/user/_services/_pures/getSelectedTeamIdxFromUser';
import { getIsLogin } from '@business/user/_utils/getIsLogin';
import { Login200 } from '@miri-unicorn/miricanvas-api-client';
import { queryKeys } from '@configs/react-query/queryKeys';
import { QueryClient } from '@configs/react-query';

/**
 * useCurrentUser의 스냅샷 버전
 */
export const useGetCurrentUserSnapshotValue = () => {
  const getUserSnapshot = useGetUserSnapshot();

  const getCurrentUserSnapshotValue = () => {
    const user = getUserSnapshot();
    const isLogin = getIsLogin(user);

    const selectedTeamIdx = getSelectedTeamIdxFromUser(user);
    const currentPlan = user?.teamList.find((team) => team.idx === selectedTeamIdx)?.plan || 'FREE';
    const email = user?.email;
    const emailReception = user?.emailReception;

    return {
      isLogin,
      accountId: user?.accountId,
      selectedTeamIdx,
      currentPlan,
      email,
      emailReception,
    };
  };

  return { getCurrentUserSnapshotValue };
};

/**
 * currentUser의 스냅샷 버전
 */
export const getCurrentUserSnapshotValue = (queryClient: QueryClient) => {
  const user = queryClient.getQueryData<Login200>(queryKeys.getUser())?.data;

  const isLogin = getIsLogin(user);

  const selectedTeamIdx = getSelectedTeamIdxFromUser(user);
  const currentPlan = user?.teamList.find((team) => team.idx === selectedTeamIdx)?.plan || 'FREE';
  const email = user?.email;
  const emailReception = user?.emailReception;

  return {
    isLogin,
    accountId: user?.accountId,
    selectedTeamIdx,
    currentPlan,
    email,
    emailReception,
  };
};
