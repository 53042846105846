import { LanguageType } from '../constants/LanguageType';
import {
  formatDateToLocaleString as _formatDateToLocaleString,
  toFormattedVersionString as _toFormattedVersionString,
} from './date_util';

export const formatDateToLocaleString = (
  date: Date,
  lang: LanguageType,
  option?: Intl.DateTimeFormatOptions
): string => {
  return _formatDateToLocaleString(date, lang, option) || '';
};

export const toFormattedVersionString = (
  date: Date,
  lang: LanguageType,
  option?: Intl.DateTimeFormatOptions
): string => {
  return _toFormattedVersionString(date, lang, option);
};
