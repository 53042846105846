import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { GAParam } from '@configs/google_analytics/types/GACommonTypes';
import { GTMTriggerEventParams } from '@configs/google_analytics/types/GTMTriggerEventParams';

export const makeGAParamsClickItemTemplateKeywordSurvey: GAParameterMakerFnType<
  'ACTION_CLICK_ITEM_TEMPLATE_KEYWORD_SURVEY'
> = ({ data: { location1, location4 } }) => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1,
    location2: '고객정보수집팝업',
    location3: '2단계',
    location4,
    action1: '클릭',
    label1: `고객정보수집팝업_키워드선택_항목_클릭`,
  } as TemplateKeywordSurveyReturnPayload;
};

type TemplateKeywordSurveyReturnPayload = GAParam<GTMTriggerEventParams>;
