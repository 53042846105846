const relationOperationMap = {
  gt: (a: number, b: number) => a > b,
  ge: (a: number, b: number) => a >= b,
  lt: (a: number, b: number) => a < b,
  le: (a: number, b: number) => a <= b,
  eq: (a: number, b: number) => a === b,
  ne: (a: number, b: number) => a !== b,
} as const;

export type RelationOperator = keyof typeof relationOperationMap;

export const calcRelationOperation = (a: number, b: number, operator: RelationOperator) => {
  return relationOperationMap[operator](a, b);
};
