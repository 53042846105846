import { atom, useAtom, useSetAtom } from 'jotai';
import { ElementTabKey } from '@business/design_resource_bar/tab/_constants/elementTabType';
import { useElementTabTypes } from '@business/design_resource_bar/tab/_services/_hooks/useElementTabTypes';
import { getRootStore } from '@configs/jotai/RootProvider';

export const selectedElementTabAtom = atom<ElementTabKey>('ELEMENTALL');

export const useElementPanelTabStore = () => {
  const [selectedTab, setSelectedTab] = useAtom(selectedElementTabAtom);
  const elementTabTypes = useElementTabTypes();

  const selectTab = (tabKey: ElementTabKey) => {
    setSelectedTab(tabKey);
  };

  const currentTabType =
    elementTabTypes.find((tab) => tab.tabKey === selectedTab) ?? elementTabTypes[0];

  return {
    selectedTab,
    selectTab,
    currentTabType,
  };
};

export const useSetElementPanelTab = () => useSetAtom(selectedElementTabAtom);

export const getElementTabKeySnapshotValue = () => getRootStore().get(selectedElementTabAtom);
