import { atom } from 'jotai';
import { DesignResourceDetailData } from '@business/design_resource/_types/designResourceDetailData';
import { atomFamilyWithAllowedKeys } from '@configs/jotai/utils/atomFamilyWithAllowedKeys';
import { DesignResourcePanelMenuType } from '@constants/DesignResourcePanelMenu';

const SIMILAR_RESOURCE_SUPPORTED_PANEL_LIST = [
  'AUDIO',
  'BACKGROUND',
  'DATA',
  'ELEMENTS',
  'PICTURE',
  'TEXT',
  'VIDEO',
] as const satisfies readonly DesignResourcePanelMenuType[];

export type SimilarResourceSupportedPanelType =
  (typeof SIMILAR_RESOURCE_SUPPORTED_PANEL_LIST)[number];

export const isSimilarResourceSupportedPanelType = (
  value: unknown
): value is SimilarResourceSupportedPanelType =>
  SIMILAR_RESOURCE_SUPPORTED_PANEL_LIST.includes(value as SimilarResourceSupportedPanelType);

export const {
  atomFamily: similarResourceTargetAtomFamily,
  writeOnlyByKeyAtom: similarResourceTargetWriteOnlyAtom,
} = atomFamilyWithAllowedKeys(
  SIMILAR_RESOURCE_SUPPORTED_PANEL_LIST,
  () => atom<DesignResourceDetailData | null>(null),
  null
);
