import { RequestParamKeyEnum } from '@business/design/_types/requestParamKey';
import { useRouter } from '@configs/next_ssr';
import { LogUtil } from '@miri-unicorn/miricanvas-utils/functions';
import { useEffect } from 'react';

export const useFixedAbCase = () => {
  const { query } = useRouter();

  useEffect(() => {
    if (query[RequestParamKeyEnum.AB_CASE] !== undefined) {
      if (isNaN(Number(query[RequestParamKeyEnum.AB_CASE]))) {
        LogUtil.error.dev('AB_CASE TYPE ERROR', 'AB_CASE is not a number');
        sessionStorage.setItem('abCase', '0');
        return;
      }

      sessionStorage.setItem('abCase', Number(query[RequestParamKeyEnum.AB_CASE]).toString());
      // removeQueryString(RequestParamKeyEnum.AB_CASE);
    }
  }, [query]);
};
