import { DesignResourceGAEventPayloadByPanel } from '@configs/google_analytics/features/design_resource/_types/designResourceGAPayloadType';
import {
  SearchType,
  SearchTypeMap,
} from '@configs/google_analytics/features/design_resource/_types/designResourceSearchType';

/**
 * 뷰: 검색어 없이 필터만 있음
 *   - 검색어가 없는 경우
 *   - search_type이 '비슷한리소스미리보기'인 경우
 * 검색: 사용자가 능동적으로 검색하는 경우
 *   - 검색어가 세팅된 경우
 *   - 비슷한 리소스 찾기를 수행한 경우 (search_type이 '비슷한리소스찾기' 혹은 '비슷한리소스찾기더보기')
 */
export const getSearchStatus = ({
  searchType,
  searchTerm,
}: {
  searchTerm: string;
  searchType: SearchType;
}): DesignResourceGAEventPayloadByPanel['search_status'] => {
  if (MANUAL_SEARCH_TYPE_LIST.includes(searchType)) {
    return '검색';
  }
  if (VIEW_SEARCH_TYPE_LIST.includes(searchType)) {
    return '뷰';
  }
  return Boolean(searchTerm) ? '검색' : '뷰';
};

// 무조건 '검색' 타입이 되는 서치 타입 목록
const MANUAL_SEARCH_TYPE_LIST = [
  SearchTypeMap.FIND_SIMILAR_RESOURCE,
  SearchTypeMap.MORE_SIMILAR_RESOURCE,
] as const satisfies SearchType[];

// 무조건 '뷰' 타입이 되는 서치 타입 목록
const VIEW_SEARCH_TYPE_LIST = [
  SearchTypeMap.PREVIEW_SIMILAR_RESOURCE,
] as const satisfies SearchType[];
