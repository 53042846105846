import _capitalize from 'lodash-es/capitalize';
import _chunk from 'lodash-es/chunk';
import _cloneDeep from 'lodash-es/cloneDeep';
import _debounce from 'lodash-es/debounce';
import _flatten from 'lodash-es/flatten';
import _isArray from 'lodash-es/isArray';
import _isEmpty from 'lodash-es/isEmpty';
import _isEqual from 'lodash-es/isEqual';
import _isNull from 'lodash-es/isNull';
import _isNumber from 'lodash-es/isNumber';
import _isUndefined from 'lodash-es/isUndefined';
import _kebabCase from 'lodash-es/kebabCase';
import omitBy from 'lodash-es/omitBy';
import _padEnd from 'lodash-es/padEnd';
import _pick from 'lodash-es/pick';
import _reject from 'lodash-es/reject';
import _round from 'lodash-es/round';
import _shuffle from 'lodash-es/shuffle';
import _snakeCase from 'lodash-es/snakeCase';
import _sortBy from 'lodash-es/sortBy';
import _throttle from 'lodash-es/throttle';
import _union from 'lodash-es/union';
import _uniqBy from 'lodash-es/uniqBy';
import _xor from 'lodash-es/xor';

type List<T> = ArrayLike<T>;

// 이렇게 사용하는게 의미가 있나? lodash-es 를 바로 사용하는게 더 좋지 않을까?
// 여기함수 import하면 이파일 전체를 가져와서 사용하는거라서 더 무거워질수도 있음
// 의견받아볼것
export const capitalize = _capitalize;

export const kebabCase = _kebabCase;

export const snakeCase = _snakeCase;

export const cloneDeep = _cloneDeep;

export const isEmpty = (value: unknown): boolean => _isEmpty(value);
export const isEmptyArray = (array: Array<unknown>): boolean =>
  Array.isArray(array) && isEmpty(array);
export const isEmptyObject = <T>(object: { [key: string]: T }): boolean =>
  typeof object === 'object' && object !== null && isEmpty(object);

export const isEqual = _isEqual;

// export const padStart = (string: string, length: number, chars?: string): string =>
//   _padStart(string, length, chars);

export const padEnd = (string: string, length: number, chars?: string): string =>
  _padEnd(string, length, chars);

export const isNull = <T>(value: T | null): value is null => _isNull(value);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isNumber = (value?: any): value is number => _isNumber(value);

export const isUndefined = <T>(value: T | undefined): value is undefined => _isUndefined(value);

export const reject = <T>(
  collection: T[],
  predicate?: (value: T, index: number, collection: T[]) => boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any[] => _reject(collection, predicate);
export const rejectArray = <T>(
  array: T[],
  predicate: (value: T, index: number, array: T[]) => boolean
): T[] => (Array.isArray(array) ? reject(array, predicate) : []);
export const rejectObject = <T>(
  object: { [key: string]: T },
  predicate: (value: T, key: string, object: { [key: string]: T }) => boolean
): { [key: string]: T } => {
  if (typeof object === 'object' && object !== null) {
    const keys = Object.keys(object);
    const rejectedObject: { [key: string]: T } = {};
    for (const key of keys) {
      if (!predicate(object[key], key, object)) {
        rejectedObject[key] = object[key];
      }
    }
    return rejectedObject;
  }
  return {};
};

export const shuffleArray = <T>(collection: T[]): T[] => _shuffle(collection);
export const shuffleObject = <T>(object: { [key: string]: T }): { [key: string]: T } => {
  const keys = Object.keys(object);
  const shuffledKeys = shuffleArray(keys);
  const shuffledObject: { [key: string]: T } = {};

  for (const key of shuffledKeys) {
    shuffledObject[key] = object[key];
  }

  return shuffledObject;
};

/**
 * 배열에서 중복된 값을 제거하여 리턴하는 함수
 * iteratee 를 통해 중복을 판단한다.
 */
export const uniqBy: typeof _uniqBy = (array, iteratee) => _uniqBy(array, iteratee);

/**
 * 반올림 함수
 */
export const round = (value: number, precision?: number): number => _round(value, precision);

export const throttle: typeof _throttle = (func, wait, options) => _throttle(func, wait, options);

export const debounce: typeof _debounce = (func, wait, options) => _debounce(func, wait, options);

/**
 * 배열들을 합치고 중복을 제거하는 함수
 * @example
 * _.union([2], [1, 2]); => [2, 1]
 */
export const union: typeof _union = (...arrays) => _union(...arrays);

export const xor: typeof _xor = (...arrays) => _xor(...arrays);

export const flatten: typeof _flatten = (array) => _flatten(array);
/**
 * Lodash의 _intersectionBy 함수로부터 타입을 가져와 intersectionBy를 정의합니다.
 * @param array 첫 번째 배열
 * @param values 두 번째 배열
 * @param iteratee 비교를 위한 iteratee 함수
 * @returns 두 배열 간의 교차 항목으로 이루어진 배열
 */
// TODO:jhkim06 pnpm type reference error check 일단 사용처는 lodash-es 직접 import하도록 설정
// export const intersectionBy = (
//   ...args: Parameters<typeof _intersectionBy>
// ): ReturnType<typeof _intersectionBy> => _intersectionBy(...args);

export const chunk: typeof _chunk = <T>(array: List<T>, size?: number): T[][] =>
  _chunk(array, size);

export const removeFalsyProperties = <T extends object = object>(object: T) => {
  return omitBy(object, (value) => !value);
};

export const pick: typeof _pick = _pick;

export const sortBy: typeof _sortBy = _sortBy;

export const isArray: typeof _isArray = _isArray;
