export const SearchTypeMap = {
  /** 키워드를 검색바에 직접 입력하는 케이스 */
  MANUAL: '직접입력',
  /** 검색바의 추천키워드 클릭해서 검색하는 케이스 */
  RECOMMENDED_KEYWORD: '추천키워드',
  /** 검색바의 최근검색어 클릭해서 검색하는 케이스 */
  RECENT_KEYWORD: '최근검색어',
  /** 리스트에서 더보기를 눌렀을 때 키워드가 검색바에 세팅되는 케이스 (키워드카테고리, 최근사용) */
  MORE: '더보기',
  /** 개별 리소스에 포함된 키워드를 클릭하여 검색바가 세팅되는 케이스 */
  RESOURCE_KEYWORD: '리소스할당키워드',
  /** 리소스 더보기 > 비슷한 xx 찾기 클릭하여 검색바가 세팅되는 케이스 */
  FIND_SIMILAR_RESOURCE: '비슷한리소스찾기',
  /** 비슷한 리소스 자동 추천 UI 에서 더보기 버튼을 눌렀을 때 랜딩한 2뎁스에서 노출되는 케이스 */
  MORE_SIMILAR_RESOURCE: '비슷한리소스찾기더보기',
  /** 비슷한 리소스 자동 추천 UI 안에 담겨서 노출되는 케이스 */
  PREVIEW_SIMILAR_RESOURCE: '비슷한리소스찾기미리보기',
  /** 검색바에 검색어를 입력한 상태에서 하단에 뜨는 연관검색어를 클릭해서 검색하는 케이스 */
  RELATED_KEYWORD: '연관검색어',
  /** 리소스 정보에 있는 출처를 눌러 검색하는 케이스 */
  LICENSE: '출처',
  /** 리소스 정보에 있는 크리에이터를 눌러 검색하는 케이스 */
  CREATOR: '크리에이터',
  /** 위에 해당되지 않는 케이스 */
  ETC: '기타',
} as const;

export type SearchType = ValueOf<typeof SearchTypeMap>;
