import { MIRICANVAS_RESOURCE_DOMAIN } from '@constants/ResourceDomain';
import { Icon, ThemeTokenType } from '@miri-unicorn/miricanvas-ds';

export const SocialTypes = {
  KAKAO: 'KAKAO',
  NAVER: 'NAVER',
  GOOGLE: 'GOOGLE',
  FACEBOOK: 'FACEBOOK',
  WHALE: 'WHALE',
  LINE: 'LINE',
  APPLE: 'APPLE',
} as const;

export const SocialTypeArray = Object.keys(SocialTypes);

export type SocialType = (typeof SocialTypes)[keyof typeof SocialTypes];

export const SignServiceTypes = {
  ...SocialTypes,
  EMAIL: 'EMAIL',
} as const;

export const SignServiceArray = Object.keys(SignServiceTypes);

export type SignServiceType = (typeof SignServiceTypes)[keyof typeof SignServiceTypes];

export const SocialServiceTypes = {
  ...SocialTypes,
  BIZHOWS_MICAN: 'BIZHOWS_MICAN',
  BIZHOWS_SOCIAL: 'BIZHOWS_SOCIAL',
  BIZHOWS_DIRECT: 'BIZHOWS_DIRECT',
} as const;
export const SocialServiceArray = Object.keys(SocialServiceTypes);

export type SocialServiceType = (typeof SocialServiceTypes)[keyof typeof SocialServiceTypes];

type SocialLoginDataType = {
  text: (isLogin: boolean) => string;
  bgColor: keyof ThemeTokenType | `#${string}`;
  iconSrc?: string;
  iconName?: Parameters<typeof Icon>[0]['iconName'];
  color: keyof ThemeTokenType;
  hoverColor?: keyof ThemeTokenType | `#${string}`;
};

export const SOCIAL_LOGIN_DATA: Record<SignServiceType, SocialLoginDataType> = {
  KAKAO: {
    text: (isLogin: boolean) =>
      isLogin
        ? 'SHARED.POPUP.POPUP.SIGN_IN.ACTION.KAKAO_SIGN_IN'
        : 'SHARED.POPUP.POPUP.SIGN_UP.ACTION.KAKAO_SIGN_UP',
    bgColor: '#FFE900',
    iconSrc: `${MIRICANVAS_RESOURCE_DOMAIN}image/logo/asset-kakao-symbol-96-96.png`,
    color: 's_color_static_black_darkness',
  },
  NAVER: {
    text: (isLogin: boolean) =>
      isLogin
        ? 'SHARED.POPUP.POPUP.SIGN_IN.ACTION.NAVER_SIGN_IN'
        : 'SHARED.POPUP.POPUP.SIGN_UP.ACTION.NAVER_SIGN_UP',
    bgColor: '#1EC800',
    iconSrc: `${MIRICANVAS_RESOURCE_DOMAIN}image/logo/asset-naver-symbol-48-48.png`,
    color: 's_color_contents_inverse_default',
  },
  EMAIL: {
    text: (isLogin: boolean) =>
      isLogin
        ? 'SHARED.POPUP.POPUP.SIGN_IN.ACTION.EMAIL_SIGN_IN'
        : 'SHARED.POPUP.POPUP.SIGN_UP.ACTION.EMAIL_SIGN_UP',
    bgColor: 's_color_surface_lightness',
    color: 's_color_contents_default',
    hoverColor: 's_color_surface_light',
    iconName: 'mail',
  },
  GOOGLE: {
    text: (isLogin: boolean) =>
      isLogin
        ? 'SHARED.POPUP.POPUP.SIGN_IN.ACTION.GOOGLE_SIGN_IN'
        : 'SHARED.POPUP.POPUP.SIGN_UP.ACTION.GOOGLE_SIGN_UP',
    bgColor: '#FFFFFF',
    iconSrc: `${MIRICANVAS_RESOURCE_DOMAIN}image/logo/asset-google-symbol-96-96-new.png`,
    color: 's_color_contents_default',
  },
  FACEBOOK: {
    text: (isLogin: boolean) =>
      isLogin
        ? 'SHARED.POPUP.POPUP.SIGN_IN.ACTION.FACEBOOK_SIGN_IN'
        : 'SHARED.POPUP.POPUP.SIGN_UP.ACTION.FACEBOOK_SIGN_UP',
    bgColor: '#FFFFFF',
    iconSrc: `${MIRICANVAS_RESOURCE_DOMAIN}image/logo/asset-facebook-symbol-96-96.png`,
    color: 's_color_contents_default',
  },
  WHALE: {
    text: (isLogin: boolean) =>
      isLogin
        ? 'SHARED.POPUP.POPUP.SIGN_IN.ACTION.WHALE_SIGN_IN'
        : 'SHARED.POPUP.POPUP.SIGN_UP.ACTION.WHALE_SIGN_UP',
    bgColor: '#FFFFFF',
    iconSrc: `${MIRICANVAS_RESOURCE_DOMAIN}image/logo/asset-whale-symbol-96-96.png`,
    color: 's_color_contents_default',
  },
  LINE: {
    text: (isLogin: boolean) =>
      isLogin
        ? 'SHARED.POPUP.POPUP.SIGN_IN.ACTION.LINE_SIGN_IN'
        : 'SHARED.POPUP.POPUP.SIGN_UP.ACTION.LINE_SIGN_UP',
    bgColor: '#FFFFFF',
    iconSrc: `${MIRICANVAS_RESOURCE_DOMAIN}image/logo/asset-line-symbol-48-48.png`,
    color: 's_color_contents_default',
  },
  APPLE: {
    text: (isLogin: boolean) =>
      isLogin
        ? 'SHARED.POPUP.POPUP.SIGN_IN.ACTION.APPLE_SIGN_IN'
        : 'SHARED.POPUP.POPUP.SIGN_UP.ACTION.APPLE_SIGN_UP',
    bgColor: 's_color_static_black_basic',
    iconSrc: `${MIRICANVAS_RESOURCE_DOMAIN}image/logo/asset-apple-symbol-128-128.png`,
    color: 's_color_static_white_basic',
  },
};

export const SOCIAL_LOGIN_I18N: Record<SignServiceType, string> = {
  EMAIL: 'SHARED.SOCIAL_TYPE.EMAIL',
  KAKAO: 'SHARED.SOCIAL_TYPE.KAKAO',
  NAVER: 'SHARED.SOCIAL_TYPE.NAVER',
  GOOGLE: 'SHARED.SOCIAL_TYPE.GOOGLE',
  FACEBOOK: 'SHARED.SOCIAL_TYPE.FACEBOOK',
  WHALE: 'SHARED.SOCIAL_TYPE.WHALE',
  LINE: 'SHARED.SOCIAL_TYPE.LINE',
  APPLE: 'SHARED.SOCIAL_TYPE.APPLE',
};

export const SOCIAL_TYPE_KOREAN: Record<SignServiceType, string> = {
  EMAIL: '이메일',
  KAKAO: '카카오',
  NAVER: '네이버',
  GOOGLE: '구글',
  FACEBOOK: '페이스북',
  WHALE: '웨일',
  LINE: '라인',
  APPLE: '애플',
};
