import { GAParam } from '@configs/google_analytics/types/GACommonTypes';
import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { GTMTriggerEventParams } from '@configs/google_analytics/types/GTMTriggerEventParams';

type ActionClickVideoEditorPromotionBanner = GAParam<GTMTriggerEventParams>;
type ActionClickYoutubeTab = GAParam<GTMTriggerEventParams>;
type ActionClickYoutubeMoveButton = GAParam<GTMTriggerEventParams>;
type ActionClickYoutubeMakeButton = GAParam<GTMTriggerEventParams>;

export const makeGAParamsActionClickVideoEditorPromotionBanner: GAParameterMakerFnType<
  'ACTION_CLICK_VIDEO_EDITOR_PROMOTION_BANNER'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '동영상',
    location3: '동영상제작유도배너',
    action1: '클릭',
    label1: '동영상_동영상제작유도배너',
  } satisfies ActionClickVideoEditorPromotionBanner;
};

export const makeGAParamsActionClickYoutubeTab: GAParameterMakerFnType<
  'ACTION_CLICK_YOUTUBE_TAB'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '동영상',
    location3: 'YouTube 탭',
    action1: '클릭',
    label1: '동영상_YouTube탭',
  } satisfies ActionClickYoutubeTab;
};

export const makeGAParamsActionClickYoutubeMoveButton: GAParameterMakerFnType<
  'ACTION_CLICK_YOUTUBE_MOVE_BUTTON'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '동영상',
    location3: 'YouTube 탭',
    location4: 'YouTube 이동',
    action1: '클릭',
    label1: '동영상_YouTube탭_YouTube이동',
  } satisfies ActionClickYoutubeMoveButton;
};

export const makeGAParamsActionClickYoutubeMakeButton: GAParameterMakerFnType<
  'ACTION_CLICK_YOUTUBE_MAKE_BUTTON'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '동영상',
    location3: 'YouTube 탭',
    location4: '만들기',
    action1: '클릭',
    label1: '동영상_YouTube탭_만들기',
  } satisfies ActionClickYoutubeMakeButton;
};
