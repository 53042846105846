import {
  LanguageType,
  MILLI_SECONDS_OF_ONE_DAY,
  MILLI_SECONDS_OF_ONE_HOUR,
  MILLI_SECONDS_OF_ONE_MINUTE,
  MILLI_SECONDS_OF_ONE_SECOND,
} from '../constants';
import { padStart } from './string_util';

/**
 * 초를 00:00 형식으로 변환
 * @param second
 */
export const convertSecondToMinute = (second: number, minuteLength = 2): string => {
  return `${Math.floor(second / 60)
    .toString()
    .padStart(minuteLength, '0')}:${(second % 60).toString().padStart(2, '0')}`;
};

export const convertSecondToHour = (second: number): string => {
  return `${Math.floor(second / 3600)
    .toString()
    .padStart(2, '0')}:${Math.floor((second % 3600) / 60)
    .toString()
    .padStart(2, '0')}:${(second % 60).toString().padStart(2, '0')}`;
};

/**
 * @see 1.0 DateUtil.ts
 *
 * 언어별 날짜 포맷 할때 date값이 Invalid Date 인경우 에러가 나던 문제 수정하는 함수.
 */
export const isValidDate = (date: Date): boolean => {
  return date instanceof Date && !isNaN(date.valueOf());
};

/**
 * @see 1.0 DateUtil.ts
 *
 * 언어별 날짜 포맷팅 함수
 * default는 yyyy년 MM월 dd일
 * @param date Date
 * @param lang LanguageType
 * @param option Intl.DateTimeFormatOptions
 * @returns string
 */
export const formatDateToLocaleString = (
  date: Date,
  lang: LanguageType,
  option: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' }
) => {
  return isValidDate(date) && Intl.DateTimeFormat(lang, option).format(date);
};

/**
 * @see 1.0 DateUtil.ts
 *
 * 년도가 바뀌었을때 사용할 날짜 포맷팅 함수
 * 일반적인 날짜 포맷팅은 formatDateToLocaleString를 사용해야함
 */
export const toFormattedVersionString = (
  date: Date,
  lang: LanguageType,
  option?: Intl.DateTimeFormatOptions
): string => {
  const todayYear = new Date().getFullYear();
  const versionYear = date.getFullYear();
  const options: Intl.DateTimeFormatOptions =
    todayYear === versionYear
      ? { month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }
      : { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
  return formatDateToLocaleString(date, lang, option ?? options) || '';
};

export const FormatKey = {
  FULL_YEAR: 'yyyy',
  YEAR: 'yy',
  MONTH: 'MM',
  DAY: 'dd',
  HOUR: 'HH',
  MINUTE: 'mm',
  SECOND: 'ss',
  MILLISECOND: 'ms',
} as const;

export const toString = (date: Date, format = 'yyyy-MM-dd HH:mm:ss:ms'): string => {
  const year = String(date.getFullYear());
  return format
    .replace(FormatKey.FULL_YEAR, year)
    .replace(FormatKey.YEAR, year.substr(2, 2))
    .replace(FormatKey.MONTH, padStart(String(date.getMonth() + 1), 2, '0'))
    .replace(FormatKey.DAY, padStart(String(date.getDate()), 2, '0'))
    .replace(FormatKey.HOUR, padStart(String(date.getHours()), 2, '0'))
    .replace(FormatKey.MINUTE, padStart(String(date.getMinutes()), 2, '0'))
    .replace(FormatKey.SECOND, padStart(String(date.getSeconds()), 2, '0'))
    .replace(FormatKey.MILLISECOND, String(Math.floor(date.getMilliseconds() / 100)));
};

export const toFormattedStringWithSecond = (date: Date): string => {
  const ss =
    String(date.getSeconds()).length > 1 ? `${date.getSeconds()}` : `0${date.getSeconds()}`;
  const dateTxt = toFormattedString(date);
  return `${dateTxt}:${ss}`;
};

// TODO: 이준희 2021/01/04 => 하나로 통합하면 좋을 것 같음
// 초 => HH:MM:SS
export const convertSecondToTimeFormat = (seconds: number, from: number, num: number): string => {
  return new Date(seconds * MILLI_SECONDS_OF_ONE_SECOND).toISOString().substr(from, num);
};

export const convertSecondToVarLengthHhMmSs = (seconds: number): string => {
  const secInHour = 3600;
  const HHMMSS: string = new Date(seconds * MILLI_SECONDS_OF_ONE_SECOND)
    .toISOString()
    .substr(11, 8);
  if (Math.floor(seconds / secInHour) > 0) {
    return HHMMSS;
  } else {
    return HHMMSS.substr(3, 7);
  }
  //00:00:00;
};

/**
 * Input => 00:01:42
 * Output => 102
 */
export const convertHhMmSsToSecond = (value: string): number => {
  const hhMmSs = value.split(':');
  if (hhMmSs.length === 3) {
    const hours = parseInt(hhMmSs[0]);
    const minutes = parseInt(hhMmSs[1]);
    const seconds = parseInt(hhMmSs[2]);
    return hours * 3600 + minutes * 60 + seconds;
  } else if (hhMmSs.length === 2) {
    const minutes = parseInt(hhMmSs[0]);
    const seconds = parseInt(hhMmSs[1]);
    return minutes * 60 + seconds;
  }
  return +value;
};

/** 1분 미만일때 초단위로 표기하고, 그 이상은 HhMmSs 단위로 표기하고 싶을때 사용하는 함수
 * 62 => 1:02
 * 42 => 42.0
 */
export const convertSecondToHhMmSsOrFloat = (second: number): string => {
  if (second >= 60) {
    return convertSecondToVarLengthHhMmSs(second).replace(/(^0)/, '');
  }
  return second.toFixed(1);
};

export const dateToString = (date: Date): string => {
  return date
    .toLocaleString('en-us', { year: 'numeric', month: '2-digit', day: '2-digit' })
    .replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2');
};

export const toFormattedString = (date: Date, delimiter: string = '.'): string => {
  const YYYY = String(date.getFullYear());
  const MM =
    String(date.getMonth() + 1).length > 1 ? `${date.getMonth() + 1}` : `0${date.getMonth() + 1}`;
  const DD = String(date.getDate()).length > 1 ? `${date.getDate()}` : `0${date.getDate()}`;
  const hh = String(date.getHours()).length > 1 ? `${date.getHours()}` : `0${date.getHours()}`;
  const mm =
    String(date.getMinutes()).length > 1 ? `${date.getMinutes()}` : `0${date.getMinutes()}`;
  const ss =
    String(date.getSeconds()).length > 1 ? `${date.getSeconds()}` : `0${date.getSeconds()}`;
  return `${YYYY}${delimiter}${MM}${delimiter}${DD} ${hh}:${mm}:${ss}`;
};

/**
 * 두 날짜가 며칠 차이인지 반환한다
 *
 * @param base
 * @param comparison
 */
export const getDifferenceBetween = (base: Date, comparison: Date): number => {
  return Math.ceil((base.getTime() - comparison.getTime()) / MILLI_SECONDS_OF_ONE_DAY);
};

export const getToday = (): string => {
  const date = new Date();
  const year: number = date.getFullYear();
  const month: number = date.getMonth() + 1;
  const day: number = date.getDate();
  return `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
};

export const getTomorrow = (): Date => {
  const now = new Date();
  return new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
};

export const getAfterAWeek = (): Date => {
  const now = new Date();
  return new Date(now.getFullYear(), now.getMonth(), now.getDate() + 7);
};

export const getNextMonth = (): Date => {
  const now = new Date();
  return new Date(now.getFullYear(), now.getMonth() + 1, now.getDate());
};

// Date 객체 month는 실제 '월-1'로 넣어줘야함
// eX) 2월 => 1, 1월 => 0
export const isOverDueDate = (today: Date, dueDate: Date): boolean => {
  return today > dueDate;
};

export const getYears = (
  startYear: number,
  endYear: number = new Date().getFullYear(),
  sort: 'ASC' | 'DESC' = 'DESC'
): number[] => {
  if (startYear > endYear) {
    throw new Error('invalid date range');
  }

  if (sort === 'ASC') {
    return new Array(endYear - (startYear - 1)).fill(startYear).map((year, idx) => year + idx);
  }

  return new Array(endYear - (startYear - 1)).fill(endYear).map((year, idx) => year - idx);
};

export const getMonths = (startMonth = 1): number[] => {
  return new Array(12).fill(0).map((_, idx) => ((startMonth - 1 + idx) % 12) + 1);
};

export const getDiffDays = (date1: Date, date2: Date) => {
  return Math.ceil((date1.getTime() - date2.getTime()) / MILLI_SECONDS_OF_ONE_DAY);
};

/**
 * @returns 두 Date의 차이(date1 - date2)를 구해 "날짜" 단위로 소수값은 "버림" 처리하여 리턴하는 함수
 * - ex) 5.5 일 => 5 리턴
 */
export const getDiffDaysFloor = (date1: Date, date2: Date): number => {
  return Math.floor((date1.getTime() - date2.getTime()) / MILLI_SECONDS_OF_ONE_DAY);
};

/**
 * @returns 두 Date의 차이(date1 - date2)를 구해 "시간" 단위로 소수값은 "버림" 처리하여 리턴하는 함수
 * - ex) 5.5 시간 => 5 리턴
 */
export const getDiffHoursFloor = (date1: Date, date2: Date): number => {
  return Math.floor((date1.getTime() - date2.getTime()) / MILLI_SECONDS_OF_ONE_HOUR);
};

/**
 * @returns 두 Date의 차이(date1 - date2)를 구해 "분" 단위로 소수값은 "버림" 처리하여 리턴하는 함수
 * - ex) 5.5 분 => 5 리턴
 */
export const getDiffMinutesFloor = (date1: Date, date2: Date): number => {
  return Math.floor((date1.getTime() - date2.getTime()) / MILLI_SECONDS_OF_ONE_MINUTE);
};

/**
 * 날짜를 표현한 문자열과 그 포맷을 입력 받으면 그 날짜에 맞는 Date 객체를 반환.
 * format 파라미터에서 생략한 날짜 포맷은 아래 문서의 "4. 개별 날짜 및 시간 구성 요소"에서 설명하는 기본 값으로 대체함.
 * @see https://developer.mozilla.org/ko/docs/Web/JavaScript/Reference/Global_Objects/Date/Date
 * @param dateString 날짜를 표현한 문자열
 * @param format 첫번째 문자열의 포맷을 나타내는 문자열
 * 년: yy 또는 yyyy
 * 월: MM
 * 일: dd
 * 시: HH
 * 분: mm
 * 초: ss
 * 밀리초: ms
 * @example
 * toDate('12/22', 'MM/yy'); // new Date(1922, 12, 1, 0, 0, 0, 0)
 */
export const toDate = (dateString: string, format: string): Date => {
  const fullYearIndex = format.indexOf(FormatKey.FULL_YEAR);
  const yearIndex = format.indexOf(FormatKey.YEAR);
  const monthIndex = format.indexOf(FormatKey.MONTH);
  const dayIndex = format.indexOf(FormatKey.DAY);
  const hourIndex = format.indexOf(FormatKey.HOUR);
  const minuteIndex = format.indexOf(FormatKey.MINUTE);
  const secondIndex = format.indexOf(FormatKey.SECOND);
  const millisecondIndex = format.indexOf(FormatKey.MILLISECOND);
  let year = 1900;
  if (fullYearIndex >= 0) {
    year = Number(dateString.substring(fullYearIndex, fullYearIndex + 4));
  } else if (yearIndex >= 0) {
    year += Number(dateString.substring(yearIndex, yearIndex + 2));
  }
  const month = monthIndex >= 0 ? Number(dateString.substring(monthIndex, monthIndex + 2)) - 1 : 0; // 월은 0부터 시작
  const day = dayIndex >= 0 ? Number(dateString.substring(dayIndex, dayIndex + 2)) : 1;
  const hour = hourIndex >= 0 ? Number(dateString.substring(hourIndex, hourIndex + 2)) : 0;
  const minute = minuteIndex >= 0 ? Number(dateString.substring(minuteIndex, minuteIndex + 2)) : 0;
  const second = secondIndex >= 0 ? Number(dateString.substring(secondIndex, secondIndex + 2)) : 0;
  const millisecond =
    millisecondIndex >= 0
      ? Number(dateString.substring(millisecondIndex, millisecondIndex + 2))
      : 0;
  return new Date(year, month, day, hour, minute, second, millisecond);
};

/**
 * 0: 같은 시간
 * 1: 24시간 지나기 전
 * n: 24시간 기준 초과한 일수
 * @param time 기준이 되는 시간 (보통 현재 시간)
 * @param diffTime 비교할 시간
 */
export const isOver24Hours = (time: number, diffTime: number): boolean => {
  const ONE_DAY = 1;
  return getDifferenceBetween(new Date(time), new Date(diffTime)) > ONE_DAY;
};

export const isOverDays = (date: Date, diffDate: Date): boolean => {
  if (date.getFullYear() !== diffDate.getFullYear()) {
    return date.getFullYear() > diffDate.getFullYear();
  }

  if (date.getMonth() !== diffDate.getMonth()) {
    return date.getMonth() > diffDate.getMonth();
  }

  return date.getDate() > diffDate.getDate();
};

/**
 * @see 1.0 i18nDateUtil.ts formatDateToLocaleString
 *
 * @param currentLanguage 1.0 의 "LanguageTranslator.getCurrentLanguage()" 값
 * - TODO: 추후 해당 위 currentLanguage 값 어떻게 전달할 것인지 고민 후 수정할 것!
 * @param option
 * @returns
 */
export const i18nFormatDateToLocaleString = (
  date: Date,
  currentLanguage: LanguageType,
  option?: Intl.DateTimeFormatOptions
): string => {
  return formatDateToLocaleString(date, currentLanguage, option) as string;
};

/**
 * @see 1.0 i18nDateUtil.ts toFormattedVersionString
 *
 * @param date
 * @param currentLanguage 1.0 의 "LanguageTranslator.getCurrentLanguage()" 값
 * - TODO: 추후 해당 위 currentLanguage 값 어떻게 전달할 것인지 고민 후 수정할 것!
 * @param option
 * @returns
 */
export const i18nToFormattedVersionString = (
  date: Date,
  currentLanguage: LanguageType,
  option?: Intl.DateTimeFormatOptions
): string => {
  return toFormattedVersionString(date, currentLanguage, option);
};

export const parseTimeToSeconds = (value: string) => {
  if (/^[0-9]+(\.[0-9]+)?$/.test(value)) {
    return parseFloat(value); // 실수일 경우 그대로 반환
  } else if (/^[0-5]?[0-9]:[0-5][0-9]$/.test(value)) {
    const parts = value.split(':').map(parseFloat); // 실수로 변환
    const minutes = parts[0];
    const seconds = parts[1];
    return minutes * 60 + seconds;
  }
  return NaN; // 변환 실패
};

export const getKSTDateString = () => {
  const now = new Date();
  const koreaTime = toFormattedString(
    new Date(
      now.getTime() +
        now.getTimezoneOffset() * MILLI_SECONDS_OF_ONE_MINUTE +
        9 * MILLI_SECONDS_OF_ONE_HOUR
    )
  );

  return koreaTime;
};
