import { atom } from 'jotai';
import { UserDesignResourceType } from '@miri-unicorn/miricanvas-api-client';
import { atomFamilyWithAllowedKeys } from '@configs/jotai/utils/atomFamilyWithAllowedKeys';
import { DesignResourcePanelMenuType } from '@constants/DesignResourcePanelMenu';

const SIMILAR_RESOURCE_BY_USER_RESOURCE_SUPPORTED_PANEL_LIST = [
  'PICTURE',
  'ELEMENTS',
] as const satisfies DesignResourcePanelMenuType[];

export type SimilarResourceByUserResourceSupportedPanelType =
  (typeof SIMILAR_RESOURCE_BY_USER_RESOURCE_SUPPORTED_PANEL_LIST)[number];

export const isSimilarResourceByUserResourceSupportedPanelType = (
  panel: DesignResourcePanelMenuType
): panel is SimilarResourceByUserResourceSupportedPanelType =>
  SIMILAR_RESOURCE_BY_USER_RESOURCE_SUPPORTED_PANEL_LIST.includes(
    panel as SimilarResourceByUserResourceSupportedPanelType
  );

export type UserResourceTarget = {
  resourceKey: string;
  resourceType: UserDesignResourceType;
};

export const {
  atomFamily: similarResourceByUserResourceTargetAtomFamily,
  writeOnlyByKeyAtom: writeOnlySimilarUserResourceTargetAtom,
} = atomFamilyWithAllowedKeys(
  SIMILAR_RESOURCE_BY_USER_RESOURCE_SUPPORTED_PANEL_LIST,
  () => atom<UserResourceTarget | null>(null),
  null
);
