import { EditorFeatureToMenuTypeMap } from '@business/design_resource_bar/_services/EditorFeatureToMenuTypeMap';
import {
  makeKeyFromEditorFeature,
  useEditorFeature,
} from '@business/editor/editor_feature_limitations/_stores/useEditorFeature';
import {
  PanelKeyEditorFeature,
  PanelKeyEditorFeatureSchema,
} from '@business/editor/launcher_param/_types/editorFeatureSchema';

export const useDisabledMenuTabs = () => {
  const featureConfigs = useEditorFeature(PanelKeyEditorFeatureSchema.options);
  return {
    disabledTabs: PanelKeyEditorFeatureSchema.options
      .filter((feature) => !featureConfigs[makeKeyFromEditorFeature(feature)])
      .flatMap((feature) => {
        const menuType = EditorFeatureToMenuTypeMap[feature];

        // 해당 탭 뿐만 아니라 추가로 제약돼야 하는 탭을 가져와서 함께 제약
        const additionalMenuTypes =
          additionalDisabledTabFeatureMap[feature]?.map(
            (additionalFeature) => EditorFeatureToMenuTypeMap[additionalFeature]
          ) ?? [];

        return [menuType, ...additionalMenuTypes];
      }),
  };
};

/**
 * 특정 탭이 제약될 때 함께 제약돼야 하는 탭이 있을 경우
 * ex. 템플릿 탭이 제약되면 크리에이터 탭도 함께 제약되어야 함
 */
const additionalDisabledTabFeatureMap: {
  [key in PanelKeyEditorFeature]?: PanelKeyEditorFeature[];
} = {
  // UNICORNQA-9532 - 템플릿 탭 제약 시 크리에이터 탭도 제약하기로 결정
  TAB_TEMPLATE: ['TAB_CREATOR'],
};
