import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';

export const makeGAParamsAILayoutApply: GAParameterMakerFnType<
  'ACTION_CLICK_APPLY_AI_LAYOUT_THUMBNAIL'
> = (payload) => {
  return {
    event: 'action_recommended_layout',
    log_type: 'action_recommended_layout',
    ai_template_idx: payload.data.ai_template_idx.toString(),
    type: payload.data.type,
    filter: payload.data.filter,
    search_type: payload.data.search_type,
    priority: payload.data.priority.toString(),
    design_history_idx: payload.data.design_history_idx,
    design_page_idx: payload.data.design_page_idx,
    source_template: payload.data.source_template,
    source_template_category: payload.data.source_template_category,
    editor_version: payload.data.editor_version,
  };
};
