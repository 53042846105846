import { VIDEO_MOV, VIDEO_MP4 } from '../constants/FileMimeTypes';
import { readFileAsync } from './file_util';
import { LogUtil } from './log_util';
import { isDataUrl } from './url_util';

export const fetchAudioElementSize = (
  _: string,
  type?: 'LIST' | 'CHECKER_BOARD'
): { width: number; height: number } => {
  return type === 'LIST' ? { width: 300, height: 80 } : { width: 120, height: 120 };
};

/**
 *
 * @param src
 * @param disableCache
 * CORS 오류 방지를 위해 필요 시, 브라우저 캐시를 사용하지 않는 옵션을 제공함.
 */
export const fetchImageElement = async (
  src: string,
  disableCache = false
): Promise<HTMLImageElement> => {
  return new Promise<HTMLImageElement>((resolve, reject) => {
    const img = new Image();

    img.crossOrigin = 'Anonymous'; //UNICORN-6606
    img.onload = () => {
      resolve(img);
    };
    img.onerror = () => {
      // 20201210 서동균 UNICORN-8610 여기서 404 를 보내고 싶었는데, Event 에 관련 정보가 없음
      reject(`loadImageAsync fail: [${src}]`);
    };

    /**
     * dataUrl은 뒤에 임의의 쿼리 스트링이 붙으면 유효성이 깨지므로
     * imageUrl일 경우에만 임의의 쿼리 스트링으로 캐시를 사용하지 않도록 한다.
     * dataUrl은 어차피 항상 캐시를 사용하지 않는다.
     */
    const imgSrc = disableCache && !isDataUrl(src) ? `${src}?t=${new Date().getTime()}` : src;
    img.src = imgSrc || '';
  });
};

export const fetchVideoElement = async (src: string): Promise<HTMLVideoElement> => {
  return new Promise<HTMLVideoElement>((resolve, reject) => {
    const video = document.createElement('video');
    video.addEventListener(
      'loadedmetadata',
      () => {
        video.width = video.videoWidth;
        video.height = video.videoHeight;
      },
      false
    );

    video.onloadedmetadata = () => {
      resolve(video);
    };
    video.onerror = (e) => {
      LogUtil.error.dev('fetchVideoElement error', e);
      reject(`loadVideoAsync fail: [${src}]`);
    };

    video.src = src;
  });
};

export const fetchVideoFileDimension = async (file: File) => {
  const videoUrl = await fetchDataURL(file);
  const videoTag = await fetchVideoElement(videoUrl);

  const width = videoTag.width;
  const height = videoTag.height;
  videoTag.removeAttribute('width');
  videoTag.removeAttribute('height');

  return { width, height };
};

// make thumbnail if required
export const fetchDataURL = async (file: File): Promise<string> => {
  if (file.type === VIDEO_MP4 || file.type === VIDEO_MOV) {
    return URL.createObjectURL(file);
  }
  return (await readFileAsync((fileReader) => fileReader.readAsDataURL(file))).result as string;
};

export const getUserImageDimension = async (file: File) => {
  const imgUrl = await fetchDataURL(file);
  const imgTag = await fetchImageElement(imgUrl);
  return { width: imgTag.width, height: imgTag.height };
};

/**
 * element 외부를 클릭했을 때 callback 을 실행하는 함수
 * @param element element 외부 클릭하면 callback 실행
 * @param callback 콜백 함수
 * @param options 옵션
 * @param options.once true 일 경우, 한번만 실행 (콜백 실행 후 이벤트 리스너 제거)
 * @returns 이벤트 리스너 제거 함수
 */
export const addOnClickOutsideEventListener = (
  element: HTMLElement,
  callback: (e: MouseEvent) => void,
  options: { once: boolean } = { once: false }
) => {
  const handleClickOutside = (e: MouseEvent) => {
    if (element.contains(e.target as Node)) {
      return;
    }
    if (options.once) {
      window.removeEventListener('click', handleClickOutside);
    }
    callback(e);
  };

  window.addEventListener('click', handleClickOutside);

  return () => {
    window.removeEventListener('click', handleClickOutside);
  };
};

export const imageElementToCanvas = (imageElement: HTMLImageElement): HTMLCanvasElement => {
  const canvas = document.createElement('canvas');
  canvas.width = imageElement.width;
  canvas.height = imageElement.height;
  const ctx = canvas.getContext('2d');
  if (!ctx) {
    return canvas;
  }
  ctx.drawImage(imageElement, 0, 0);
  return canvas;
};
