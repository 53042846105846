/**
 * 1.0에서 URL을 구성하는 쿼리스트링의 key 값들의 enum 형
 * @see 1.0 RequestUtil.ts enum RequestParamKey
 */
export const RequestParamKeyEnum = {
  SHEET_W: 'sheetW',
  SHEET_H: 'sheetH',
  TEMPLATE_TYPE_ID: 'templateTypeId',
  SIZE_UNIT: 'sizeUnit',
  DPI: 'dpi',
  CATEGORY_IDX: 'categoryIdx',
  TEAM_IDX: 'teamIdx',
  TEAM_SCOPE: 'teamScope',
  TEMPLATE_IDX: 'templateIdx',
  TEMPLATE_SEARCH_WORD: 'templateSearchWord',
  VIEW_MODE: 'viewMode',
  FEEDBACK_ID: 'feedbackId',
  OPEN_PRODUCTION_TAB: 'openProductionTab',
  OPEN_EDITOR_FAVORITES_PANEL: 'openPickPanel',
  OPEN_EDITOR_PANEL: 'panel',
  OPEN_EDITOR_SEARCH_KEYWORD: 'q',
  /** AI 프레젠테이션 배너 경유 진입 여부 */
  OPEN_AI_PRESENTATION_MODAL: 'openAIPresentation',

  /**
   * 국제화 작업을 위해 원하는 언어 설정하는 파라미터
   * value로 사용될 수 있는 부분들은 LanguageType 타입에 해당하는 값
   * @see @shared/locale/model/LanguageTranslationTypes File의 LanguageType
   **/
  LANG: 'llllaaanng',
  EMBED: 'embed',
  /** 에디터 초기 실행 시, 템플릿 관리자 화면을 열고 템플릿 정보를 보여주기 위한 템플릿 idx */
  ADMIN_TEMPLATE_IDX: 'adminTemplateIdx',
  USER_TEMPLATE_DESIGN_IDX: 'userTemplateDesignIdx',
  /** 네이버 워크플레이스(미캔 연동 외부 서비스)에서 사용하는 서비스 이름, */
  AUTH_NAME: 'authName',
  /** 템플릿 purpose (ex.웹용, 인쇄용, 동영상용) */
  TEMPLATE_PURPOSE: 'templatePurpose',
  /** 모바일에서 빈 디자인으로 시작 시 디자인 리소스 패널이 올라오지 않게하는 값 */
  WITHOUT_STARTUP: 'withoutStartup',
  /** AB 테스트 케이스를 고정하고 싶을 때 */
  AB_CASE: 'abcase',
} as const;

/**
 * 1.0에서 URL을 구성하는 쿼리스트링의 key 값들이 정의된 타입.
 * @see 1.0 RequestUtil.ts
 */
export type RequestParamEnumKey = keyof typeof RequestParamKeyEnum;
export type RequestParamKey = (typeof RequestParamKeyEnum)[RequestParamEnumKey];
