import { ParsedUrlQuery } from 'querystring';
import { NextRouter, useRouter as _useRouter } from 'next/router';

export const useRouter = <Q extends ParsedUrlQuery>(): NextRouter & { query: Q } => {
  const router = _useRouter();

  return {
    ...router,
    query: router.query as Q,
  };
};
