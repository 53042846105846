import {
  BackgroundDesignResourceType,
  DesignResourceType,
  KeywordCategoryEntityType,
  RecentDesignResourceGroup,
} from '@miri-unicorn/miricanvas-api-client';
import { DesignResourceTabType } from '@business/design_resource_bar/tab/_constants/designResourceTabType';
import { LauncherParamEditorFeature } from '@business/editor/launcher_param/_types/editorFeatureSchema';

export const BACKGROUND_TAB_TYPE: BackgroundTabType[] = [
  {
    tabKey: 'PICTURE',
    tabName: 'EDITOR.LEFT_MENU.EDITOR_FEATURE.TAB_BACKGROUND_IMAGE',
    designResourceTypes: ['BACKGROUND_PICTURE'],
    editorFeatureKey: 'TAB_BACKGROUND_IMAGE',
    resourceGroup: 'BACKGROUND_PICTURE',
    keywordCategoryType: 'BACKGROUND_PICTURE',
  },
  {
    tabKey: 'PATTERN',
    tabName: 'EDITOR.LEFT_MENU.EDITOR_FEATURE.TAB_BACKGROUND_PATTERN',
    designResourceTypes: ['BACKGROUND_PATTERN'],
    editorFeatureKey: 'TAB_BACKGROUND_PATTERN',
    resourceGroup: 'BACKGROUND_PATTERN',
    keywordCategoryType: 'BACKGROUND_PATTERN',
  },
];

export type BackgroundTabType = DesignResourceTabType<BackgroundTabKey> & {
  designResourceTypes: BackgroundDesignResourceType[];
  editorFeatureKey: LauncherParamEditorFeature;
  resourceGroup: RecentDesignResourceGroup;
  keywordCategoryType?: KeywordCategoryEntityType;
};
export type BackgroundTabKey = 'PICTURE' | 'PATTERN';

export const backgroundTabToDesignResourceMap: Record<BackgroundTabKey, KeywordCategoryEntityType> =
  {
    PICTURE: DesignResourceType.BACKGROUND_PICTURE,
    PATTERN: DesignResourceType.BACKGROUND_PATTERN,
  };
