/**
 * 외부 스크립트를 불러올때 사용하는 유틸 함수
 * callback 함수를 전달해서 스크립트가 로드된 이후의 로직을 실행
 */
export const getScript = (url: string, callback: () => void) => {
  const script = document.createElement('script');
  script.src = url;
  document.body.appendChild(script);
  script.onload = callback;
};
