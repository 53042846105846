import { getRootStore } from '@configs/jotai/RootProvider';
import { panelSearchKeywordAtomFamily } from '@ui/design/_common/design_resource_panel/_stores/_common/panelSearchKeywordAtomFamily';
import { isSearchingRecentPanelAtomFamily } from '@ui/design/_common/design_resource_panel/_stores/_common/isSearchingRecentInPanelAtomFamily';
import { DesignResourceEventPanelType } from '@configs/google_analytics/features/design_resource/events/payloads/designResourceActionPayload';
import { DesignResourceGAEventPayloadByPanel } from '@configs/google_analytics/features/design_resource/_types/designResourceGAPayloadType';

export const getSearchTerm = ({
  panelType,
}: {
  panelType: DesignResourceEventPanelType;
}): DesignResourceGAEventPayloadByPanel['search_term'] => {
  const searchTerm = getRootStore().get(panelSearchKeywordAtomFamily(panelType));
  const isSearchingRecent = getRootStore().get(isSearchingRecentPanelAtomFamily(panelType));

  return isSearchingRecent ? '최근사용' : searchTerm;
};
