import {
  KeywordCategoryEntityType,
  RecentDesignResourceGroup,
  TextDesignResourceType,
} from '@miri-unicorn/miricanvas-api-client';
import { DesignResourceTabType } from '@business/design_resource_bar/tab/_constants/designResourceTabType';
import { LauncherParamEditorFeature } from '@business/editor/launcher_param/_types/editorFeatureSchema';

export const TEXT_TAB_TYPE: TextTabType[] = [
  {
    tabKey: 'STYLE',
    tabName: 'EDITOR.LEFT_MENU.EDITOR_FEATURE.TAB_TEXTSTYLE',
    designResourceTypes: ['TEXT_STYLE'],
    editorFeatureKey: 'TAB_TEXTSTYLE',
    keywordCategoryEntityType: 'TEXT_STYLE',
    resourceGroup: 'TEXT_STYLE',
  },
  {
    tabKey: 'FONT',
    tabName: 'EDITOR.LEFT_MENU.EDITOR_FEATURE.TAB_TEXTCOMBINATION',
    designResourceTypes: ['COMBINATION'],
    editorFeatureKey: 'TAB_TEXTCOMBINATION',
    keywordCategoryEntityType: 'COMBINATION',
    resourceGroup: 'COMBINATION',
  },
  {
    tabKey: 'SPECIAL_SIGN',
    tabName: 'EDITOR.LEFT_MENU.EDITOR_FEATURE.TAB_TEXTSPECIAL',
    editorFeatureKey: 'TAB_TEXTSPECIAL',
    designResourceTypes: [],
  },
];

export type TextTabType = DesignResourceTabType<TextTabKey> & {
  designResourceTypes: TextDesignResourceType[];
  editorFeatureKey: LauncherParamEditorFeature;
  resourceGroup?: RecentDesignResourceGroup;
  keywordCategoryEntityType?: KeywordCategoryEntityType;
};

export type TextTabKey = 'STYLE' | 'FONT' | 'SPECIAL_SIGN';
export const TextTabTypeMap = new Map(TEXT_TAB_TYPE.map((tab) => [tab.tabKey, tab]));
