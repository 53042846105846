import { Currency } from '@miri-unicorn/miricanvas-api-client';

const localeMap = new Map<string, string>([
  ['KRW', 'ko-KR'],
  ['USD', 'en-US'],
  ['EUR', 'de-DE'],
  ['JPY', 'ja-JP'],
]);

export const toCurrency = ({
  value,
  currency = 'KRW',
}: {
  value: number;
  currency?: Currency;
}): string => {
  return new Intl.NumberFormat(localeMap.get(currency), {
    style: 'currency',
    currency: currency,
  }).format(value);
};
