import { GAParam } from '@configs/google_analytics/types/GACommonTypes';
import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { GTMTriggerEventParams } from '@configs/google_analytics/types/GTMTriggerEventParams';

export const makeGAParamsClickTutorialTooltipExecuteAllStepButton: GAParameterMakerFnType<
  'ACTION_CLICK_TUTORIAL_TOOLTIP_EXECUTE_ALL_STEP_BUTTON'
> = ({ data: { location3 } }) => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '수동',
    location3,
    location4: '바로시작',
    action1: '클릭',
    label1: `에디터_튜토리얼진입점_{템플릿타입명}_바로시작`,
  } as TutorialTooltipOpenButtonReturnPayload;
};

type TutorialTooltipOpenButtonReturnPayload = GAParam<GTMTriggerEventParams>;
