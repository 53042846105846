import {
  RecentlyViewedTemplateObj,
  RecentlyViewedTemplateParams,
} from '@domain/template/recently_viewed/_business/_types/RecentlyViewedTemplateTypes';

/**
 * templateIdx를 기준으로 중복 제거된 목록을 반환
 */
const getUniqueRecentlyViewedTemplate = ({
  origin,
  target,
}: {
  origin: RecentlyViewedTemplateObj[];
  target: number;
}): RecentlyViewedTemplateObj[] => {
  return origin.filter((item) => item.idx !== target);
};

/**
 * 최근 본 템플릿 목록에 새로운 템플릿을 추가하는 함수
 * @param origin - 기존 목록
 * @param target - 추가할 요소
 * @param maxSliceCount - 최대 개수
 */
export const insertRecentlyViewedTemplate = ({
  origin,
  target,
  maxSliceCount,
}: {
  origin: RecentlyViewedTemplateObj[];
  target: RecentlyViewedTemplateParams;
  maxSliceCount?: number;
}): RecentlyViewedTemplateObj[] => {
  // 1) target의 id기준으로 중복 제거된 목록을 반환
  const uniqueRecentlyViewedTemplate = getUniqueRecentlyViewedTemplate({
    origin,
    target: target.idx,
  });

  // 2) target을 가장 앞에 추가
  const addedRecentlyViewedTemplate: RecentlyViewedTemplateObj[] = [
    { timestamp: new Date().getTime(), displayType: target.displayType, idx: target.idx },
    ...uniqueRecentlyViewedTemplate,
  ];

  // 3) 최대 개수만큼 자른 목록을 반환
  return addedRecentlyViewedTemplate.slice(0, maxSliceCount);
};
