"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _OnClickSocialLoginPayload = require("./OnClickSocialLoginPayload");
Object.keys(_OnClickSocialLoginPayload).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _OnClickSocialLoginPayload[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _OnClickSocialLoginPayload[key];
    }
  });
});
var _SocialType = require("./SocialType");
Object.keys(_SocialType).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _SocialType[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _SocialType[key];
    }
  });
});
var _ActionTypeAfterLogin = require("./ActionTypeAfterLogin");
Object.keys(_ActionTypeAfterLogin).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _ActionTypeAfterLogin[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _ActionTypeAfterLogin[key];
    }
  });
});