import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { GAParam } from '@configs/google_analytics/types/GACommonTypes';
import { GTMTriggerEventParams } from '@configs/google_analytics/types/GTMTriggerEventParams';

export const makeGAParamsClickItemFavoriteTemplateSurvey: GAParameterMakerFnType<
  'ACTION_CLICK_ITEM_FAVORITE_TEMPLATE_SURVEY'
> = ({ data: { location1, location4 } }) => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1,
    location2: '고객정보수집팝업',
    location3: '3단계',
    location4,
    action1: '클릭',
    label1: `고객정보수집팝업_템플릿찜_항목_클릭`,
  } as FavoriteTemplateSurveyReturnPayload;
};

type FavoriteTemplateSurveyReturnPayload = GAParam<GTMTriggerEventParams>;
