export type LoadScriptOptions = {
  url: string;
  type?: 'module' | 'text/javascript';
};

export const loadScript = async ({ url, type = 'text/javascript' }: LoadScriptOptions) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.type = type;
    script.onload = resolve;
    script.onerror = reject;
    script.src = url;
    document.getElementsByTagName('head')[0].appendChild(script);
  });
};

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace loadScript {
  export type Function = typeof loadScript;
}
