import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';

export const makeGAParamsActionAIPresentationTemplateTypePopupCreateButton: GAParameterMakerFnType<
  'ACTION_CLICK_AI_PRESENTATION_TEMPLATE_TYPE_POPUP_CREATE_BUTTON'
> = ({ data: { location1, location2, location3, label1 } }) => ({
  event: 'gtm_trigger_event',
  log_type: 'generic_event_mican',
  location1,
  location2,
  location3,
  location4: 'AI프레젠테이션만들기',
  action1: '클릭',
  label1,
});
