import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { getSearchStatus } from '@configs/google_analytics/features/design_resource/_makers/getSearchStatus';
import { getSelectedCollectionKeywordCategorySnapshot } from '@ui/design/_common/design_resource_panel/_stores/element/subpage/useSelectedElementCollectionStore';
import { getRootStore } from '@configs/jotai/RootProvider';
import { designResourceSearchTraceAtomFamily } from '@configs/google_analytics/features/design_resource/_stores/designResourceSearchTraceAtomFamily';
import { getElementSearchKeywordSnapshotValue } from '@ui/design/_common/design_resource_panel/_stores/element/useElementPanelSearchStore';

export const makeGAParamsActionClickElementItemInCollectionItem: GAParameterMakerFnType<
  'ACTION_CLICK_ELEMENT_ITEM_IN_COLLECTION_ITEM'
> = (payload) => {
  const trace = getRootStore().get(
    designResourceSearchTraceAtomFamily({ panel: 'ELEMENTS', key: 'DEFAULT' })
  );
  const keyword = getElementSearchKeywordSnapshotValue();
  return {
    event: 'mican_collection',
    log_type: 'mican_collection',
    search_status: getSearchStatus({ searchType: trace.searchType, searchTerm: keyword }),
    search_type: trace.searchType,
    search_term: keyword,
    collection_id: payload.data.collectionId,
    search_keyword_category: getSelectedCollectionKeywordCategorySnapshot(),
    element_id: payload.data.elementId,
    action_type: '요소',
  };
};
