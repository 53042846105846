import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';

export const makeGAParamsActionClickSignUpButtonSignUpNudgePopup: GAParameterMakerFnType<
  'ACTION_CLICK_SIGN_UP_BUTTON_SIGN_UP_NUDGE_POPUP'
> = ({ data: { location1 } }) => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1,
    location2: 'CTA버튼',
    action1: '클릭',
    label1: '글로벌신규유입_가입유도팝업_CTA버튼_클릭',
  };
};
