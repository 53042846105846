import { atom } from 'jotai/index';
import { ELEMENT_TAB_TYPE } from '@business/design_resource_bar/tab/_constants/elementTabType';
import { selectedElementTabAtom } from '@ui/design/_common/design_resource_panel/_stores/element/useElementPanelTabStore';
import { elementSearchAtom } from '@ui/design/_common/design_resource_panel/_stores/element/useElementPanelSearchStore';
import { isEqual } from '@utils/functions/lodash_util';
import { ElementDesignResourceType } from '@miri-unicorn/miricanvas-api-client';

// TODO: EditorFeature로 제약되는 것까지 고려해서 타입 필터 판단해야 함
export const selectedElementTypeFilterAtom = atom<ElementDesignResourceType | null>((get) => {
  const foundTab = ELEMENT_TAB_TYPE.find((tab) => tab.tabKey === get(selectedElementTabAtom));
  if (!foundTab || !foundTab.supportElementTypeFilter) return null;

  const { typeList } = get(elementSearchAtom);
  if (isEqual(typeList, foundTab.designResourceTypes)) {
    return null;
  }

  // TODO: typeList 비교로 타입 필터 선택 여부를 판단하는게 아니라, 실제 유저가 필터에서 선택한 값을 기억하게 변경
  return typeList[0];
});
