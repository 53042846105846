import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import { getRootStore } from '@configs/jotai/RootProvider';

export const selectedCollectionIdxAtom = atom<number | undefined>(undefined);

// GA용 - 요소 패널 컬렉션 2뎁스 오픈 시, 컬렉션이 포함되어 있던 키워드 카테고리의 이름
export const selectedCollectionKeywordCategoryAtom = atom<string | undefined>(undefined);
export const getSelectedCollectionKeywordCategorySnapshot = () =>
  getRootStore().get(selectedCollectionKeywordCategoryAtom);

export const useSelectedElementCollectionStore = () => {
  const [selectedCollectionIdx, setSelectedCollectionIdx] = useAtom(selectedCollectionIdxAtom);

  return {
    selectedCollectionIdx,
    setSelectedCollectionIdx,
  };
};

const selectedFavoriteCollectionIdxAtom = atom<number | undefined>(undefined);

export const useSelectedFavoriteCollectionIdx = () => {
  return useAtomValue(selectedFavoriteCollectionIdxAtom);
};

export const useSetSelectedFavoriteCollectionIdx = () => {
  const setSelectedFavoriteCollectionIdx = useSetAtom(selectedFavoriteCollectionIdxAtom);

  return { setSelectedFavoriteCollectionIdx };
};

/**
 * 데이터 패널의 선택된 컬렉션
 */
const selectedDataCollectionIdxAtom = atom<number | undefined>(undefined);
selectedDataCollectionIdxAtom.debugLabel = 'dataCollectionIdxAtom';

export const useSelectedDataCollectionIdx = () => {
  return useAtomValue(selectedDataCollectionIdxAtom);
};

export const useSetSelectedDataCollectionIdx = () => {
  return useSetAtom(selectedDataCollectionIdxAtom);
};
