/* eslint-disable no-console */
import { BuildEnv } from '../configs/BuildEnv';
import { toJsonString } from './json_util';

type LogParams = Parameters<typeof console.log>;

type LogType = 'log' | 'warn' | 'error' | 'info' | 'trace' | 'debug';

const isServer = typeof window === 'undefined';

abstract class Logger<T extends LogType> {
  protected readonly TYPE: Uppercase<T>;
  constructor(protected readonly type: T) {
    this.TYPE = this.type.toUpperCase() as Uppercase<T>;
  }

  protected abstract format(label: string, ...params: LogParams): LogParams;

  all(label: string, ...params: LogParams) {
    console[this.type](...this.format(label, params));
  }

  dev(label: string, ...params: LogParams) {
    if (BuildEnv.IS_PRODUCTION) {
      return;
    }
    this.all(label, params);
  }

  local(label: string, ...params: LogParams) {
    if (!BuildEnv.IS_LOCAL) {
      return;
    }
    this.all(label, params);
  }
}

class ClientLogger<T extends LogType> extends Logger<T> {
  format(label: string, params: LogParams) {
    return [`[${this.TYPE}] - '${label}':`, ...params];
  }
}

class ServerLogger<T extends LogType> extends Logger<T> {
  format(label: string, params: LogParams) {
    const { errors, rest } = this.split(params);

    return [
      // 로그를 한줄로 출력하지 않으면 cloudwatch로그가 오염되어 확인이 어려움
      `[${this.TYPE}] - '${label}' - ${rest
        .map((param) => `${toJsonString(param, 0)}`)
        .join(', ')}`,
      // 에러 객체만 별도로 출력
      ...errors,
    ];
  }

  private split(params: LogParams) {
    const errors: Error[] = [];
    const rest: unknown[] = [];

    for (let i = 0; i < params.length; i++) {
      const param = params[i];
      if (param instanceof Error) {
        errors.push(param);
      } else {
        rest.push(param);
      }
    }

    return { errors, rest };
  }
}

const LoggerClass = isServer ? ServerLogger : ClientLogger;

export const LogUtil = {
  log: new LoggerClass('log'),
  warn: new LoggerClass('warn'),
  error: new LoggerClass('error'),
  info: new LoggerClass('info'),
  trace: new LoggerClass('trace'),
  debug: new LoggerClass('debug'),
} as const satisfies Record<LogType, Logger<LogType>>;
