import { PanelKeyEditorFeature } from '@business/editor/launcher_param/_types/editorFeatureSchema';
import { DesignResourcePanelMenuType } from '@constants/DesignResourcePanelMenu';

export const EditorFeatureToMenuTypeMap: Record<
  PanelKeyEditorFeature,
  DesignResourcePanelMenuType
> = {
  TAB_AI_DRAWING: 'AIDRAWING', // AI 드로잉
  TAB_AUDIO: 'AUDIO', // 오디오
  TAB_BACKGROUND: 'BACKGROUND', // 배경
  TAB_ELEMENT: 'ELEMENTS', // 요소
  TAB_LOGO: 'LOGO', // 로고
  TAB_PHOTO_GROUP: 'PICTURE', // 사진
  TAB_PICK: 'FAVORITES', // 찜
  TAB_TEMPLATE: 'TEMPLATE', // 템플릿
  TAB_QRANDBARCODE: 'QRBARCODE', // QR / 바코드
  TAB_TEXT: 'TEXT', // 텍스트
  TAB_THEME: 'THEME', // 테마
  TAB_UPLOAD: 'UPLOAD', // 업로드
  TAB_VIDEO_GROUP: 'VIDEO', // 비디오
  TAB_WORK: 'WORKSPACE', // 작업공간
  TAB_CREATOR: 'CREATOR', // 크리에이터
  TAB_DATA: 'DATA', // 데이터
};
