import {
  SearchType,
  SearchTypeMap,
} from '@configs/google_analytics/features/design_resource/_types/designResourceSearchType';
import { DesignResourcePanelMenuType } from '@constants/DesignResourcePanelMenu';
import { atomFamily } from 'jotai/utils';
import { atom, PrimitiveAtom } from 'jotai';
import { match } from 'ts-pattern';

type SearchTrace = {
  searchType: SearchType;
  sourceTab?: DesignResourcePanelMenuType | 'PROPERTY';
};

type SearchTraceMap = {
  DEFAULT: SearchTrace;
  SIMILAR_RESOURCE_PREVIEW: SearchTrace;
  SIMILAR_RESOURCE_SUBPAGE: SearchTrace;
};

export type SearchTraceKey = keyof SearchTraceMap;

export const designResourceSearchTraceAtomFamily = atomFamily<
  { panel: DesignResourcePanelMenuType; key: SearchTraceKey },
  PrimitiveAtom<SearchTrace>
>(
  () => atom<SearchTraceMap[SearchTraceKey]>({ searchType: SearchTypeMap.ETC }),
  (a, b) => a.key === b.key && a.panel === b.panel
);

export const getSearchTraceEntry = (searchType: SearchType): SearchTraceKey => {
  return match<SearchType>(searchType)
    .with(SearchTypeMap.PREVIEW_SIMILAR_RESOURCE, () => 'SIMILAR_RESOURCE_PREVIEW' as const)
    .with(
      SearchTypeMap.MORE_SIMILAR_RESOURCE,
      SearchTypeMap.FIND_SIMILAR_RESOURCE,
      () => 'SIMILAR_RESOURCE_SUBPAGE' as const
    )
    .otherwise(() => 'DEFAULT' as const);
};
