import { clamp } from '../math_util';

export const getJustifiedContentWidth = ({
  ratio,
  rowHeight,
  min,
  max,
}: {
  ratio: number;
  rowHeight: number;
  min?: number;
  max?: number;
}) => {
  return clamp(ratio * rowHeight, min, max);
};

/**
 * rowDefaultHeight를 기준으로 rowMaxWidth를 넘지 않는 columnCount를 계산한다.
 * TODO: 테스트 코드 작성
 * @param rawContentRatio 각 컨텐츠의 width/height 비율
 */
export const calcColumnCountByHeight = (
  rawContentRatio: number[],
  {
    rowDefaultHeight,
    rowMaxWidth,
    columnGap,
    contentMinWidth,
    contentMaxWidth,
  }: {
    rowDefaultHeight: number;
    rowMaxWidth: number;
    columnGap: number;
    contentMinWidth?: number;
    contentMaxWidth?: number;
  }
) => {
  let rowCount = 0;
  let nowWidth = 0;

  const getTotalWidth = () => nowWidth + Math.max(0, rowCount - 1) * columnGap;

  // row에 최초 한 개의 아이템은 넣고 시작함
  do {
    const justifiedContentWidth = getJustifiedContentWidth({
      ratio: rawContentRatio[rowCount],
      rowHeight: rowDefaultHeight,
      min: contentMinWidth,
      max: contentMaxWidth,
    });

    /**
     * 현재 아이템을 넣어 봤을 때, rowMaxWidth를 너무 크게 넘어가는 경우 row에 포함시키지 않는다
     * => 정확히는 (row의 width 합 - rowMaxWidth) 값이 마지막 아이템의 width의 절반보다 큰 경우.
     */
    const overflowedSize = getTotalWidth() + justifiedContentWidth + columnGap - rowMaxWidth;
    const overflowThreshold = (justifiedContentWidth + columnGap) / 2;
    const canInsertItem = rowCount === 0 || overflowedSize < overflowThreshold;
    if (!canInsertItem) {
      break;
    }

    nowWidth += justifiedContentWidth;
    rowCount++;

    // 아이템 넣었을 때 totalWidth가 rowMaxWidth를 넘겼다면 row 생성 중단
  } while (rawContentRatio[rowCount] && getTotalWidth() < rowMaxWidth);

  return rowCount;
};
