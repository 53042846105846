/** window.open을 이용하여 새 창 팝업을 발생시킬 경우, 팝업이 중앙에 위치하도록 */
export const openWindowPopupWithCenterPosition = (url: string, width: number, height: number) => {
  const screenLeft = window.screenLeft ? window.screenLeft : window.screenX;
  const screenTop = window.screenTop ? window.screenTop : window.screenY;

  const screenWidth = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : screen.width;
  const screenHeight = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : screen.height;

  const left = screenWidth / 2 - width / 2 + screenLeft;
  const top = screenHeight / 2 - height / 2 + screenTop;

  window.open(
    url,
    'popup',
    `width=${width},height=${height},top=${top},left=${left}` +
      ',location=no,menubar=no,resizable=no,scrollbars=no,status=no,titlebar=no,toolbar=no'
  );
};
