import { atom, useAtomValue, useSetAtom } from 'jotai';
import { atomFamily } from 'jotai/utils';
import { useCallback } from 'react';
import { DesignResourceDetailData } from '@business/design_resource/_types/designResourceDetailData';
import { DesignResourcePanelMenuType } from '@constants/DesignResourcePanelMenu';
import { useIsRecommendationSupportedResource } from '@ui/design/_common/design_resource/_hooks/useIsRecommendationSupportedResource';

const RECOMMEND_RESOURCE_ROW_SUPPORTED_PANEL_LIST = [
  'ELEMENTS',
  'PICTURE',
] as const satisfies readonly DesignResourcePanelMenuType[];

type RecommendResourceRowSupportedPanelType =
  (typeof RECOMMEND_RESOURCE_ROW_SUPPORTED_PANEL_LIST)[number];

export const isRecommendResourceRowSupportedPanelType = (
  panelType: DesignResourcePanelMenuType
): panelType is RecommendResourceRowSupportedPanelType =>
  RECOMMEND_RESOURCE_ROW_SUPPORTED_PANEL_LIST.includes(panelType);

const dummyAtom = atom(null, () => null);

const recommendResourceRowTargetAtomFamily = atomFamily(
  (panelType: RecommendResourceRowSupportedPanelType) => {
    if (!isRecommendResourceRowSupportedPanelType(panelType)) return dummyAtom;

    return atom<DesignResourceDetailData | null>(null);
  }
);

export const getRecommendResourceRowTargetAtom = (panelType: DesignResourcePanelMenuType) =>
  isRecommendResourceRowSupportedPanelType(panelType)
    ? recommendResourceRowTargetAtomFamily(panelType)
    : dummyAtom;

export const useRecommendResourceRowTarget = (panelType: DesignResourcePanelMenuType) => {
  return useAtomValue(getRecommendResourceRowTargetAtom(panelType));
};

export const useSetRecommendResourceRowTarget = (panelType: DesignResourcePanelMenuType) => {
  const setValue = useSetAtom(getRecommendResourceRowTargetAtom(panelType));

  const isRecommendationSupportedResource = useIsRecommendationSupportedResource();

  return useCallback(
    (target: DesignResourceDetailData | null) => {
      if (!isRecommendationSupportedResource({ resourceInfo: target })) {
        setValue(null);
        return;
      }

      setValue(target);
    },
    [isRecommendationSupportedResource, setValue]
  );
};
