export const isInside = (pos: { x: number; y: number }, rect: DOMRect) => {
  return pos.x >= rect.left && pos.x <= rect.right && pos.y >= rect.top && pos.y <= rect.bottom;
};

/**
 * target 의 요소들 중, id 를 포함하는 모든 요소를 targets 배열에 집어넣는다.
 * 재귀라서 target 의 children 들도 모두 검사한다.
 */
export const findElementsById = (ret: Element[], id: string, target: HTMLCollection): void => {
  if (target.length === 0) {
    return;
  }

  for (const element of target) {
    if (element.id.includes(id)) {
      ret.push(element);
    } else {
      findElementsById(ret, id, element.children);
    }
  }
};
