import { atom, useAtom, useSetAtom } from 'jotai';
import {
  AUDIO_TAB_TYPE,
  AudioTabKey,
} from '@business/design_resource_bar/tab/_constants/audioTabType';

export const selectedAudioTabAtom = atom<AudioTabKey>('INSTRUMENTAL');

export const useAudioPanelTabStore = () => {
  const [selectedTab, setSelectedTab] = useAtom(selectedAudioTabAtom);

  const currentTabType = AUDIO_TAB_TYPE[selectedTab];

  return {
    selectedTab,
    currentTabType,
    setSelectedTab,
  };
};

export const useSetAudioPanelTab = () => useSetAtom(selectedAudioTabAtom);
