import { ReactNode } from 'react';
import { useAbBucketing } from '@configs/ab-test/_hooks/useAbBucketing';
import { AbValue } from '@configs/ab-test/_types/type';
import { useFixedAbCase } from '@configs/ab-test/_hooks/useFixedAbCase';

type ChildrenFunction = (props: { data: AbValue | null }) => ReactNode;

export const AbBucketing = ({ children }: { children: ChildrenFunction }) => {
  const { isFetched, data } = useAbBucketing();
  useFixedAbCase();

  if (!isFetched || !data) {
    return null;
  }

  return <>{children({ data })}</>;
};
