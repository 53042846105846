"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _NativeSystemEntryPoint = require("./common/NativeSystemEntryPoint");
Object.keys(_NativeSystemEntryPoint).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _NativeSystemEntryPoint[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _NativeSystemEntryPoint[key];
    }
  });
});
var _native_event = require("./native_event");
Object.keys(_native_event).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _native_event[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _native_event[key];
    }
  });
});
var _webview_event = require("./webview_event");
Object.keys(_webview_event).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _webview_event[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _webview_event[key];
    }
  });
});