import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';

export const makeGAParamsAILayoutExpose: GAParameterMakerFnType<
  'ACTION_EXPOSE_AI_LAYOUT_THUMBNAILS'
> = (payload) => {
  return {
    event: 'view_recommended_layout',
    log_type: 'view_recommended_layout',
    ai_template_idx_list: payload.data.ai_template_idx_list.join(','),
    type_history: payload.data.type_history.join(','),
    filter: payload.data.filter,
    type: payload.data.type,
    load_count: payload.data.load_count.toString(),
    design_history_idx: payload.data.design_history_idx,
    design_page_idx: payload.data.design_page_idx,
    source_template: payload.data.source_template,
    source_template_category: payload.data.source_template_category,
    editor_version: payload.data.editor_version,
  };
};
