import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';

export const makeGAParamsActionClickMyTemplateFloatingButton: GAParameterMakerFnType<
  'ACTION_CLICK_MY_TEMPLATE_FLOATING_BUTTON'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '템플릿페이지',
    location2: '관심템플릿',
    action1: '클릭',
    label1: '플로팅버튼',
  };
};
