import { aiChatbotQueryKeys } from '@business/ai/ai_chatbot/_queries/aiChatbotQueryKeys';
import { aiPresentationQueryKeys } from '@business/ai/ai_presentation/_queries/aiPresentationQueryKeys';
import { aiWritingQueryKeys } from '@business/ai/ai_writing/_queries/aiWritingQueryKeys';
import { designInfoQueryKeys } from '@business/design/_queries/designInfoQueryKeys';
import { designMemberQueryKeys } from '@business/design/_queries/designMemberQueryKeys';
import { designShareQueryKeys } from '@business/design/_queries/designShareQueryKeys';
import { designTypeQueryKeys } from '@business/design/_queries/designTypeQueryKeys';
import { designHistoryQueryKeys } from '@business/design/design_history/_queries/designHistoryQueryKeys';
import { downloadLimitCountQueryKeys } from '@business/design/download/_queries/downloadLimitCountQueryKeys';
import { designDraftQueryKeys } from '@business/design/draft/_queries/designDraftQueryKeys';
import { audioElementQueryKeys } from '@business/design_resource/audio/_queries/audioElementQueryKeys';
import { backgroundQueryKeys } from '@business/design_resource/background/_queries/backgroundQueryKeys';
import { designResourceQueryKeys } from '@business/design_resource/common/_queries/designResoruceQueryKeys';
import { keywordCategoryQueryKeys } from '@business/design_resource/common/contents_category/keyword_category/_queries/keywordCategoryQueryKeys';
import { searchQueryKeys } from '@business/design_resource/common/search/_queries/searchQueryKeys';
import { elementQueryKeys } from '@business/design_resource/element/_queries/elementQueryKeys';
import { downloadExternalResourceQueryKeys } from '@business/design_resource/external_resource/_queries/downloadExternalResourceQueryKeys';
import { favoriteQueryKeys } from '@business/design_resource/favorite/_queries/favoriteQuerykeys';
import { fontListQueryKeys } from '@business/design_resource/font/_queries/fontListQueryKeys';
import { fontFamilyQueryKeys } from '@business/design_resource/font/font_famliy/_queries/fontFamilyKeys';
import { licenseListQueryKeys } from '@business/design_resource/license/_queries/licenseQueryKeys';
import { providerQueryKeys } from '@business/design_resource/license/provider/_queries/providerQueryKeys';
import { licenseTypeListQueryKeys } from '@business/design_resource/license_type/_queries/licenseTypeQueryKeys';
import { pictureElementQueryKeys } from '@business/design_resource/picture/_queries/pictureQueryKeys';
import { designResourceListQueryKeys } from '@business/design_resource/resources/_queries/resourceQueryKeys';
import { templateQueryKeys } from '@business/design_resource/template/_queries/templateQueryKeys';
import { textElementQueryKeys } from '@business/design_resource/text/_queries/textElementQueryKeys';
import { userResourceQueryKeys } from '@business/design_resource/user_resource/list/_queries/userResourceQueryKeys';
import { videoElementQueryKeys } from '@business/design_resource/video/_queries/videoElementQueryKeys';
import { editorConfigQueryKeys } from '@business/editor/editor_configs/_queries/editorConfigQueryKeys';
import { editorInitConfigQueryKeys } from '@business/editor/editor_init_configs/_queries/editorInitConfigQueryKeys';
import { launcherParameterQueryKeys } from '@business/editor/editor_init_configs/_queries/launcherParameterQueryKeys';
import { webEnvironmentQueryKeys } from '@business/notice/_queries/webEnvironmentQueryKeys';
import { paymentQueryKeys } from '@business/payment/_queries/usePaymentQueryKeys';
import { couponQueryKeys } from '@business/payment/coupon/_queries/couponQueryKeys';
import { estimatedBillingQueryKeys } from '@business/payment/subscription/estimated_billing/_queries/estimatedBillingQueryKeys';
import { workspaceStatusQueryKeys } from '@business/payment/subscription/teams/_queries/workspaceStatusQueryKeys';
import { userQueryKeys } from '@business/user/_queries/userQueryKeys';
import { userInfoSurveyQueryKeys } from '@business/user/user_info_survey/_queries/userInfoSurveyQueryKeys';
import { brandKitQuerykeys } from '@business/workspace/brandkit/_queries/brandKitQueryKeys';
import { externalFolderQueryKeys } from '@business/workspace/external_folder/_queries/externalFolderQueryKeys';
import { folderQueryKeys } from '@business/workspace/folder/_queries/folderQueryKeys';
import { inviteFriendQueryKeys } from '@business/workspace/invite_friend/_queries/inviteFriendQueryKeys';
import { logoQueryKeys } from '@business/workspace/logo/_queries/brandLogoQueryKey';
import { teamQueryKeys } from '@business/workspace/teams/_queries/teamQueryKeys';
import { drivesQueryKeys } from '@business/workspace/teams/drives/_queries/drivesQueryKeys';
import { uploadQueryKeys } from '@business/workspace/upload/_queries/uploadQueryKeys';
import { userFontQueryKeys } from '@business/workspace/upload/fonts/_queries/userFontQuerykeys';
import { recentlyViewedTemplateQueryKeys } from '@domain/template/recently_viewed/_business/_queries/recentlyViewedTemplateQueryKeys';

export const queryKeys = {
  ...userQueryKeys,
  ...editorConfigQueryKeys,
  ...backgroundQueryKeys,
  ...templateQueryKeys,
  ...textElementQueryKeys,
  ...audioElementQueryKeys,
  ...keywordCategoryQueryKeys,
  ...searchQueryKeys,
  ...fontListQueryKeys,
  ...videoElementQueryKeys,
  ...fontFamilyQueryKeys,
  ...favoriteQueryKeys,
  ...providerQueryKeys,
  ...brandKitQuerykeys,
  ...drivesQueryKeys,
  ...folderQueryKeys,
  ...userResourceQueryKeys,
  ...uploadQueryKeys,
  ...teamQueryKeys,
  ...workspaceStatusQueryKeys,
  ...pictureElementQueryKeys,
  ...elementQueryKeys,
  ...licenseListQueryKeys,
  ...licenseTypeListQueryKeys,
  ...logoQueryKeys,
  ...estimatedBillingQueryKeys,
  ...designMemberQueryKeys,
  ...couponQueryKeys,
  ...designInfoQueryKeys,
  ...designShareQueryKeys,
  ...launcherParameterQueryKeys,
  ...designTypeQueryKeys,
  ...editorInitConfigQueryKeys,
  ...designDraftQueryKeys,
  ...designResourceQueryKeys,
  ...designResourceListQueryKeys,
  ...designHistoryQueryKeys,
  ...downloadLimitCountQueryKeys,
  ...downloadExternalResourceQueryKeys,
  ...userFontQueryKeys,
  ...externalFolderQueryKeys,
  ...userInfoSurveyQueryKeys,
  ...webEnvironmentQueryKeys,
  ...inviteFriendQueryKeys,
  ...aiWritingQueryKeys,
  ...aiChatbotQueryKeys,
  ...paymentQueryKeys,
  ...aiPresentationQueryKeys,
  ...recentlyViewedTemplateQueryKeys,
} as const;

export const getQueryKey = (key: string, ...params: unknown[]): unknown[] => {
  if (params.every((param) => param)) {
    return [key, ...params];
  } else {
    return [key];
  }
};
