import { calcColumnCountByHeight, getJustifiedContentWidth } from './list_util';

export type MasonryListOption = {
  rowDefaultHeight: number;
  rowMaxHeight?: number;
  rowMaxWidth: number;
  contentMinWidth?: number;
  contentMaxWidth?: number;
  columnGap: number;
  rowGap: number;
};

export type RowContentInfo<ContentData> = {
  ratio: number;
  item: ContentData;
  maxWidth?: number;
};

export type RowInfo<ContentData> = {
  rowHeight: number;
  contents: RowContentInfo<ContentData>[];
};

/**
 * 데이터를 Masonry 형태로 보여주기 위한 row 배열로 변환. ListView 컴포넌트와 함께 사용
 * @param rawDataList 데이터 배열
 * @param getRatioFromRowData 데이터로부터 width / height 비율을 알아오는 방법을 함수로 정의
 * @param masonryOption masonry 리스트를 구성하기 위한 옵션
 */
export const createMasonryList = <ContentData>({
  rawDataList,
  getRatioFromRowData,
  ...masonryOption
}: {
  rawDataList: ContentData[];
  getRatioFromRowData: (data: ContentData) => number;
} & MasonryListOption): RowInfo<ContentData>[] => {
  const rawContentList = rawDataList.map((data) => ({
    item: data,
    ratio: getRatioFromRowData(data),
  }));

  const rows = [];
  let renderedCount = 0;

  while (renderedCount < rawContentList.length) {
    const row = createMasonryRow<ContentData>(
      rawContentList.slice(renderedCount, rawContentList.length),
      masonryOption
    );

    renderedCount += row.contents.length;
    rows.push(row);
  }

  return rows;
};

/**
 * Masonry 리스트의 한 행을 구성
 * rowDefaultHeight * 데이터의 ratio 값을 계속 더해가다가,
 * 이 값이 rowMaxWidth보다 커지는 순간의 데이터까지 row에 포함시킨다.
 */
export const createMasonryRow = <ContentData>(
  rawContentList: RowContentInfo<ContentData>[],
  {
    rowDefaultHeight,
    rowMaxWidth,
    columnGap,
    rowMaxHeight,
    contentMinWidth,
    contentMaxWidth,
  }: Pick<
    MasonryListOption,
    | 'rowMaxWidth'
    | 'rowDefaultHeight'
    | 'columnGap'
    | 'rowMaxHeight'
    | 'contentMinWidth'
    | 'contentMaxWidth'
  >
): RowInfo<ContentData> => {
  const columnCount = calcColumnCountByHeight(
    rawContentList.map((content) => content.ratio),
    { columnGap, rowDefaultHeight, rowMaxWidth, contentMinWidth, contentMaxWidth }
  );

  const row = rawContentList.slice(0, columnCount);

  const rowHeight = calculateMasonryRowHeight(row, {
    rowMaxWidth,
    columnGap,
    rowMaxHeight,
    rowDefaultHeight,
    contentMinWidth,
    contentMaxWidth,
  });

  /**
   * 각 아이템의 maxWidth 조정
   * - contentMaxWidth가 설정되어 있고, 이 너비를 초과하는 아이템은 max-width를 contentMaxWidth로 설정하게 함
   *   - maxWidth를 설정하지 않으면 row 밖으로 넘어가게 될 수 있음
   * - contentMaxWidth보다 작은 아이템은, row 내에 빈 공간이 있는 경우 아이템 너비가 늘어나서 빈 공간을 채우도록 함
   */
  if (contentMaxWidth && row.length >= 1) {
    row.forEach((content) => {
      const justifiedContentWidth = getJustifiedContentWidth({
        ratio: content.ratio,
        rowHeight: rowDefaultHeight,
        min: contentMinWidth,
        max: contentMaxWidth,
      });
      if (content.ratio * rowDefaultHeight > contentMaxWidth) {
        content.maxWidth = justifiedContentWidth * (rowHeight / rowDefaultHeight);
      }
    });
  }

  return {
    rowHeight,
    contents: row,
  };
};

/**
 * width - height 비율을 계산. 둘 중 하나라도 없다면 1을 반환
 * @param width
 * @param height
 */
export const calculateRatio = (width?: number, height?: number) => {
  if (!width || !height) {
    return 1;
  }

  return width / height;
};

/**
 * row에 포함된 데이터를 가지고 실제 row의 height 값을 계산.
 * 한 행이 rowMaxWidth를 꽉 채웠을 때, 모든 데이터의 높이가 동일하게 보여지도록 계산한다.
 * createMasonryList를 호출할 때 넘겨준 rowDefaultHeight 값보다 클 수도 있고 작을 수도 있다.
 * @param rawContentList
 * @param row
 * @param rowMaxWidth
 * @param columnGap
 */
const calculateMasonryRowHeight = <ContentData>(
  row: RowContentInfo<ContentData>[],
  {
    rowMaxWidth,
    columnGap,
    rowMaxHeight,
    rowDefaultHeight,
    contentMinWidth,
    contentMaxWidth,
  }: Pick<
    MasonryListOption,
    | 'rowMaxWidth'
    | 'rowDefaultHeight'
    | 'columnGap'
    | 'rowMaxHeight'
    | 'contentMinWidth'
    | 'contentMaxWidth'
  >
) => {
  const contentWidthList = row.map((rowItem) =>
    getJustifiedContentWidth({
      ratio: rowItem.ratio,
      rowHeight: rowDefaultHeight,
      min: contentMinWidth,
      max: contentMaxWidth,
    })
  );
  const ratioSum = contentWidthList.reduce((prev, width) => prev + width / rowDefaultHeight, 0);
  const gapSum = (contentWidthList.length - 1) * columnGap;
  const newHeight = (rowMaxWidth - gapSum) / ratioSum;

  return Math.min(newHeight, rowMaxHeight ?? Infinity);
};
