import { useMemo } from 'react';
import { ElementDesignResourceType } from '@miri-unicorn/miricanvas-api-client';
import { ELEMENT_TAB_TYPE } from '@business/design_resource_bar/tab/_constants/elementTabType';
import { useEditorFeature } from '@business/editor/editor_feature_limitations/_stores/useEditorFeature';

const animationTypes = new Set<ElementDesignResourceType>([
  'ANI',
  'EXTERNAL_ANI',
  'CONTRACTED_ANI',
]);

export const useElementTabTypes = () => {
  const { isTabAniEnabled } = useEditorFeature(['TAB_ANI']);
  return useMemo(() => {
    const disabledResourceTypes = isTabAniEnabled
      ? new Set<ElementDesignResourceType>()
      : animationTypes;
    return ELEMENT_TAB_TYPE.filter((tab) =>
      tab.editorFeatureKey === 'TAB_ANI' ? isTabAniEnabled : true
    ).map(
      ({ designResourceTypes, ...tab }) =>
        ({
          ...tab,
          designResourceTypes: designResourceTypes.filter(
            (type) => !disabledResourceTypes.has(type)
          ),
        } as const)
    );
  }, [isTabAniEnabled]);
};
