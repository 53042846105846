import { isEqual } from './lodash_util';

/**
 * 원본 배열에서 mactching하여 n개의 인자를 가진 배열을 리턴받는 함수입니다.
 * @param arr 원본배열
 * @param predicate matching 할 predicate 함수
 * @param n 패턴 순서대로 몇개까지 가져올 것인지
 * @returns
 */
export const getMatchingElements = <T>(
  arr: T[],
  predicate: (value: T) => boolean,
  n: number
): T[] => {
  const result = [];
  for (let i = 0; i < arr.length && result.length < n; i++) {
    if (predicate(arr[i])) {
      result.push(arr[i]);
    }
  }
  return result;
};

/**
 * arr1 과 arr2 에 공통된 요소가 있는지 확인하는 함수입니다.
 * @param arr1,arr2 The arrays to inspect.
 * @param iteratee  The iteratee invoked per element.
 *
 * @example
 * hasCommonValue([1.2, 2, 3], [5, 7]) => false
 * hasCommonValue([1.2, 2], [1.3, 4], Math.floor) => true
 */
export const hasCommonValue = <T, K>(
  arr1: T[],
  arr2: K[],
  iteratee?: (param: T | K) => unknown
): boolean => {
  /**
   * null, undefined 예외처리
   */
  if (!arr1 || !arr2) {
    return false;
  }
  /**
   * 빈 배열 예외처리
   */
  if (arr1.length === 0 || arr2.length === 0) {
    return false;
  }
  const arr1Iteratee = iteratee ? arr1.map(iteratee) : arr1;
  const arr2Iteratee = iteratee ? arr2.map(iteratee) : arr2;
  return arr1Iteratee.some((value) => arr2Iteratee.includes(value));
};

/**
 * 두 개의 배열이 같은지 요소들을 얕은 비교로 확인
 */
export const shallowEqual = <T>(arrA: readonly T[], arrB: readonly T[]): boolean => {
  if (!arrA || !arrB) {
    return false;
  }
  return arrA.length === arrB.length && arrA.every((a, i) => arrB[i] === a);
};

export const trimArrayToSize = <T>(
  arr: T[],
  size: number,
  trimPos: 'first' | 'last' = 'last'
): T[] => {
  if (arr.length <= size) {
    return arr;
  }

  if (trimPos === 'first') {
    return arr.slice(arr.length - size);
  }
  return arr.slice(0, size);
};

/**
 * {@link https://stackoverflow.com/questions/6274339/how-can-i-shuffle-an-array 알고리즘 참고}
 */
export const shuffle = <T>(arr: T[]): T[] => {
  const shuffledArr = arr.slice();

  for (let i = shuffledArr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArr[i], shuffledArr[j]] = [shuffledArr[j], shuffledArr[i]];
  }

  return shuffledArr;
};

export const allEqual = <T>(arr: T[]): boolean => {
  if (!arr || arr.length === 0) {
    return false;
  }
  return arr.every((value) => isEqual(value, arr[0]));
};

/**
 * boolean형 배열에서 index에 해당하는 값 토글
 */
export const toggleValueAtIndex = (index: number, array: boolean[]) => {
  return array.map((value, i) => (i === index ? !value : value));
};

/**
 * 검사할 배열이 정상적인경우 그대로 반환, 아니라면 기본값 반환
 * @param array 검사할 값
 * @param defaultArray 타겟이 배열이 아닌경우 리턴될값, 기본값은 빈 배열
 */
export const nvl = <T>(array: T[], defaultArray: T[] = []): T[] => {
  return Array.isArray(array) ? array : defaultArray;
};

/**
 * 2차원 배열 각 요소의 시작과 끝 인덱스를 계산합니다.
 * @param twoDimensionArray 이차원 배열
 * @example
 * calculateSubarrayIndexes([[1, 2, 3], [4, 5, 6]]) => [{ startIdx: 0, endIdx: 2 }, { startIdx: 3, endIdx: 5 }]
 */
export const calculateSubarrayIndexes = <T>(
  twoDimensionArray: T[][]
): { startIdx: number; endIdx: number }[] => {
  return twoDimensionArray.reduce<{ startIdx: number; endIdx: number }[]>((acc, cur) => {
    if (acc.length === 0) {
      return [{ startIdx: 0, endIdx: cur.length - 1 }];
    }
    const currentLength = acc[acc.length - 1].endIdx + 1;
    acc.push({ startIdx: currentLength, endIdx: currentLength + cur.length - 1 });
    return acc;
  }, []);
};
