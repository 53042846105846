"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var SocialTypes = {
  KAKAO: 'KAKAO',
  NAVER: 'NAVER',
  GOOGLE: 'GOOGLE',
  FACEBOOK: 'FACEBOOK',
  WHALE: 'WHALE',
  LINE: 'LINE',
  APPLE: 'APPLE'
};