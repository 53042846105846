/**
 * ACTION_UPPERCASE_NAME 형태만 가능
 * 주로 ACTION_행위_대상 형태로 이름을 짓는다.
 * @example ACTION_CLICK_DOWNLOAD_BUTTON
 */
type ActionName = `ACTION_${Uppercase<string>}`;

type EventTypeEnumLike = {
  [S in ActionName]: S;
};

export const EVENT_TYPES = {
  /** 디자인 리소스를 캔버스에 추가하는 액션 (썸네일 클릭, 드래그 앤 드롭) */
  ACTION_ADD_DESIGN_RESOURCE_TO_CANVAS: 'ACTION_ADD_DESIGN_RESOURCE_TO_CANVAS',
  /** 디자인 리소스 검색 트리거 액션 - 사용자가 검색을 시도하는 경우 */
  ACTION_SEARCH_DESIGN_RESOURCE: 'ACTION_SEARCH_DESIGN_RESOURCE',
  /** 디자인 리소스가 사용자에게 노출되는 액션 */
  ACTION_VIEW_DESIGN_RESOURCE: 'ACTION_VIEW_DESIGN_RESOURCE',
  /** 업로드 아이템 클릭 액션 */
  ACTION_CLICK_UPLOAD_ITEM: 'ACTION_CLICK_UPLOAD_ITEM',
  /** 템플릿 아이템 클릭 액션 */
  ACTION_CLICK_TEMPLATE_ITEM: 'ACTION_CLICK_TEMPLATE_ITEM',
  /** 컬렉션 아이템 클릭 액션 */
  ACTION_CLICK_COLLECTION_ITEM: 'ACTION_CLICK_COLLECTION_ITEM',
  /** 컬렉션 내의 요소 아이템 클릭 액션 - ACTION_CLICK_ELEMENT_ITEM와 함께 발생할 수 있음 */
  ACTION_CLICK_ELEMENT_ITEM_IN_COLLECTION_ITEM: 'ACTION_CLICK_ELEMENT_ITEM_IN_COLLECTION_ITEM',
  ACTION_VIEW_THEME_ITEM: 'ACTION_VIEW_THEME_ITEM',
  ACTION_VIEW_UPLOAD_ITEM: 'ACTION_VIEW_UPLOAD_ITEM',
  /** 업로드 아이템 검색 액션 */
  ACTION_SEARCH_UPLOAD_ITEM: 'ACTION_SEARCH_UPLOAD_ITEM',
  ACTION_SEARCH_TEMPLATE_ITEM: 'ACTION_SEARCH_TEMPLATE_ITEM',
  ACTION_SEARCH_THEME_ITEM: 'ACTION_SEARCH_THEME_ITEM',
  ACTION_CLICK_YOUTUBE_TAB: 'ACTION_CLICK_YOUTUBE_TAB',
  /** 유튜브 이동 버튼 클릭 액션 */
  ACTION_CLICK_YOUTUBE_MOVE_BUTTON: 'ACTION_CLICK_YOUTUBE_MOVE_BUTTON',
  ACTION_CLICK_YOUTUBE_MAKE_BUTTON: 'ACTION_CLICK_YOUTUBE_MAKE_BUTTON',
  ACTION_CLICK_VIDEO_EDITOR_PROMOTION_BANNER: 'ACTION_CLICK_VIDEO_EDITOR_PROMOTION_BANNER',
  ACTION_CLICK_SLIDE_SHOW_BUTTON: 'ACTION_CLICK_SLIDE_SHOW_BUTTON',
  ACTION_CLICK_HEADER_SHARE_BUTTON: 'ACTION_CLICK_HEADER_SHARE_BUTTON',
  /** 상단바 > 더보기 버튼 클릭 액션 */
  ACTION_CLICK_MORE_MENU_BUTTON: 'ACTION_CLICK_MORE_MENU_BUTTON',
  /** 상단바 > 더보기 팝업 > 승인 요청  */
  ACTION_CLICK_MORE_MENU_DRAFT_BUTTON: 'ACTION_CLICK_MORE_MENU_DRAFT_BUTTON',
  /** 상단바 > 더보기 팝업 > 인쇄물 제작하기  */
  ACTION_CLICK_MORE_MENU_PRINTOUT_BUTTON: 'ACTION_CLICK_MORE_MENU_PRINTOUT_BUTTON',
  /** 상단바 > 더보기 팝업 > 다운로드 */
  ACTION_CLICK_MORE_MENU_DOWNLOAD_BUTTON: 'ACTION_CLICK_MORE_MENU_DOWNLOAD_BUTTON',
  /** 상단바 > 더보기 팝업 > 공유하기  */
  ACTION_CLICK_MORE_MENU_SHARE_BUTTON: 'ACTION_CLICK_MORE_MENU_SHARE_BUTTON',
  /** 상단바 > 더보기 팝업 > 슬라이드 쇼 보기 */
  ACTION_CLICK_MORE_MENU_SLIDE_SHOW_BUTTON: 'ACTION_CLICK_MORE_MENU_SLIDE_SHOW_BUTTON',
  /** 상단바 > 더보기 팝업 > HTML 코드 삽입하기 */
  ACTION_CLICK_MORE_MENU_DESIGN_EMBEDDING_BUTTON: 'ACTION_CLICK_MORE_MENU_DESIGN_EMBEDDING_BUTTON',
  /** 승인 팝업 내 승인자의 승인 모드 선택하는 select UI */
  ACTION_CLICK_DRAFT_REQUEST_POPUP_REQUEST_ROLE_SELECT_BUTTON:
    'ACTION_CLICK_DRAFT_REQUEST_POPUP_REQUEST_ROLE_SELECT_BUTTON',
  /** 승인 팝업 내 승인자의 승인 모드 중 "편집 모드" 선택 */
  ACTION_CLICK_DRAFT_REQUEST_POPUP_REQUEST_ROLE_SELECT_EDIT_MODE:
    'ACTION_CLICK_DRAFT_REQUEST_POPUP_REQUEST_ROLE_SELECT_EDIT_MODE',
  /** 승인 팝업 내 승인자의 승인 모드 중 "피드백 모드" 선택 */
  ACTION_CLICK_DRAFT_REQUEST_POPUP_REQUEST_ROLE_SELECT_FEEDBACK_MODE:
    'ACTION_CLICK_DRAFT_REQUEST_POPUP_REQUEST_ROLE_SELECT_FEEDBACK_MODE',
  /** 승인 팝업 내 승인자의 승인 모드 중 "보기 모드" 선택 */
  ACTION_CLICK_DRAFT_REQUEST_POPUP_REQUEST_ROLE_SELECT_VIEW_MODE:
    'ACTION_CLICK_DRAFT_REQUEST_POPUP_REQUEST_ROLE_SELECT_VIEW_MODE',
  /** 승인 팝업 내 승인자의 승인 모드 중 "삭제" 선택 */
  ACTION_CLICK_DRAFT_REQUEST_POPUP_REQUEST_ROLE_SELECT_DELETE:
    'ACTION_CLICK_DRAFT_REQUEST_POPUP_REQUEST_ROLE_SELECT_DELETE',
  ACTION_CLICK_FONT_FILTER: 'ACTION_CLICK_FONT_FILTER',
  ACTION_CLICK_FONT_FILTER_LIST: 'ACTION_CLICK_FONT_FILTER_LIST',
  ACTION_CLICK_FONT_FILTER_RESET: 'ACTION_CLICK_FONT_FILTER_RESET',
  ACTION_CLICK_FONT_FILTER_APPLY: 'ACTION_CLICK_FONT_FILTER_APPLY',
  ACTION_CLICK_TEXT_STYLE_RECOMMEND: 'ACTION_CLICK_TEXT_STYLE_RECOMMEND',
  ACTION_CLICK_EYE_DROPPER: 'ACTION_CLICK_EYE_DROPPER',
  ACTION_CLICK_SOLID_COLOR_PICKER: 'ACTION_CLICK_SOLID_COLOR_PICKER',
  ACTION_CLICK_GRADATION_COLOR_PICKER: 'ACTION_CLICK_GRADATION_COLOR_PICKER',
  ACTION_CLICK_BRAND_KIT: 'ACTION_CLICK_BRAND_KIT',
  ACTION_CLICK_PAGE_ANIMATION_PRESET: 'ACTION_CLICK_PAGE_ANIMATION_PRESET',
  ACTION_CLICK_MAIN_MENU_BUTTON: 'ACTION_CLICK_MAIN_MENU_BUTTON',
  ACTION_CLICK_WORKSPACE_MOVE_BUTTON: 'ACTION_CLICK_WORKSPACE_MOVE_BUTTON',
  ACTION_CLICK_HEADER_FOLDER_BUTTON: 'ACTION_CLICK_HEADER_FOLDER_BUTTON',
  ACTION_CLICK_DESIGN_RESIZE_BUTTON: 'ACTION_CLICK_DESIGN_RESIZE_BUTTON',
  ACTION_CLICK_RESIZE_DIRECT_INPUT_BUTTON: 'ACTION_CLICK_RESIZE_DIRECT_INPUT_BUTTON',
  ACTION_CLICK_DESIGN_PUBLIC_TOGGLE_BUTTON: 'ACTION_CLICK_DESIGN_PUBLIC_TOGGLE_BUTTON',
  ACTION_CLICK_THEME_ITEM: 'ACTION_CLICK_THEME_ITEM',
  ACTION_CLICK_OPEN_FONT_LIST: 'ACTION_CLICK_OPEN_FONT_LIST',
  ACTION_CLICK_FONT_LIST_CATEGORY_ALL: 'ACTION_CLICK_FONT_LIST_CATEGORY_ALL',
  ACTION_CLICK_FONT_LIST_CATEGORY_FAVORITE: 'ACTION_CLICK_FONT_LIST_CATEGORY_FAVORITE',
  ACTION_CLICK_FONT_LIST_CATEGORY_BRAND: 'ACTION_CLICK_FONT_LIST_CATEGORY_BRAND',
  ACTION_CLICK_CHANGE_ALL_SAME_FONT: 'ACTION_CLICK_CHANGE_ALL_SAME_FONT',
  ACTION_CLICK_FONT_LIST_RECENT: 'ACTION_CLICK_FONT_LIST_RECENT',
  ACTION_CLICK_SELECT_FONT_LIST: 'ACTION_CLICK_SELECT_FONT_LIST',
  ACTION_CLICK_TEXT_CONTENT_LOCK: 'ACTION_CLICK_TEXT_CONTENT_LOCK',
  ACTION_CLICK_CALENDAR: 'ACTION_CLICK_CALENDAR',
  ACTION_CLICK_APPLY_CALENDAR: 'ACTION_CLICK_APPLY_CALENDAR',
  ACTION_CLICK_TEXT_ANIMATION_TAB: 'ACTION_CLICK_TEXT_ANIMATION_TAB',
  ACTION_CLICK_PATTERN_BACKGROUND_COLOR: 'ACTION_CLICK_PATTERN_BACKGROUND_COLOR',
  ACTION_CLICK_PATTERN_BACKGROUND_SIZE: 'ACTION_CLICK_PATTERN_BACKGROUND_SIZE',
  ACTION_CLICK_ADD_TEXT_BUTTON: 'ACTION_CLICK_ADD_TEXT_BUTTON',
  ACTION_CLICK_LEFT_PANEL_MENU_BUTTON: 'ACTION_CLICK_LEFT_PANEL_MENU_BUTTON',
  ACTION_DRAG_LEFT_PANEL_RESIZE_BUTTON: 'ACTION_DRAG_LEFT_PANEL_RESIZE_BUTTON',
  ACTION_CLICK_TEMPLATE_SEARCH_LANGUAGE_BUTTON: 'ACTION_CLICK_TEMPLATE_SEARCH_LANGUAGE_BUTTON',
  ACTION_CLICK_HEADER_BAR_DOWNLOAD_BUTTON: 'ACTION_CLICK_HEADER_BAR_DOWNLOAD_BUTTON',
  ACTION_CLICK_PROPERTY_ALIGN: 'ACTION_CLICK_PROPERTY_ALIGN',
  ACTION_CLICK_PROPERTY_ZINDEX: 'ACTION_CLICK_PROPERTY_ZINDEX',
  ACTION_CLICK_PROPERTY_FLIP: 'ACTION_CLICK_PROPERTY_FLIP',
  ACTION_CLICK_PROPERTY_CROP: 'ACTION_CLICK_PROPERTY_CROP',
  ACTION_CLICK_PROPERTY_LINK: 'ACTION_CLICK_PROPERTY_LINK',
  ACTION_CLICK_PROPERTY_STYLE_COPY: 'ACTION_CLICK_PROPERTY_STYLE_COPY',
  ACTION_CLICK_PROPERTY_LOCK: 'ACTION_CLICK_PROPERTY_LOCK',
  ACTION_CLICK_BROWSE_VIDEO_TEMPLATE_DOWNLOAD_PROGRESS:
    'ACTION_CLICK_BROWSE_VIDEO_TEMPLATE_DOWNLOAD_PROGRESS',
  ACTION_CLICK_BROWSE_VIDEO_TEMPLATE_DOWNLOAD_COMPLETE:
    'ACTION_CLICK_BROWSE_VIDEO_TEMPLATE_DOWNLOAD_COMPLETE',
  ACTION_CLICK_MAKE_VIDEO_WITH_MY_DESIGN: 'ACTION_CLICK_MAKE_VIDEO_WITH_MY_DESIGN',
  ACTION_CLICK_DARK_MODE_ACTIVE_BUTTON: 'ACTION_CLICK_DARK_MODE_ACTIVE_BUTTON',
  ACTION_CLICK_LAYER_ACTIVE_BUTTON: 'ACTION_CLICK_LAYER_ACTIVE_BUTTON',
  ACTION_CLICK_PAGE_NUMBER_BUTTON: 'ACTION_CLICK_PAGE_NUMBER_BUTTON',
  ACTION_CLICK_PAGE_NUMBER_ACTIVE_BUTTON: 'ACTION_CLICK_PAGE_NUMBER_ACTIVE_BUTTON',
  ACTION_CLICK_TEMPLATE_CREATE_BUTTON: 'ACTION_CLICK_TEMPLATE_CREATE_BUTTON',
  ACTION_CLICK_CHANGE_TEMPLATE_SIZE_BUTTON: 'ACTION_CLICK_CHANGE_TEMPLATE_SIZE_BUTTON',
  ACTION_CLICK_HEADER_BAR_LOGIN_BUTTON: 'ACTION_CLICK_HEADER_BAR_LOGIN_BUTTON',
  ACTION_SIGN_IN_USER: 'ACTION_SIGN_IN_USER',
  ACTION_SIGN_UP_USER: 'ACTION_SIGN_UP_USER',
  ACTION_CLICK_BACKGROUND_COLOR_CHIP_BUTTON: 'ACTION_CLICK_BACKGROUND_COLOR_CHIP_BUTTON',
  ACTION_CLICK_BACKGROUND_PICTURE_TAB_BUTTON: 'ACTION_CLICK_BACKGROUND_PICTURE_TAB_BUTTON',
  ACTION_CLICK_BACKGROUND_PATTERN_TAB_BUTTON: 'ACTION_CLICK_BACKGROUND_PATTERN_TAB_BUTTON',
  ACTION_CLICK_BACKGROUND_EDIT_BUTTON: 'ACTION_CLICK_BACKGROUND_EDIT_BUTTON',
  ACTION_CLICK_TEMPLATE_SEARCH_FILTER_TIER_BUTTON:
    'ACTION_CLICK_TEMPLATE_SEARCH_FILTER_TIER_BUTTON',
  ACTION_CLICK_MORE_INFO_BUTTON: 'ACTION_CLICK_MORE_INFO_BUTTON',
  ACTION_CLICK_MORE_INFO_POPOVER_PRICE_INFO_BUTTON:
    'ACTION_CLICK_MORE_INFO_POPOVER_PRICE_INFO_BUTTON',
  ACTION_CLICK_CURRENT_THEME_INITIALIZE_BUTTON: 'ACTION_CLICK_CURRENT_THEME_INITIALIZE_BUTTON',
  ACTION_CLICK_RECOMMEND_THEME_MORE_INFO_BUTTON: 'ACTION_CLICK_RECOMMEND_THEME_MORE_INFO_BUTTON',
  ACTION_CLICK_THEME_SHUFFLE_BUTTON: 'ACTION_CLICK_THEME_SHUFFLE_BUTTON',
  ACTION_CLICK_THEME_SELECT_BUTTON: 'ACTION_CLICK_THEME_SELECT_BUTTON',
  ACTION_CLICK_APPLY_THEME_ALL_PAGE: 'ACTION_CLICK_APPLY_THEME_ALL_PAGE',
  ACTION_CLICK_HEADER_DESIGN_TITLE: 'ACTION_CLICK_HEADER_DESIGN_TITLE',
  ACTION_CLICK_MAIN_MENU_DESIGN_TITLE: 'ACTION_CLICK_MAIN_MENU_DESIGN_TITLE',
  ACTION_CLICK_LEFT_PANEL_EXPAND_BUTTON: 'ACTION_CLICK_LEFT_PANEL_EXPAND_BUTTON',
  ACTION_CLICK_KEYWORD_CATEGORY_INDICATOR_BUTTON: 'ACTION_CLICK_KEYWORD_CATEGORY_INDICATOR_BUTTON',
  ACTION_CLICK_KEYWORD_CATEGORY_FINAL_INDICATOR_BUTTON:
    'ACTION_CLICK_KEYWORD_CATEGORY_FINAL_INDICATOR_BUTTON',
  ACTION_CLICK_SHARE_PREVIEW_BUTTON: 'ACTION_CLICK_SHARE_PREVIEW_BUTTON',
  ACTION_CLICK_SHARE_LINK_COPY_BUTTON: 'ACTION_CLICK_SHARE_LINK_COPY_BUTTON',
  ACTION_CLICK_SHARE_SNS_FACEBOOK_BUTTON: 'ACTION_CLICK_SHARE_SNS_FACEBOOK_BUTTON',
  ACTION_CLICK_SHARE_SNS_TWITTER_BUTTON: 'ACTION_CLICK_SHARE_SNS_TWITTER_BUTTON',
  ACTION_CLICK_SHARE_SNS_KAKAO_TALK_BUTTON: 'ACTION_CLICK_SHARE_SNS_KAKAO_TALK_BUTTON',
  ACTION_CLICK_SHARE_SNS_KAKAO_STORY_BUTTON: 'ACTION_CLICK_SHARE_SNS_KAKAO_STORY_BUTTON',
  ACTION_CLICK_SHARE_SNS_NAVER_BLOG_BUTTON: 'ACTION_CLICK_SHARE_SNS_NAVER_BLOG_BUTTON',
  ACTION_CLICK_SHARE_SNS_NAVER_BAND_BUTTON: 'ACTION_CLICK_SHARE_SNS_NAVER_BAND_BUTTON',
  ACTION_CLICK_SHARE_PAGE_STYLE_DROPDOWN_BUTTON: 'ACTION_CLICK_SHARE_PAGE_STYLE_DROPDOWN_BUTTON',
  ACTION_CLICK_SHARE_PAGE_STYLE_SLIDE_BUTTON: 'ACTION_CLICK_SHARE_PAGE_STYLE_SLIDE_BUTTON',
  ACTION_CLICK_SHARE_PAGE_STYLE_SCROLL_BUTTON: 'ACTION_CLICK_SHARE_PAGE_STYLE_SCROLL_BUTTON',
  ACTION_CLICK_SHARE_PAGE_STYLE_QUIZE_BUTTON: 'ACTION_CLICK_SHARE_PAGE_STYLE_QUIZE_BUTTON',
  ACTION_CLICK_SHARE_DETAIL_OPTION_BUTTON: 'ACTION_CLICK_SHARE_DETAIL_OPTION_BUTTON',
  ACTION_CLICK_SHARE_DETAIL_OPTION_COPY_TOGGLE: 'ACTION_CLICK_SHARE_DETAIL_OPTION_COPY_TOGGLE',
  ACTION_CLICK_SHARE_DETAIL_OPTION_LIKE_AND_COMMENT_TOGGLE:
    'ACTION_CLICK_SHARE_DETAIL_OPTION_LIKE_AND_COMMENT_TOGGLE',
  ACTION_CLICK_SHARE_DETAIL_OPTION_SLIDE_SHOW_TOGGLE:
    'ACTION_CLICK_SHARE_DETAIL_OPTION_SLIDE_SHOW_TOGGLE',
  ACTION_CLICK_SHARE_DETAIL_OPTION_SHARE_TOGGLE: 'ACTION_CLICK_SHARE_DETAIL_OPTION_SHARE_TOGGLE',
  /** 모든 무료구독권 팝업 > StartForFreeCTA 클릭 */
  ACTION_CLICK_FREE_SUBSCRIPTION_POPUP_START_FOR_FREE_BUTTON:
    'ACTION_CLICK_FREE_SUBSCRIPTION_POPUP_START_FOR_FREE_BUTTON',
  /** 모든 무료구독권 팝업 > 닫기 버튼 클릭 */
  ACTION_CLICK_FREE_SUBSCRIPTION_POPUP_CLOSE_BUTTON:
    'ACTION_CLICK_FREE_SUBSCRIPTION_POPUP_CLOSE_BUTTON',
  /** 템플릿페이지 > 키워드 카테고리 > 가입 유도 배너 > 버튼 클릭 */
  ACTION_CLICK_TEMPLATE_SEARCH_PAGE_BANNER_BUTTON:
    'ACTION_CLICK_TEMPLATE_SEARCH_PAGE_BANNER_BUTTON',
  /** (해외 유저)템플릿 검색 페이지 > 가격 필터 > 무료 버튼 클릭 */
  ACTION_CLICK_TEMPLATE_SEARCH_PAGE_PRICE_FILTER_FREE:
    'ACTION_CLICK_TEMPLATE_SEARCH_PAGE_PRICE_FILTER_FREE',
  /** (해외 유저)템플릿 검색 페이지 > 가격 필터 > 유료 버튼 클릭 */
  ACTION_CLICK_TEMPLATE_SEARCH_PAGE_PRICE_FILTER_PREMIUM:
    'ACTION_CLICK_TEMPLATE_SEARCH_PAGE_PRICE_FILTER_PREMIUM',
  /** 일본 템플릿 추천 유됴 배너 클릭 */
  ACTION_CLICK_JAPAN_TEMPLATE_RECOMMEND_BANNER: 'ACTION_CLICK_JAPAN_TEMPLATE_RECOMMEND_BANNER',

  /** ㅡㅡㅡㅡㅡㅡㅡㅡㅡ아래부터는 기능 제약에 의한 업그레이드 팝업 나타날때 이벤트들입니다. 이 외 경우 위쪽으로 추가부탁드립니다.ㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡ */
  // 프리미엄 템플릿/요소 불러오기 시도 → 업그레이드 안내 팝업
  ACTION_USE_PREMIUM_CONTENTS_UPGRADE_POPUP: 'ACTION_USE_PREMIUM_CONTENTS_UPGRADE_POPUP',
  // 프리미엄 요소/사진 불러오기 → 다운로드 → 콘텐츠 구입 후 다운로드 → 한 달간 무료로 사용해보기 → 업그레이드 안내 팝업
  ACTION_DOWNLOAD_PREMIUM_RESOURCE_UPGRADE_POPUP: 'ACTION_DOWNLOAD_PREMIUM_RESOURCE_UPGRADE_POPUP',
  // 업로드 탭 → 정보 → 한 달간 무료로 사용하기 → 업그레이드 안내 팝업
  ACTION_CLICK_UPLOAD_INFO_UPGRADE_POPUP: 'ACTION_CLICK_UPLOAD_INFO_UPGRADE_POPUP',
  // 특정 요소 선택 → 상세의 좌상단 ... 버튼 → 폴더에 추가 → 업그레이드 안내 팝업
  ACTION_ADD_RESOURCE_TO_FOLDER_UPGRADE_POPUP: 'ACTION_ADD_RESOURCE_TO_FOLDER_UPGRADE_POPUP',
  // 우상단 ... → 템플릿 만들기 → 업그레이드 안내 팝업
  ACTION_MAKE_TEMPLATE_UPGRADE_POPUP: 'ACTION_MAKE_TEMPLATE_UPGRADE_POPUP',
  // 작업 내역 → 이 버전 복원 → 업그레이드 안내 팝업
  ACTION_REVERT_DESIGN_UPGRADE_POPUP: 'ACTION_REVERT_DESIGN_UPGRADE_POPUP',
  // 작업 내역 → 버전 상단 ... → 이 버전 복원하기 → 업그레이드 안내 팝업
  ACTION_REVERT_DESIGN_INDIVIDUAL_VERSION_UPGRADE_POPUP:
    'ACTION_REVERT_DESIGN_INDIVIDUAL_VERSION_UPGRADE_POPUP',
  // 작업 내역 → 버전 상단 ... → 사본 만들기 → 업그레이드 안내 팝업
  ACTION_COPY_DESIGN_HISTORY_UPGRADE_POPUP: 'ACTION_COPY_DESIGN_HISTORY_UPGRADE_POPUP',
  // 업로드 사진 선택 → 배경 제거 → 업그레이드 안내 팝업
  ACTION_REMOVE_BACKGROUND_UPGRADE_POPUP: 'ACTION_REMOVE_BACKGROUND_UPGRADE_POPUP',
  // 에디터 모드 선택 → 피드백 모드 → 업그레이드 안내 팝업
  ACTION_CHANGE_VIEW_MODE_UPGRADE_POPUP: 'ACTION_CHANGE_VIEW_MODE_UPGRADE_POPUP',
  // 디자인 공유 상세 옵션 → 비밀번호 사용 제외 옵션 off 시도 → 업그레이드 안내 팝업
  ACTION_PUBLISH_DESIGN_WEB_UPGRADE_POPUP: 'ACTION_PUBLISH_DESIGN_WEB_UPGRADE_POPUP',
  // 사용자 초대 → 역할 설정 → 피드백 가능 선택 시도 → 업그레이드 안내 팝업
  ACTION_INVITE_MEMBER_CHANGE_ROLE_UPGRADE_POPUP: 'ACTION_INVITE_MEMBER_CHANGE_ROLE_UPGRADE_POPUP',
  // 에디터 > 업로드 > 새 폴더
  ACTION_CREATE_UPLOAD_FOLDER_UPGRADE_POPUP: 'ACTION_CREATE_UPLOAD_FOLDER_UPGRADE_POPUP',
  // 에디터 > 속성창 > 애니메이션 > 적용 옵션 변경
  ACTION_CHANGE_ANIMATION_APPEARANCE_UPGRADE_POPUP:
    'ACTION_CHANGE_ANIMATION_APPEARANCE_UPGRADE_POPUP',
  // 에디터 > 속성창 > 애니메이션 > 속도 조절
  ACTION_CHANGE_ANIMATION_SPEED_UPGRADE_POPUP: 'ACTION_CHANGE_ANIMATION_SPEED_UPGRADE_POPUP',
  // 에디터 > 속성창 > 애니메이션 > 방향 변경
  ACTION_CHANGE_ANIMATION_DIRECTION_UPGRADE_POPUP:
    'ACTION_CHANGE_ANIMATION_DIRECTION_UPGRADE_POPUP',
  // 에디터 > 속성창 > 애니메이션 > 반대 방향으로 종료 옵션 선택
  ACTION_CHANGE_ANIMATION_OUTRO_DIRECTION_UPGRADE_POPUP:
    'ACTION_CHANGE_ANIMATION_OUTRO_DIRECTION_UPGRADE_POPUP',
  // 에디터 > 속성창 > 애니메이션 > 등장 시간 체크박스 클릭
  ACTION_CHANGE_ANIMATION_DELAY_UPGRADE_POPUP: 'ACTION_CHANGE_ANIMATION_DELAY_UPGRADE_POPUP',
  // 에디터 > 텍스트 > 속성창 > 애니메이션 > 단위 설정
  ACTION_CHANGE_ANIMATION_UNIT_UPGRADE_POPUP: 'ACTION_CHANGE_ANIMATION_UNIT_UPGRADE_POPUP',
  // 에디터 > 다운로드 > mp4 다운로드 횟수 제약 시 다운로드 버튼 클릭
  ACTION_DOWNLOAD_MP4_RESTRICTION_UPGRADE_POPUP: 'ACTION_DOWNLOAD_MP4_RESTRICTION_UPGRADE_POPUP',
  // 에디터 > 다운로드 > gif 다운로드 횟수 제약 시 다운로드 버튼 클릭
  ACTION_DOWNLOAD_GIF_RESTRICTION_UPGRADE_POPUP: 'ACTION_DOWNLOAD_GIF_RESTRICTION_UPGRADE_POPUP',
  // 에디터 > 공유 > 프리미엄 콘텐츠 무제한 이용
  ACTION_SHARE_PREMIUM_CONTENTS_UPGRADE_POPUP: 'ACTION_SHARE_PREMIUM_CONTENTS_UPGRADE_POPUP',
  // 에디터 > 글꼴 변경 > 글꼴 업로드 > 업그레이드 안내 팝업
  ACTION_UPLOAD_FONT_UPGRADE_POPUP: 'ACTION_UPLOAD_FONT_UPGRADE_POPUP',
  // 에디터 > 글꼴 변경 > 브랜드 칩 > 브랜드 글꼴 추가
  ACTION_USE_BRAND_KIT_UPGRADE_POPUP: 'ACTION_USE_BRAND_KIT_UPGRADE_POPUP',
  // 에디터 > 페이지 애니메이션 > 차분한
  ACTION_SET_CLAM_PAGE_ANIMATION_UPGRADE_POPUP: 'ACTION_SET_CLAM_PAGE_ANIMATION_UPGRADE_POPUP',
  // 에디터 > 페이지 애니메이션 > 화려한
  ACTION_SET_FANCY_PAGE_ANIMATION_UPGRADE_POPUP: 'ACTION_SET_FANCY_PAGE_ANIMATION_UPGRADE_POPUP',
  // 에디터 > 페이지 애니메이션 > 귀여운
  ACTION_SET_CUTE_PAGE_ANIMATION_UPGRADE_POPUP: 'ACTION_SET_CUTE_PAGE_ANIMATION_UPGRADE_POPUP',
  // 에디터 > 페이지 애니메이션 > 세련된
  ACTION_SET_SOPHISTICATED_PAGE_ANIMATION_UPGRADE_POPUP:
    'ACTION_SET_SOPHISTICATED_PAGE_ANIMATION_UPGRADE_POPUP',
  // 에디터 > 다운로드 > 동영상 다운로드 횟수 제약 도달 이전 업그레이드 유도 버튼 클릭
  ACTION_DOWNLOAD_VIDEO_COUNT_RESTRICTION_UPGRADE_POPUP:
    'ACTION_DOWNLOAD_VIDEO_COUNT_RESTRICTION_UPGRADE_POPUP',
  // 에디터 > 상단바 > Pro 사용해보기
  ACTION_CLICK_HEADER_PRO_INFO_UPGRADE_POPUP: 'ACTION_CLICK_HEADER_PRO_INFO_UPGRADE_POPUP',
  // 에디터 → 찜메뉴 → 찜 상단 배너 → 업그레이드 안내 팝업
  ACTION_CLICK_PICK_BANNER_UPGRADE_POPUP: 'ACTION_CLICK_PICK_BANNER_UPGRADE_POPUP',
  // 에디터 > 템플릿 > 검색 > 검색 결과 상단 배너
  ACTION_CLICK_TEMPLATE_SEARCH_HEADER_BANNER_UPGRADE_POPUP:
    'ACTION_CLICK_TEMPLATE_SEARCH_HEADER_BANNER_UPGRADE_POPUP',
  // 에디터 > 동영상 > 검색 > 검색 결과 상단 배너
  ACTION_CLICK_VIDEO_SEARCH_HEADER_BANNER_UPGRADE_POPUP:
    'ACTION_CLICK_VIDEO_SEARCH_HEADER_BANNER_UPGRADE_POPUP',
  // 에디터 > 오디오 > 검색 > 검색 결과 상단 배너
  ACTION_CLICK_AUDIO_SEARCH_HEADER_BANNER_UPGRADE_POPUP:
    'ACTION_CLICK_AUDIO_SEARCH_HEADER_BANNER_UPGRADE_POPUP',
  // 에디터 > 템플릿 > 검색 필드 클릭 > 하단 팝업
  ACTION_CLICK_TEMPLATE_SEARCH_FILTER_BANNER_UPGRADE_POPUP:
    'ACTION_CLICK_TEMPLATE_SEARCH_FILTER_BANNER_UPGRADE_POPUP',
  // 에디터 > 동영상 > 검색 필드 클릭 > 하단 팝업
  ACTION_CLICK_VIDEO_SEARCH_FILTER_BANNER_UPGRADE_POPUP:
    'ACTION_CLICK_VIDEO_SEARCH_FILTER_BANNER_UPGRADE_POPUP',
  // 에디터 > 오디오 > 검색 필드 클릭 > 하단 팝업
  ACTION_CLICK_AUDIO_SEARCH_FILTER_BANNER_UPGRADE_POPUP:
    'ACTION_CLICK_AUDIO_SEARCH_FILTER_BANNER_UPGRADE_POPUP',
  // 에디터 > 텍스트 > 검색 필드 클릭 > 하단 팝업
  ACTION_CLICK_TEXT_SEARCH_FILTER_BANNER_UPGRADE_POPUP:
    'ACTION_CLICK_TEXT_SEARCH_FILTER_BANNER_UPGRADE_POPUP',
  // 에디터 > AI로 디자인 시작하기 > 프레젠테이션 자동 생성 > 개요 만들기 클릭
  ACTION_CLICK_GENERATE_AI_PRESENTATION_OUTLINE_UPGRADE_POPUP:
    'ACTION_CLICK_GENERATE_AI_PRESENTATION_OUTLINE_UPGRADE_POPUP',
  // 에디터 > 구독유도팝업
  ACTION_OPEN_GLOBAL_TRIAL_UPGRADE_POPUP: 'ACTION_OPEN_GLOBAL_TRIAL_UPGRADE_POPUP',
  // 온보딩 > 웰컴팝업 > CTA > 클릭
  ACTION_CLICK_GLOBAL_TRIAL_WELCOME_POPUP_BUTTON: 'ACTION_CLICK_GLOBAL_TRIAL_WELCOME_POPUP_BUTTON',
  ACTION_OPEN_UNKNOWN_FLOW_UPGRADE_POPUP: 'ACTION_OPEN_UNKNOWN_FLOW_UPGRADE_POPUP',
  ACTION_CLICK_SHARE_TEMPLATE_LINK_SHARE_BUTTON: 'ACTION_CLICK_SHARE_TEMPLATE_LINK_SHARE_BUTTON',
  ACTION_CLICK_SHARE_MEMBER_INVITE_TAB_BUTTON: 'ACTION_CLICK_SHARE_MEMBER_INVITE_TAB_BUTTON',
  ACTION_CLICK_SHARE_MEMBER_INVITE_EMAIL_TOGGLE: 'ACTION_CLICK_SHARE_MEMBER_INVITE_EMAIL_TOGGLE',
  ACTION_CLICK_MAIN_MENU_NEW_DESIGN_BUTTON: 'ACTION_CLICK_MAIN_MENU_NEW_DESIGN_BUTTON',
  ACTION_CLICK_MAIN_MENU_CREATE_COPY_BUTTON: 'ACTION_CLICK_MAIN_MENU_CREATE_COPY_BUTTON',
  ACTION_CLICK_MAIN_MENU_FIND_TEXT_BUTTON: 'ACTION_CLICK_MAIN_MENU_FIND_TEXT_BUTTON',
  ACTION_CLICK_MAIN_MENU_HELP_BUTTON: 'ACTION_CLICK_MAIN_MENU_HELP_BUTTON',
  /** 에디터 > 상단바 > 전체메뉴 > "피드백 및 보기 모드로 변경" 메뉴 클릭 */
  ACTION_CLICK_MAIN_MENU_CHANGE_VIEW_MODE_BUTTON: 'ACTION_CLICK_MAIN_MENU_CHANGE_VIEW_MODE_BUTTON',
  /** 에디터 > 상단바 > 전체메뉴 > "피드백 및 보기 모드로 변경" 팝오버 내 "편집 모드" 메뉴 클릭 */
  ACTION_CLICK_MAIN_MENU_EDIT_MODE_BUTTON: 'ACTION_CLICK_MAIN_MENU_EDIT_MODE_BUTTON',
  /** 에디터 > 상단바 > 전체메뉴 > "피드백 및 보기 모드로 변경" 팝오버 내 "피드백 모드" 메뉴 클릭 */
  ACTION_CLICK_MAIN_MENU_FEEDBACK_MODE_BUTTON: 'ACTION_CLICK_MAIN_MENU_FEEDBACK_MODE_BUTTON',
  /** 에디터 > 상단바 > 전체메뉴 > "피드백 및 보기 모드로 변경" 팝오버 내 "보기 모드" 메뉴 클릭 */
  ACTION_CLICK_MAIN_MENU_VIEW_MODE_BUTTON: 'ACTION_CLICK_MAIN_MENU_VIEW_MODE_BUTTON',
  ACTION_VIEW_TEMPLATE_ITEM: 'ACTION_VIEW_TEMPLATE_ITEM',
  ACTION_CLICK_GUIDE_LINE_MENU_BUTTON: 'ACTION_CLICK_GUIDE_LINE_MENU_BUTTON',
  ACTION_CLICK_ROW_GUIDE_LINE_BUTTON: 'ACTION_CLICK_ROW_GUIDE_LINE_BUTTON',
  ACTION_CLICK_COLUMN_GUIDE_LINE_BUTTON: 'ACTION_CLICK_COLUMN_GUIDE_LINE_BUTTON',
  ACTION_CLICK_GUIDE_LINE_RESET_BUTTON: 'ACTION_CLICK_GUIDE_LINE_RESET_BUTTON',
  ACTION_CLICK_GUIDE_LINE_TOGGLE: 'ACTION_CLICK_GUIDE_LINE_TOGGLE',
  ACTION_CLICK_GUIDE_LINE_LOCK_TOGGLE: 'ACTION_CLICK_GUIDE_LINE_LOCK_TOGGLE',
  ACTION_CLICK_GUIDE_LINE_RULER_TOGGLE: 'ACTION_CLICK_GUIDE_LINE_RULER_TOGGLE',
  ACTION_CLICK_SNAP_GUIDE_TOGGLE: 'ACTION_CLICK_SNAP_GUIDE_TOGGLE',
  ACTION_CLICK_AUTO_SAVE_TOGGLE: 'ACTION_CLICK_AUTO_SAVE_TOGGLE',
  ACTION_CLICK_INVITE_FRIEND_LINK_COPY_BUTTON: 'ACTION_CLICK_INVITE_FRIEND_LINK_COPY_BUTTON',
  ACTION_CLICK_TEMPLATE_TYPE_FILTER_BUTTON: 'ACTION_CLICK_TEMPLATE_TYPE_FILTER_BUTTON',
  ACTION_CLICK_TEMPLATE_SEARCH_FILTER_BUTTON: 'ACTION_CLICK_TEMPLATE_SEARCH_FILTER_BUTTON',
  ACTION_DOWNLOAD_EVENT: 'ACTION_DOWNLOAD_EVENT',
  ACTION_DOWNLOAD_FILE_TYPE_SELECT_EVENT: 'ACTION_DOWNLOAD_FILE_TYPE_SELECT_EVENT',
  ACTION_CLICK_DOWNLOAD_BUTTON_ALL_MODES: 'ACTION_CLICK_DOWNLOAD_BUTTON_ALL_MODES',
  ACTION_CLICK_DOWNLOAD_MODAL_MAKE_PRINT_BUTTON: 'ACTION_CLICK_DOWNLOAD_MODAL_MAKE_PRINT_BUTTON',

  /*  ======================= [QR 코드] ===========================  */
  /** 에디터 > 좌패널 > QR코드 > 만들기 클릭 (웹) */
  ACTION_CLICK_QR_CODE_MAKE_BUTTON: 'ACTION_CLICK_QR_CODE_MAKE_BUTTON',
  /** 에디터 모바일 > 좌패널 > QR코드 > 만들기 클릭 (모바일) */
  ACTION_CLICK_QR_CODE_MAKE_MOBILE_BUTTON: 'ACTION_CLICK_QR_CODE_MAKE_MOBILE_BUTTON',

  /** 에디터 > QR코드 속성창 > 심벌마크 스위치 토글 */
  ACTION_CLICK_QR_CODE_SYMBOL_MARK_SWITCH: 'ACTION_CLICK_QR_CODE_SYMBOL_MARK_SWITCH',
  /** 에디터 > QR코드 속성창 > 심벌마크 아이콘 리스트 아이템 클릭 */
  ACTION_CLICK_QR_CODE_SYMBOL_MARK_ICON: 'ACTION_CLICK_QR_CODE_SYMBOL_MARK_ICON',
  /** 에디터 > QR코드 속성창 > 심벌마크 이미지 업로드 버튼 클릭 */
  ACTION_CLICK_QR_CODE_SYMBOL_MARK_UPLOAD_BUTTON: 'ACTION_CLICK_QR_CODE_SYMBOL_MARK_UPLOAD_BUTTON',
  /** 에디터 > QR코드 속성창 > 심벌마크 이미지 업로드 완료 */
  ACTION_UPLOAD_QR_CODE_SYMBOL_MARK_IMAGE: 'ACTION_UPLOAD_QR_CODE_SYMBOL_MARK_IMAGE',

  /*  ======================= [AI 드로잉] ===========================  */
  /* 에디터 > 좌패널 > "AI 드로잉" 메뉴 클릭 */
  ACTION_OPEN_AI_DRAWING: 'ACTION_OPEN_AI_DRAWING',
  /* AI 드로잉 > 1뎁스 > "생성" 버튼 클릭 */
  ACTION_CLICK_GENERATE_AI_IMAGE_BUTTON: 'ACTION_CLICK_GENERATE_AI_IMAGE_BUTTON',
  /* AI 드로잉 > 2뎁스 > "생성 취소" 버튼 클릭 */
  ACTION_CLICK_CANCEL_GENERATING_AI_IMAGE_BUTTON: 'ACTION_CLICK_CANCEL_GENERATING_AI_IMAGE_BUTTON',
  /* AI 드로잉 > 2뎁스 > "생성 완료된 이미지" 클릭 */
  ACTION_CLICK_GENERATED_AI_IMAGE_WHEN_ALL_TASKS_ARE_UNFINISHED:
    'ACTION_CLICK_GENERATED_AI_IMAGE_WHEN_ALL_TASKS_ARE_UNFINISHED',
  /* AI 드로잉 > 3뎁스 > "생성 완료된 이미지" 클릭 */
  ACTION_CLICK_GENERATED_AI_IMAGE_WHEN_ALL_TASKS_ARE_FINISHED:
    'ACTION_CLICK_GENERATED_AI_IMAGE_WHEN_ALL_TASKS_ARE_FINISHED',
  /* AI 드로잉 > 3뎁스 > "다시 생성" 버튼 클릭 */
  ACTION_CLICK_RECREATE_AI_IMAGE_BUTTON: 'ACTION_CLICK_RECREATE_AI_IMAGE_BUTTON',
  /* AI 드로잉 > 3뎁스 > "돌아가기" 버튼 클릭 */
  ACTION_CLICK_BACK_TO_GENERATE_STEP_BUTTON: 'ACTION_CLICK_BACK_TO_GENERATE_STEP_BUTTON',
  /* AI 드로잉 > 3뎁스 > 생성 완료된 이미지 > "다시 생성" 버튼 클릭 */
  ACTION_CLICK_RECREATE_WITH_GENERATED_AI_IMAGE_BUTTON:
    'ACTION_CLICK_RECREATE_WITH_GENERATED_AI_IMAGE_BUTTON',
  /* AI 드로잉 > 3뎁스 > 모든 이미지 생성 완료된 후 결과 화면 렌더링 */
  ACTION_VIEW_RESULT_WHEN_ALL_TASKS_ARE_FINISHED: 'ACTION_VIEW_RESULT_WHEN_ALL_TASKS_ARE_FINISHED',
  /* AI 드로잉 > 입력화면 > "스타일 리스트 보기" 버튼 클릭 */
  ACTION_CLICK_SHOW_STYLE_LIST_BUTTON: 'ACTION_CLICK_SHOW_STYLE_LIST_BUTTON',
  /* AI 드로잉 > 입력화면 > 스타일 리스트 > "스타일 아이템" 클릭 */
  ACTION_CLICK_STYLE_ITEM: 'ACTION_CLICK_STYLE_ITEM',
  /* AI 드로잉 > 입력화면 > "예시 사용하기" 버튼 클릭 */
  ACTION_CLICK_USE_EXAMPLE_BUTTON: 'ACTION_CLICK_USE_EXAMPLE_BUTTON',
  /* AI 드로잉 > 입력화면 > 스타일 리스트 > "뒤로 가기" 버튼 클릭 */
  ACTION_CLICK_STYLE_LIST_BACK_BUTTON: 'ACTION_CLICK_STYLE_LIST_BACK_BUTTON',
  /* AI 드로잉 > 진입화면 > 카테고리 팩 리스트 > "카테고리 팩 아이템" 클릭 */
  ACTION_CLICK_CATEGORY_PACK_ITEM: 'ACTION_CLICK_CATEGORY_PACK_ITEM',
  /* AI 드로잉 > 결과화면 > 배경 제거 버튼 클릭 */
  ACTION_CLICK_AI_IMAGE_BACKGROUND_REMOVAL_BUTTON:
    'ACTION_CLICK_AI_IMAGE_BACKGROUND_REMOVAL_BUTTON',
  /* AI 드로잉 > 결과화면 > 이미지 프리뷰 클릭 */
  ACTION_CLICK_AI_IMAGE_PREVIEW: 'ACTION_CLICK_AI_IMAGE_PREVIEW',
  /* AI 드로잉 > 디자인에 어울리는 요소 생성 > 생성 버튼 클릭 */
  ACTION_CLICK_GENERATE_SUGGESTED_ELEMENT_BUTTON: 'ACTION_CLICK_GENERATE_SUGGESTED_ELEMENT_BUTTON',
  /* AI 도구, 쉬운 편집 > 업로드 */
  ACTION_UPLOAD_FOR_AI: 'ACTION_UPLOAD_FOR_AI',

  ACTION_CLICK_GLOBAL_UPDATE_MODAL_NEXT_BUTTON: 'ACTION_CLICK_GLOBAL_UPDATE_MODAL_NEXT_BUTTON',
  ACTION_CLICK_AI_DESIGN_ASSISTANT_POPUP_BUTTON: 'ACTION_CLICK_AI_DESIGN_ASSISTANT_POPUP_BUTTON',
  ACTION_CLICK_AI_DESIGN_ASSISTANT_POPUP_AI_DRAWING_BUTTON:
    'ACTION_CLICK_AI_DESIGN_ASSISTANT_POPUP_AI_DRAWING_BUTTON',
  ACTION_CLICK_AI_DESIGN_ASSISTANT_POPUP_AI_WRITING_BUTTON:
    'ACTION_CLICK_AI_DESIGN_ASSISTANT_POPUP_AI_WRITING_BUTTON',
  ACTION_CLICK_AI_DESIGN_ASSISTANT_POPUP_AI_CHATBOT_BUTTON:
    'ACTION_CLICK_AI_DESIGN_ASSISTANT_POPUP_AI_CHATBOT_BUTTON',
  ACTION_CLICK_DESIGN_SHARE_RESOURCE: 'ACTION_CLICK_DESIGN_SHARE_RESOURCE',
  /** 요소속성 관련 GA */
  ACTION_CLICK_PROPERTY_ELEMENT_MOVE: 'ACTION_CLICK_PROPERTY_ELEMENT_MOVE',
  ACTION_CLICK_PROPERTY_OVERLAPPING_ELEMENT_SELECT:
    'ACTION_CLICK_PROPERTY_OVERLAPPING_ELEMENT_SELECT',
  ACTION_CLICK_PROPERTY_ELEMENT_SIZE: 'ACTION_CLICK_PROPERTY_ELEMENT_SIZE',
  ACTION_CLICK_PROPERTY_ELEMENT_ANIMATION: 'ACTION_CLICK_PROPERTY_ELEMENT_ANIMATION',
  ACTION_CLICK_PROPERTY_ELEMENT_TRANSPARENCY: 'ACTION_CLICK_PROPERTY_ELEMENT_TRANSPARENCY',
  ACTION_CLICK_PROPERTY_ELEMENT_INFORMATION: 'ACTION_CLICK_PROPERTY_ELEMENT_INFORMATION',
  ACTION_CLICK_PROPERTY_COLOR: 'ACTION_CLICK_PROPERTY_COLOR',
  ACTION_CLICK_PROPERTY_FILTER: 'ACTION_CLICK_PROPERTY_FILTER',
  ACTION_CLICK_PROPERTY_ADJUSTMENT: 'ACTION_CLICK_PROPERTY_ADJUSTMENT',
  ACTION_CLICK_PROPERTY_EFFECT: 'ACTION_CLICK_PROPERTY_EFFECT',
  ACTION_CLICK_PROPERTY_MAKE_BACKGROUND: 'ACTION_CLICK_PROPERTY_MAKE_BACKGROUND',
  ACTION_CLICK_PROPERTY_DELETE_BACKGROUND: 'ACTION_CLICK_PROPERTY_DELETE_BACKGROUND',
  ACTION_CLICK_PROPERTY_INIT_BACKGROUND: 'ACTION_CLICK_PROPERTY_INIT_BACKGROUND',
  ACTION_CLICK_PROPERTY_INIT_PICTURE: 'ACTION_CLICK_PROPERTY_INIT_PICTURE',
  ACTION_CLICK_PROPERTY_MORE: 'ACTION_CLICK_PROPERTY_MORE',
  ACTION_CLICK_PROPERTY_MORE_COPY: 'ACTION_CLICK_PROPERTY_MORE_COPY',
  ACTION_CLICK_PROPERTY_MORE_PASTE: 'ACTION_CLICK_PROPERTY_MORE_PASTE',
  ACTION_CLICK_PROPERTY_MORE_MULTI_SELECT: 'ACTION_CLICK_PROPERTY_MORE_MULTI_SELECT',
  ACTION_CLICK_PROPERTY_MORE_LAYER: 'ACTION_CLICK_PROPERTY_MORE_LAYER',
  ACTION_CLICK_PROPERTY_MORE_ALIGN: 'ACTION_CLICK_PROPERTY_MORE_ALIGN',
  ACTION_CLICK_PROPERTY_MORE_DELETE: 'ACTION_CLICK_PROPERTY_MORE_DELETE',
  ACTION_CLICK_PROPERTY_EDIT_DATA: 'ACTION_CLICK_PROPERTY_EDIT_DATA',
  ACTION_CLICK_PROPERTY_PLAY: 'ACTION_CLICK_PROPERTY_PLAY',
  ACTION_CLICK_PROPERTY_STYLING: 'ACTION_CLICK_PROPERTY_STYLING',
  ACTION_CLICK_PROPERTY_FONT: 'ACTION_CLICK_PROPERTY_FONT',
  ACTION_CLICK_PROPERTY_TEXT_SIZE: 'ACTION_CLICK_PROPERTY_TEXT_SIZE',
  ACTION_CLICK_PROPERTY_TEXT_COLOR: 'ACTION_CLICK_PROPERTY_TEXT_COLOR',
  ACTION_CLICK_PROPERTY_BACKGROUND_COLOR: 'ACTION_CLICK_PROPERTY_BACKGROUND_COLOR',
  ACTION_CLICK_PROPERTY_FORM: 'ACTION_CLICK_PROPERTY_FORM',
  ACTION_CLICK_PROPERTY_TEXT_ADJUSTMENT: 'ACTION_CLICK_PROPERTY_TEXT_ADJUSTMENT',
  ACTION_CLICK_PROPERTY_UNGROUP: 'ACTION_CLICK_PROPERTY_UNGROUP',
  ACTION_CLICK_PROPERTY_MAKE_GROUP: 'ACTION_CLICK_PROPERTY_MAKE_GROUP',
  ACTION_CLICK_PROPERTY_DELETE: 'ACTION_CLICK_PROPERTY_DELETE',

  /* 에디터 > 사진/비트맵 속성창 > '자르기' 클릭 */
  ACTION_CLICK_PHOTO_PROPERTY_CROP: 'ACTION_CLICK_PHOTO_PROPERTY_CROP',
  /* 에디터 > 사진/비트맵 속성창 > '투명도' 클릭 */
  ACTION_CLICK_PHOTO_PROPERTY_TRANSPARENCY: 'ACTION_CLICK_PHOTO_PROPERTY_TRANSPARENCY',
  /* 에디터 > 사진/비트맵 속성창 > '정렬' 클릭 */
  ACTION_CLICK_PHOTO_PROPERTY_ALIGN: 'ACTION_CLICK_PHOTO_PROPERTY_ALIGN',
  /* 에디터 > 사진/비트맵 속성창 > '순서' 클릭 */
  ACTION_CLICK_PHOTO_PROPERTY_ORDER: 'ACTION_CLICK_PHOTO_PROPERTY_ORDER',
  /* 에디터 > 사진/비트맵 속성창 > '반전' 클릭 */
  ACTION_CLICK_PHOTO_PROPERTY_FLIP: 'ACTION_CLICK_PHOTO_PROPERTY_FLIP',
  /* 에디터 > 사진/비트맵 속성창 > '배경으로 설정' 클릭 */
  ACTION_CLICK_PHOTO_PROPERTY_SET_AS_BACKGROUND: 'ACTION_CLICK_PHOTO_PROPERTY_SET_AS_BACKGROUND',
  /* 에디터 > 사진/비트맵 속성창 > '사진 초기화' 클릭 */
  ACTION_CLICK_PHOTO_PROPERTY_RESET_PHOTO: 'ACTION_CLICK_PHOTO_PROPERTY_RESET_PHOTO',

  /*  ======================= [ 업로드 ] ===========================  */
  /* 업로드 > 아이템 > "더보기" 버튼 클릭 */
  ACTION_CLICK_UPLOAD_ITEM_MORE_BUTTON: 'ACTION_CLICK_UPLOAD_ITEM_MORE_BUTTON',
  /* 업로드 > 아이템 > "이동" 버튼 클릭 */
  ACTION_CLICK_UPLOAD_ITEM_MOVE_BUTTON: 'ACTION_CLICK_UPLOAD_ITEM_MOVE_BUTTON',
  /* 업로드 > 아이템 > "원본 다운로드" 버튼 클릭 */
  ACTION_CLICK_UPLOAD_ITEM_DOWNLOAD_BUTTON: 'ACTION_CLICK_UPLOAD_ITEM_DOWNLOAD_BUTTON',
  /* 업로드 > 아이템 > "휴지통으로 이동" 버튼 클릭 */
  ACTION_CLICK_UPLOAD_ITEM_DELETE_BUTTON: 'ACTION_CLICK_UPLOAD_ITEM_DELETE_BUTTON',
  /* 업로드 > 아이템 > "더보기" 버튼 클릭 | AI DRAWING 이벤트용 */
  ACTION_CLICK_UPLOAD_ITEM_MORE_BUTTON_FOR_AI: 'ACTION_CLICK_UPLOAD_ITEM_MORE_BUTTON_FOR_AI',
  /* 업로드 > 아이템 > "이동" 버튼 클릭 | AI DRAWING 이벤트용 */
  ACTION_CLICK_UPLOAD_ITEM_MOVE_BUTTON_FOR_AI: 'ACTION_CLICK_UPLOAD_ITEM_MOVE_BUTTON_FOR_AI',
  /* 업로드 > 아이템 > "원본 다운로드" 버튼 클릭 | AI DRAWING 이벤트용 */
  ACTION_CLICK_UPLOAD_ITEM_DOWNLOAD_BUTTON_FOR_AI:
    'ACTION_CLICK_UPLOAD_ITEM_DOWNLOAD_BUTTON_FOR_AI',
  /* 업로드 > 아이템 > "휴지통으로 이동" 버튼 클릭 | AI DRAWING 이벤트용 */
  ACTION_CLICK_UPLOAD_ITEM_DELETE_BUTTON_FOR_AI: 'ACTION_CLICK_UPLOAD_ITEM_DELETE_BUTTON_FOR_AI',

  /*  ======================= [템플릿 추천 챗봇] ===========================  */
  /** 템플릿 추천 챗봇 > 메시지 입력 후 전송 > 챗봇으로부터 온 응답에 추천된 템플릿 있을 경우 */
  ACTION_SEARCH_TEMPLATE_RECOMMEND_CHATBOT: 'ACTION_SEARCH_TEMPLATE_RECOMMEND_CHATBOT',
  /** 템플릿 추천 챗봇 > 메시지 입력 후 전송 > 챗봇으로부터 온 응답에 추천된 템플릿 채택(사용) */
  ACTION_CLICK_TEMPLATE_CHATBOT_ITEM: 'ACTION_CLICK_TEMPLATE_CHATBOT_ITEM',
  /** 템플릿 추천 챗봇 > 메시지 입력 후 전송 > 추천된 템플릿 채택(사용) */
  ACTION_VIEW_RECOMMENDED_TEMPLATE_IN_CHATBOT: 'ACTION_VIEW_RECOMMENDED_TEMPLATE_IN_CHATBOT',

  /* ======================= [AI 라이팅] =========================== */
  /** 에디터 > 텍스트 박스 클릭 > AI 라이팅 클릭 > AI 라이팅 하위 메뉴 클릭 */
  ACTION_CLICK_AI_WRITING_EDIT_TEXT_BUTTON: 'ACTION_CLICK_AI_WRITING_EDIT_TEXT_BUTTON',

  /* ======================= [AI 프레젠테이션] =========================== */
  /** 에디터 > 템플릿 패널 > 프레젠테이션 > AI 프레젠테이션 배너 클릭 수 */
  ACTION_CLICK_AI_PRESENTATION_TEMPLATE_BANNER: 'ACTION_CLICK_AI_PRESENTATION_TEMPLATE_BANNER',
  /** 템픞핏 타입 팝업 > AI 프레젠테이션 만들기 클릭 수 */
  ACTION_CLICK_AI_PRESENTATION_TEMPLATE_TYPE_POPUP_CREATE_BUTTON:
    'ACTION_CLICK_AI_PRESENTATION_TEMPLATE_TYPE_POPUP_CREATE_BUTTON',
  /** 에디터 > AI 진입점 버튼 > 프레젠테이션 자동 생성 클릭 수 */
  ACTION_CLICK_AI_PRESENTATION_BUTTON: 'ACTION_CLICK_AI_PRESENTATION_BUTTON',
  /** 에디터 > 프레젠테이션 자동 생성 > 개요 만들기 > 페이지 6장 클릭 수 */
  ACTION_CLICK_AI_PRESENTATION_GENERATE_OUTLINE_6PAGE_BUTTON:
    'ACTION_CLICK_AI_PRESENTATION_GENERATE_OUTLINE_6PAGE_BUTTON',
  /** 에디터 > 프레젠테이션 자동 생성 > 개요 만들기 > 페이지 7장 클릭 수 */
  ACTION_CLICK_AI_PRESENTATION_GENERATE_OUTLINE_7PAGE_BUTTON:
    'ACTION_CLICK_AI_PRESENTATION_GENERATE_OUTLINE_7PAGE_BUTTON',
  /** 에디터 > 프레젠테이션 자동 생성 > 개요 만들기 > 페이지 8장 클릭 수 */
  ACTION_CLICK_AI_PRESENTATION_GENERATE_OUTLINE_8PAGE_BUTTON:
    'ACTION_CLICK_AI_PRESENTATION_GENERATE_OUTLINE_8PAGE_BUTTON',
  /** 에디터 > 프레젠테이션 자동 생성 > 개요 만들기 > 다른 예시 클릭 수 */
  ACTION_CLICK_AI_PRESENTATION_GENERATE_OUTLINE_OTHER_EXAMPLE_BUTTON:
    'ACTION_CLICK_AI_PRESENTATION_GENERATE_OUTLINE_OTHER_EXAMPLE_BUTTON',
  /** 에디터 > 프레젠테이션 자동 생성 > 개요 만들기 > 개요 만들기 클릭 수 */
  ACTION_CLICK_AI_PRESENTATION_GENERATE_OUTLINE_BUTTON:
    'ACTION_CLICK_AI_PRESENTATION_GENERATE_OUTLINE_BUTTON',
  /** 에디터 > 프레젠테이션 자동 생성 > 개요 편집 > 뒤로가기 클릭 수 */
  ACTION_CLICK_AI_PRESENTATION_EDIT_OUTLINE_PREVIOUS_BUTTON:
    'ACTION_CLICK_AI_PRESENTATION_EDIT_OUTLINE_PREVIOUS_BUTTON',
  /** 에디터 > 프레젠테이션 자동 생성 > 개요 편집 > 페이지 6장 클릭 수 */
  ACTION_CLICK_AI_PRESENTATION_EDIT_OUTLINE_6PAGE_BUTTON:
    'ACTION_CLICK_AI_PRESENTATION_EDIT_OUTLINE_6PAGE_BUTTON',
  /** 에디터 > 프레젠테이션 자동 생성 > 개요 편집 > 페이지 7장 클릭 수 */
  ACTION_CLICK_AI_PRESENTATION_EDIT_OUTLINE_7PAGE_BUTTON:
    'ACTION_CLICK_AI_PRESENTATION_EDIT_OUTLINE_7PAGE_BUTTON',
  /** 에디터 > 프레젠테이션 자동 생성 > 개요 편집 > 페이지 8장 클릭 수 */
  ACTION_CLICK_AI_PRESENTATION_EDIT_OUTLINE_8PAGE_BUTTON:
    'ACTION_CLICK_AI_PRESENTATION_EDIT_OUTLINE_8PAGE_BUTTON',
  /** 에디터 > 프레젠테이션 자동 생성 > 개요 편집 > 재생성 클릭 수 */
  ACTION_CLICK_AI_PRESENTATION_EDIT_OUTLINE_REGENERATE_BUTTON:
    'ACTION_CLICK_AI_PRESENTATION_EDIT_OUTLINE_REGENERATE_BUTTON',
  /** 에디터 > 프레젠테이션 자동 생성 > 개요 편집 > 개요 말머리 클릭 수 */
  ACTION_CLICK_AI_PRESENTATION_EDIT_OUTLINE_TITLE_BUTTON:
    'ACTION_CLICK_AI_PRESENTATION_EDIT_OUTLINE_TITLE_BUTTON',
  /** 에디터 > 프레젠테이션 자동 생성 > 개요 편집 > 템플릿 선택 클릭 수 */
  ACTION_CLICK_AI_PRESENTATION_EDIT_OUTLINE_TEMPLATE_SELECT_BUTTON:
    'ACTION_CLICK_AI_PRESENTATION_EDIT_OUTLINE_TEMPLATE_SELECT_BUTTON',
  /** 에디터 > 프레젠테이션 자동 생성 > 템플릿선택 > 뒤로가기 클릭 수 */
  ACTION_CLICK_AI_PRESENTATION_TEMPLATE_SELECT_PREVIOUS_BUTTON:
    'ACTION_CLICK_AI_PRESENTATION_TEMPLATE_SELECT_PREVIOUS_BUTTON',
  /** 에디터 > 프레젠테이션 자동 생성 > 템플릿선택 > 템플릿 클릭 수 */
  ACTION_CLICK_AI_PRESENTATION_TEMPLATE_SELECT_BUTTON:
    'ACTION_CLICK_AI_PRESENTATION_TEMPLATE_SELECT_BUTTON',
  /** 에디터 > 프레젠테이션 자동 생성 > 템플릿선택 > 생성 클릭 수 */
  ACTION_CLICK_AI_PRESENTATION_TEMPLATE_GENERATE_BUTTON:
    'ACTION_CLICK_AI_PRESENTATION_TEMPLATE_GENERATE_BUTTON',
  /** 에디터 > 프레젠테이션 자동 생성 > 로딩중> 닫기 클릭 수 */
  ACTION_CLICK_AI_PRESENTATION_LOADING_CLOSE_BUTTON:
    'ACTION_CLICK_AI_PRESENTATION_LOADING_CLOSE_BUTTON',
  /** 에디터 > 프레젠테이션 자동 생성 > 로딩중> 플로팅 버튼 클릭 수 (유저가 닫고 다시 플로팅을 얼마나 누르는지) */
  ACTION_CLICK_AI_PRESENTATION_LOADING_FLOATING_BUTTON:
    'ACTION_CLICK_AI_PRESENTATION_LOADING_FLOATING_BUTTON',
  /** 에디터 > 프레젠테이션 자동 생성 > 생성 완료 > 덮어쓰기 클릭 수 */
  ACTION_CLICK_AI_PRESENTATION_OVERWRITE_BUTTON: 'ACTION_CLICK_AI_PRESENTATION_OVERWRITE_BUTTON',
  /** 에디터 > 프레젠테이션 자동 생성 > 생성 완료 > 새 디자인으로 만들기 클릭 수 */
  ACTION_CLICK_AI_PRESENTATION_CREATE_NEW_DESIGN_BUTTON:
    'ACTION_CLICK_AI_PRESENTATION_CREATE_NEW_DESIGN_BUTTON',
  /** 에디터 > 프레젠테이션 자동 생성 > 로딩 중 > 다시 시도 클릭 수 */
  ACTION_CLICK_AI_PRESENTATION_RETRY_BUTTON: 'ACTION_CLICK_AI_PRESENTATION_RETRY_BUTTON',

  /* ======================= [쉬운 편집] =========================== */
  /** 에디터 > 사진 속성창 > 쉬운편집 메뉴(배경제거/영역지우개/화질개선) 클릭 */
  ACTION_CLICK_PHOTO_EXTRA_EDIT_TOOL_BUTTON: 'ACTION_CLICK_PHOTO_EXTRA_EDIT_TOOL_BUTTON',
  /* ======================= [영역 지우개] =========================== */
  /** 에디터 > 영역 지우개 > 컨트롤러 패널 > 사용팁 버튼 클릭 */
  ACTION_CLICK_OBJECT_REMOVAL_GUIDE_BUTTON: 'ACTION_CLICK_OBJECT_REMOVAL_GUIDE_BUTTON',
  /** 에디터 > 영역 지우개 > 컨트롤러 패널 > 사용팁이 화면에 보여짐 */
  ACTION_VIEW_OBJECT_REMOVAL_GUIDE_POPOVER: 'ACTION_VIEW_OBJECT_REMOVAL_GUIDE_POPOVER',
  /** 에디터 > 영역 지우개 > 컨트롤러 패널 > 사용팁 팝오버 닫기 클릭 혹은 바깥 영역 클릭 */
  ACTION_CLOSE_OBJECT_REMOVAL_GUIDE_POPOVER: 'ACTION_CLOSE_OBJECT_REMOVAL_GUIDE_POPOVER',
  /** 에디터 > 사진 속성창 > 쉬운편집 메뉴 중 "영역 지우개" 클릭 */
  ACTION_CLICK_OBJECT_REMOVAL_TOOL_BUTTON: 'ACTION_CLICK_OBJECT_REMOVAL_TOOL_BUTTON',
  /** 에디터 > 영역 지우개 > 영역 선택/해제 버튼 클릭 */
  ACTION_CLICK_OBJECT_REMOVAL_AREA_SELECTION_BUTTON:
    'ACTION_CLICK_OBJECT_REMOVAL_AREA_SELECTION_BUTTON',
  /** 에디터 > 영역 지우개 > 지우기 버튼 클릭 */
  ACTION_CLICK_OBJECT_REMOVAL_ERASE_BUTTON: 'ACTION_CLICK_OBJECT_REMOVAL_ERASE_BUTTON',
  /** 에디터 > 영역 지우개 > 헤더 > 편집완료/초기화/원본이미지보기/돌아가기 버튼 클릭 */
  ACTION_CLICK_OBJECT_REMOVAL_HEADER_BUTTON: 'ACTION_CLICK_OBJECT_REMOVAL_HEADER_BUTTON',
  /** 에디터 > 영역 지우개 > 헤더 > 언두/리두 버튼 클릭 */
  ACTION_CLICK_OBJECT_REMOVAL_UNDO_REDO_BUTTON: 'ACTION_CLICK_OBJECT_REMOVAL_UNDO_REDO_BUTTON',
  /** 에디터 > 영역 지우개 > 헤더 > 돌아가기 버튼 클릭 */
  ACTION_CLICK_OBJECT_REMOVAL_BACK_BUTTON: 'ACTION_CLICK_OBJECT_REMOVAL_BACK_BUTTON',
  /** 에디터 > 영역 지우개 > 알림 팝업 > 에디터 다른 편집 먼저 진행 버튼 클릭 */
  ACTION_CLICK_OBJECT_REMOVAL_EDIT_OTHER_BUTTON: 'ACTION_CLICK_OBJECT_REMOVAL_EDIT_OTHER_BUTTON',
  /* ======================= [인쇄물 제작] =========================== */
  /** 상단바 > 인쇄물 제작하기 버튼 클릭 */
  ACTION_CLICK_HEADER_PRINTOUT_BUTTON: 'ACTION_CLICK_HEADER_PRINTOUT_BUTTON',
  /** 상단바 > 다운로드 > 설문조사 팝업 > 다시 보지 않기 버튼 */
  ACTION_CLICK_AFTER_DOWNLOAD_SURVEY_DO_NOT_SHOW_AGAIN_BUTTON:
    'ACTION_CLICK_AFTER_DOWNLOAD_SURVEY_DO_NOT_SHOW_AGAIN_BUTTON',
  /** 상단바 > 다운로드 > 설문조사 팝업 > 닫기 버튼 */
  ACTION_CLICK_AFTER_DOWNLOAD_SURVEY_CLOSE_BUTTON:
    'ACTION_CLICK_AFTER_DOWNLOAD_SURVEY_CLOSE_BUTTON',
  /** 상단바 > 미리캔버스 로고 버튼 */
  ACTION_CLICK_MIRICANVAS_LOGO_BUTTON: 'ACTION_CLICK_MIRICANVAS_LOGO_BUTTON',
  /** 해외유저 > 다운로드 진행팝업 > 다운로드 진행중일때 북마크바 등록 버튼 클릭 */
  ACTION_CLICK_ADD_BOOKMARK_BUTTON_WHEN_DOWNLOADING:
    'ACTION_CLICK_ADD_BOOKMARK_BUTTON_WHEN_DOWNLOADING',
  /** 해외유저 > 다운로드 완료팝업 > 다운로드 완료됬을 때 북마크바 등록 버튼 클릭 */
  ACTION_CLICK_ADD_BOOKMARK_BUTTON_AFTER_DOWNLOAD:
    'ACTION_CLICK_ADD_BOOKMARK_BUTTON_AFTER_DOWNLOAD',
  /** 해외유저 > 다운로드 완료팝업 > 북마크바 등록 단축키 안내 팝업 > 확인 버튼 클릭 */
  ACTION_CLICK_CONFIRM_BUTTON_ADD_BOOKMARK_GUIDE_POPUP:
    'ACTION_CLICK_CONFIRM_BUTTON_ADD_BOOKMARK_GUIDE_POPUP',
  /** 해외유저 > 다운로드 완료팝업 > 북마크바 등록 단축키 안내 팝업 > 닫기 버튼 클릭 */
  ACTION_CLICK_CLOSE_BUTTON_ADD_BOOKMARK_GUIDE_POPUP:
    'ACTION_CLICK_CLOSE_BUTTON_ADD_BOOKMARK_GUIDE_POPUP',
  /** 상단바 > 장바구니 추가 버튼 */
  ACTION_CLICK_ADD_TO_CART_BUTTON: 'ACTION_CLICK_ADD_TO_CART_BUTTON',
  /** 상단바 > 수정완료 버튼 */
  ACTION_CLICK_UPDATE_DONE_BUTTON: 'ACTION_CLICK_UPDATE_DONE_BUTTON',
  /** 템플릿 패널 > 가이드 적용 버튼 */
  ACTION_CLICK_TEMPLATE_PANEL_APPLY_PRINTOUT_GUIDE_BUTTON:
    'ACTION_CLICK_TEMPLATE_PANEL_APPLY_PRINTOUT_GUIDE_BUTTON',
  /* ================================== [템플릿] ================================== */
  /** 크기조정 팝업 > 인쇄용 가이드 적용 가능한 템플릿 사이즈로 변경 */
  ACTION_RESIZE_PRINTOUT_TEMPLATE_SIZE: 'ACTION_RESIZE_PRINTOUT_TEMPLATE_SIZE',
  /** 이 템플릿으로 덮어쓰기 > 인쇄용 가이드 적용 가능한 템플릿으로 덮어쓰기 실행 */
  ACTION_OVERWRITE_PRINTOUT_TEMPLATE: 'ACTION_OVERWRITE_PRINTOUT_TEMPLATE',
  /** 디자인 적용방식 선택 팝업 > 또는, 선택한 인쇄용 사이즈로 적용 */
  ACTION_CHANGE_TEMPLATE_WITH_PRINTOUT_SIZE: 'ACTION_CHANGE_TEMPLATE_WITH_PRINTOUT_SIZE',
  /* ======================= [템플릿 2.0 워크스페이스 관련]] =========================== */
  ACTION_VIEW_TEMPLATE_WORKSPACE: 'ACTION_VIEW_TEMPLATE_WORKSPACE',
  ACTION_SEARCH_TEMPLATE_WORKSPACE: 'ACTION_SEARCH_TEMPLATE_WORKSPACE',
  ACTION_CLICK_TEMPLATE_WORKSPACE: 'ACTION_CLICK_TEMPLATE_WORKSPACE',
  /** 사용자가 템플릿페이지/워크스페이스 내 템플릿탭에서 템플릿을 클릭하여 상세보기 팝업이 떴을 때의 정보 */
  ACTION_DETAIL_TEMPLATE_WORKSPACE: 'ACTION_DETAIL_TEMPLATE_WORKSPACE',
  ACTION_CLICK_SUCCESS_TEMPLATE_WORKSPACE: 'ACTION_CLICK_SUCCESS_TEMPLATE_WORKSPACE',
  ACTION_CLICK_CARET_BUTTON_OPEN_LEFT_MENUS: 'ACTION_CLICK_CARET_BUTTON_OPEN_LEFT_MENUS',
  ACTION_CLICK_CARET_BUTTON_CLOSE_LEFT_MENUS: 'ACTION_CLICK_CARET_BUTTON_CLOSE_LEFT_MENUS',
  ACTION_BY_AB_TEST: 'ACTION_BY_AB_TEST',
  ACTION_CLICK_LEFT_PANEL_UPLOAD_MENU_BUTTON: 'ACTION_CLICK_LEFT_PANEL_UPLOAD_MENU_BUTTON',
  ACTION_CLICK_MAIN_HEADER_SIGN_UP_BUTTON: 'ACTION_CLICK_MAIN_HEADER_SIGN_UP_BUTTON',
  ACTION_CLICK_MAIN_PAGE_FLOATING_SIGN_UP_BUTTON: 'ACTION_CLICK_MAIN_PAGE_FLOATING_SIGN_UP_BUTTON',
  ACTION_CLICK_MAIN_PAGE_SIGN_UP_CTA: 'ACTION_CLICK_MAIN_PAGE_SIGN_UP_CTA',
  ACTION_CLICK_GLOBAL_MAIN_PAGE_GO_TO_TEMPLATE_CTA:
    'ACTION_CLICK_GLOBAL_MAIN_PAGE_GO_TO_TEMPLATE_CTA',
  ACTION_CLICK_GLOBAL_MAIN_PAGE_GO_TO_EDITOR_CTA: 'ACTION_CLICK_GLOBAL_MAIN_PAGE_GO_TO_EDITOR_CTA',
  ACTION_CLICK_KOREA_MAIN_PAGE_FLOATING_BUTTON: 'ACTION_CLICK_KOREA_MAIN_PAGE_FLOATING_BUTTON',
  ACTION_CLICK_GLOBAL_MAIN_PAGE_FLOATING_BUTTON: 'ACTION_CLICK_GLOBAL_MAIN_PAGE_FLOATING_BUTTON',
  ACTION_CLICK_GLOBAL_MAIN_PAGE_START_NOW_BUTTON: 'ACTION_CLICK_GLOBAL_MAIN_PAGE_START_NOW_BUTTON',
  ACTION_CLICK_TEMPLATE_KEYWORD_BANNER: 'ACTION_CLICK_TEMPLATE_KEYWORD_BANNER',
  ACTION_VIEW_TEMPLATE_KEYWORD_BANNER: 'ACTION_VIEW_TEMPLATE_KEYWORD_BANNER',
  /** 에디터 > AI드로잉 > 업그레이드 안내 팝업 */
  ACTION_AI_TOOLS_UPGRADE_POPUP: 'ACTION_AI_TOOLS_UPGRADE_POPUP',
  /** 에디터 > 쉬운편집 > 영역지우개 */
  ACTION_OBJECT_REMOVAL_UPGRADE_POPUP: 'ACTION_OBJECT_REMOVAL_UPGRADE_POPUP',
  /** 에디터 > 쉬운편집 > 화질개선 */
  ACTION_UPSCALE_UPGRADE_POPUP: 'ACTION_UPSCALE_UPGRADE_POPUP',
  /* ======================= [앱 홈화면 관련] =========================== */
  ACTION_VIEW_APP_HOME: 'ACTION_VIEW_APP_HOME',
  ACTION_CLICK_APP_HOME_BOTTOM_TAB: 'ACTION_CLICK_APP_HOME_BOTTOM_TAB',
  ACTION_CLICK_APP_HOME_WORKSPACE_BOTTOM_TAB: 'ACTION_CLICK_APP_HOME_WORKSPACE_BOTTOM_TAB',
  /** 앱 > 홈화면 */
  ACTION_CLICK_APP_HOME_CARD_NEWS: 'ACTION_CLICK_APP_HOME_CARD_NEWS',
  ACTION_CLICK_APP_HOME_DETAIL_PAGE: 'ACTION_CLICK_APP_HOME_DETAIL_PAGE',
  ACTION_CLICK_APP_HOME_PRESENTATION: 'ACTION_CLICK_APP_HOME_PRESENTATION',
  ACTION_CLICK_APP_HOME_YOUTUBE_THUMBNAIL: 'ACTION_CLICK_APP_HOME_YOUTUBE_THUMBNAIL',
  ACTION_CLICK_APP_HOME_SNS_SQUARE: 'ACTION_CLICK_APP_HOME_SNS_SQUARE',
  ACTION_CLICK_APP_HOME_WEB_POSTER_LANDSCAPE: 'ACTION_CLICK_APP_HOME_WEB_POSTER_LANDSCAPE',
  ACTION_CLICK_APP_HOME_VIDEO: 'ACTION_CLICK_APP_HOME_VIDEO',
  ACTION_CLICK_APP_HOME_MORE: 'ACTION_CLICK_APP_HOME_MORE',
  ACTION_CLICK_APP_HOME_START_EMPTY: 'ACTION_CLICK_APP_HOME_START_EMPTY',
  ACTION_CLICK_APP_HOME_START_EMPTY_SELECT_TYPE: 'ACTION_CLICK_APP_HOME_START_EMPTY_SELECT_TYPE',
  ACTION_CLICK_APP_HOME_SEARCH: 'ACTION_CLICK_APP_HOME_SEARCH',
  /** 앱 > 홈화면 > 템플릿 더보기 */
  ACTION_CLICK_APP_HOME_MORE_TEMPLATE_BUTTON: 'ACTION_CLICK_APP_HOME_MORE_TEMPLATE_BUTTON',
  /** 앱 - 에디터 새로고침(중단) */
  ACTION_APP_EDITOR_CRASH: 'ACTION_APP_EDITOR_CRASH',
  /** 앱 - 에디터 네이티브 공유 */
  ACTION_APP_DESIGN_SHARE: 'ACTION_APP_DESIGN_SHARE',
  /** 미결제 다운그레이드 팝업 */
  ACTION_CLICK_DOWNGRADE_POPUP_SUBSCRIBE_BUTTON: 'ACTION_CLICK_DOWNGRADE_POPUP_SUBSCRIBE_BUTTON',
  ACTION_USER_INITIALIZE: 'ACTION_USER_INITIALIZE',
  /* ======================= [템플릿 찜 페이지] =========================== */
  ACTION_VIEW_TEMPLATE_MY_LIST: 'ACTION_VIEW_TEMPLATE_MY_LIST',
  ACTION_DETAIL_TEMPLATE_MY_LIST: 'ACTION_DETAIL_TEMPLATE_MY_LIST',
  ACTION_SEARCH_TEMPLATE_MY_LIST: 'ACTION_SEARCH_TEMPLATE_MY_LIST',
  ACTION_CLICK_TEMPLATE_MY_LIST: 'ACTION_CLICK_TEMPLATE_MY_LIST',
  ACTION_CLICK_SUCCESS_TEMPLATE_MY_LIST: 'ACTION_CLICK_SUCCESS_TEMPLATE_MY_LIST',
  ACTION_CLICK_TEMPLATE_FAVORITE_BUTTON: 'ACTION_CLICK_TEMPLATE_FAVORITE_BUTTON',
  ACTION_CLICK_FAVORITE_PAGE_ENTRY_POINT: 'ACTION_CLICK_FAVORITE_PAGE_ENTRY_POINT',
  ACTION_CLICK_MY_LIST_PICK_BANNER_UPGRADE_POPUP: 'ACTION_CLICK_MY_LIST_PICK_BANNER_UPGRADE_POPUP',
  ACTION_USE_MY_LIST_PREMIUM_CONTENTS_UPGRADE_POPUP:
    'ACTION_USE_MY_LIST_PREMIUM_CONTENTS_UPGRADE_POPUP',
  /* 에디터 > AI드로잉 > 프레젠테이션 만들기 클릭 */
  ACTION_CLICK_AI_PRESENTATION_BUTTON_IN_AI_TOOLS:
    'ACTION_CLICK_AI_PRESENTATION_BUTTON_IN_AI_TOOLS',
  /** 새 소식 > AI Presentation > 지금 사용해보기 클릭 */
  ACTION_CLICK_NEW_FEATURE_AI_PRESENTATION_USE_BUTTON:
    'ACTION_CLICK_NEW_FEATURE_AI_PRESENTATION_USE_BUTTON',
  /** 에디터 > 쉬운편집 > 인페인팅 */
  ACTION_INPAINTING_UPGRADE_POPUP: 'ACTION_INPAINTING_UPGRADE_POPUP',
  ACTION_CLICK_APPLY_AI_LAYOUT_UPGRADE_POPUP: 'ACTION_CLICK_APPLY_AI_LAYOUT_UPGRADE_POPUP',
  ACTION_EXPOSE_AI_LAYOUT_THUMBNAILS: 'ACTION_EXPOSE_AI_LAYOUT_THUMBNAILS',
  ACTION_CLICK_APPLY_AI_LAYOUT_THUMBNAIL: 'ACTION_CLICK_APPLY_AI_LAYOUT_THUMBNAIL',
  /** 온보딩 튜토리얼 */
  ACTION_ONBOARDING_TUTORIAL_FAVORITE_STEP: 'ACTION_ONBOARDING_TUTORIAL_FAVORITE_STEP',
  ACTION_ONBOARDING_TUTORIAL_TEMPLATE_STEP: 'ACTION_ONBOARDING_TUTORIAL_TEMPLATE_STEP',
  ACTION_ONBOARDING_TUTORIAL_ELEMENT_STEP: 'ACTION_ONBOARDING_TUTORIAL_ELEMENT_STEP',
  ACTION_ONBOARDING_TUTORIAL_UPLOAD_STEP: 'ACTION_ONBOARDING_TUTORIAL_UPLOAD_STEP',
  ACTION_ONBOARDING_TUTORIAL_AI_STEP: 'ACTION_ONBOARDING_TUTORIAL_AI_STEP',
  ACTION_ONBOARDING_TUTORIAL_DOWNLOAD_STEP: 'ACTION_ONBOARDING_TUTORIAL_DOWNLOAD_STEP',
  /** 고객정보수집팝업 > 타입선택 > 뷰 */
  ACTION_VIEW_TEMPLATE_TYPE_SURVEY: 'ACTION_VIEW_TEMPLATE_TYPE_SURVEY',
  /** 고객정보수집팝업 > 타입선택 > 항목 > 클릭  */
  ACTION_CLICK_ITEM_TEMPLATE_TYPE_SURVEY: 'ACTION_CLICK_ITEM_TEMPLATE_TYPE_SURVEY',
  /** 고객정보수집팝업 > 타입선택 > 닫기 > 클릭 */
  ACTION_CLICK_CLOSE_TEMPLATE_TYPE_SURVEY: 'ACTION_CLICK_CLOSE_TEMPLATE_TYPE_SURVEY',
  /** 고객정보수집팝업 > 타입선택 > 다음 > 클릭 */
  ACTION_CLICK_NEXT_TEMPLATE_TYPE_SURVEY: 'ACTION_CLICK_NEXT_TEMPLATE_TYPE_SURVEY',
  /** 고객정보수집팝업 > 키워드선택 > 뷰 */
  ACTION_VIEW_TEMPLATE_KEYWORD_SURVEY: 'ACTION_VIEW_TEMPLATE_KEYWORD_SURVEY',
  /** 고객정보수집팝업 > 키워드선택 > 항목 > 클릭  */
  ACTION_CLICK_ITEM_TEMPLATE_KEYWORD_SURVEY: 'ACTION_CLICK_ITEM_TEMPLATE_KEYWORD_SURVEY',
  /** 고객정보수집팝업 > 키워드선택 > 닫기 > 클릭   */
  ACTION_CLICK_CLOSE_TEMPLATE_KEYWORD_SURVEY: 'ACTION_CLICK_CLOSE_TEMPLATE_KEYWORD_SURVEY',
  /** 고객정보수집팝업 > 키워드선택 > 이전 > 클릭  */
  ACTION_CLICK_PREVIOUS_TEMPLATE_KEYWORD_SURVEY: 'ACTION_CLICK_PREVIOUS_TEMPLATE_KEYWORD_SURVEY',
  /** 고객정보수집팝업 > 키워드선택 > 다음 > 클릭  */
  ACTION_CLICK_NEXT_TEMPLATE_KEYWORD_SURVEY: 'ACTION_CLICK_NEXT_TEMPLATE_KEYWORD_SURVEY',
  /** 고객정보수집팝업 > 템플릿찜 > 뷰  */
  ACTION_VIEW_FAVORITE_TEMPLATE_SURVEY: 'ACTION_VIEW_FAVORITE_TEMPLATE_SURVEY',
  /** 고객정보수집팝업 > 템플릿찜 > 항목 > 클릭  */
  ACTION_CLICK_ITEM_FAVORITE_TEMPLATE_SURVEY: 'ACTION_CLICK_ITEM_FAVORITE_TEMPLATE_SURVEY',
  /** 고객정보수집팝업 > 템플릿찜 > 닫기 > 클릭  */
  ACTION_CLICK_CLOSE_FAVORITE_TEMPLATE_SURVEY: 'ACTION_CLICK_CLOSE_FAVORITE_TEMPLATE_SURVEY',
  /** 고객정보수집팝업 > 템플릿찜 > 이전 > 클릭  */
  ACTION_CLICK_PREVIOUS_FAVORITE_TEMPLATE_SURVEY: 'ACTION_CLICK_PREVIOUS_FAVORITE_TEMPLATE_SURVEY',
  /** 고객정보수집팝업 > 템플릿찜 > 찾아보기 > 클릭  */
  ACTION_CLICK_FIND_MORE_FAVORITE_TEMPLATE_SURVEY:
    'ACTION_CLICK_FIND_MORE_FAVORITE_TEMPLATE_SURVEY',
  /** 고객정보수집팝업 > 템플릿찜 > 써보기 > 클릭  */
  ACTION_CLICK_TRY_TEMPLATE_FAVORITE_TEMPLATE_SURVEY:
    'ACTION_CLICK_TRY_TEMPLATE_FAVORITE_TEMPLATE_SURVEY',
  /** 일본 온보딩 튜토리얼 툴팁 > 다음 > 클릭 */
  ACTION_CLICK_TUTORIAL_TOOLTIP_NEXT_BUTTON: 'ACTION_CLICK_TUTORIAL_TOOLTIP_NEXT_BUTTON',
  /** 일본 온보딩 튜토리얼 툴팁 > 완료 > 클릭 */
  ACTION_CLICK_TUTORIAL_TOOLTIP_COMPLETE_BUTTON: 'ACTION_CLICK_TUTORIAL_TOOLTIP_COMPLETE_BUTTON',
  /** 일본 온보딩 튜토리얼 툴팁 > 닫기 > 클릭 */
  ACTION_CLICK_TUTORIAL_TOOLTIP_CLOSE_BUTTON: 'ACTION_CLICK_TUTORIAL_TOOLTIP_CLOSE_BUTTON',
  /** 일본 온보딩 튜토리얼 툴팁 > 좌패널 튜토리얼 버튼 > 클릭 */
  ACTION_CLICK_TUTORIAL_TOOLTIP_MENU_OPEN_BUTTON: 'ACTION_CLICK_TUTORIAL_TOOLTIP_MENU_OPEN_BUTTON',
  /** 일본 온보딩 튜토리얼 툴팁 > 좌패널 튜토리얼 > 템플릿 바로 시작 > 클릭 */
  ACTION_CLICK_TUTORIAL_TOOLTIP_EXECUTE_ALL_STEP_BUTTON:
    'ACTION_CLICK_TUTORIAL_TOOLTIP_EXECUTE_ALL_STEP_BUTTON',
  /** 일본 온보딩 튜토리얼 툴팁 > 좌패널 튜토리얼 > 스텝 시작 > 클릭 */
  ACTION_CLICK_TUTORIAL_TOOLTIP_EXECUTE_SINGLE_STEP_BUTTON:
    'ACTION_CLICK_TUTORIAL_TOOLTIP_EXECUTE_SINGLE_STEP_BUTTON',
  ACTION_CLICK_MY_TEMPLATE_FLOATING_BUTTON: 'ACTION_CLICK_MY_TEMPLATE_FLOATING_BUTTON',
  /** 관심 템플릿 팝업 > 최근 본 탭 | 찜 탭 > 클릭 */
  ACTION_CLICK_MY_TEMPLATE_POPUP_TAB: 'ACTION_CLICK_MY_TEMPLATE_POPUP_TAB',
  /** "템플릿 페이지 > 관심 템플릿 팝업 내 로그인 버튼" 클릭 */
  ACTION_CLICK_MY_TEMPLATE_POPUP_LOGIN_BUTTON: 'ACTION_CLICK_MY_TEMPLATE_POPUP_LOGIN_BUTTON',
  /** 메인 페이지, 템플릿 페이지 > 30초 경과 > 팝업 */
  ACTION_VIEW_SIGN_UP_NUDGE_POPUP: 'ACTION_VIEW_SIGN_UP_NUDGE_POPUP',
  /** 메인 페이지, 템플릿 페이지 > 30초 경과 > 팝업 > 닫기 버튼 클릭 */
  ACTION_CLICK_CLOSE_BUTTON_SIGN_UP_NUDGE_POPUP: 'ACTION_CLICK_CLOSE_SIGN_UP_NUDGE_POPUP',
  /** 메인 페이지, 템플릿 페이지 > 30초 경과 > 팝업 > 회원 가입 버튼 클릭 */
  ACTION_CLICK_SIGN_UP_BUTTON_SIGN_UP_NUDGE_POPUP:
    'ACTION_CLICK_SIGN_UP_BUTTON_SIGN_UP_NUDGE_POPUP',
  /** 일본 온보딩 튜토리얼 툴팁 > 뷰 */
  ACTION_VIEW_TUTORIAL_TOOLTIP: 'ACTION_VIEW_TUTORIAL_TOOLTIP',
  /** AI 레이아웃 진입점 클릭 */
  ACTION_CLICK_AI_LAYOUT_ENTRY: 'ACTION_CLICK_AI_LAYOUT_ENTRY',
} as const satisfies EventTypeEnumLike;

export type EventType = ValueOf<typeof EVENT_TYPES>;

const EVENT_TYPES_ARRAY = Object.values(EVENT_TYPES);

export const getEventTypesAll = () => {
  return EVENT_TYPES_ARRAY;
};
