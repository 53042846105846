"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _on_complete_social_login = require("./on_complete_social_login");
Object.keys(_on_complete_social_login).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _on_complete_social_login[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _on_complete_social_login[key];
    }
  });
});
var _on_load_subscribe_products = require("./on_load_subscribe_products");
Object.keys(_on_load_subscribe_products).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _on_load_subscribe_products[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _on_load_subscribe_products[key];
    }
  });
});
var _on_payment_complete = require("./on_payment_complete");
Object.keys(_on_payment_complete).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _on_payment_complete[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _on_payment_complete[key];
    }
  });
});
var _on_response_file_select = require("./on_response_file_select");
Object.keys(_on_response_file_select).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _on_response_file_select[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _on_response_file_select[key];
    }
  });
});
var _on_load_device_info = require("./on_load_device_info");
Object.keys(_on_load_device_info).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _on_load_device_info[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _on_load_device_info[key];
    }
  });
});
var _on_native_back = require("./on_native_back");
Object.keys(_on_native_back).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _on_native_back[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _on_native_back[key];
    }
  });
});
var _on_change_user_setting = require("./on_change_user_setting");
Object.keys(_on_change_user_setting).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _on_change_user_setting[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _on_change_user_setting[key];
    }
  });
});
var _on_load_app_info = require("./on_load_app_info");
Object.keys(_on_load_app_info).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _on_load_app_info[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _on_load_app_info[key];
    }
  });
});
var _on_success_design_download = require("./on_success_design_download");
Object.keys(_on_success_design_download).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _on_success_design_download[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _on_success_design_download[key];
    }
  });
});
var _on_fail_design_download_or_share = require("./on_fail_design_download_or_share");
Object.keys(_on_fail_design_download_or_share).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _on_fail_design_download_or_share[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _on_fail_design_download_or_share[key];
    }
  });
});
var _on_request_ga_event = require("./on_request_ga_event");
Object.keys(_on_request_ga_event).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _on_request_ga_event[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _on_request_ga_event[key];
    }
  });
});