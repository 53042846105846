"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _WebViewEventType = require("./WebViewEventType");
Object.keys(_WebViewEventType).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _WebViewEventType[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _WebViewEventType[key];
    }
  });
});
var _payloads = require("./payloads");
Object.keys(_payloads).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _payloads[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _payloads[key];
    }
  });
});
var _WebViewEventRegistry = require("./WebViewEventRegistry");
Object.keys(_WebViewEventRegistry).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _WebViewEventRegistry[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _WebViewEventRegistry[key];
    }
  });
});