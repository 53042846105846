import { DesignResourceType } from '@miri-unicorn/miricanvas-api-client';
import { DesignResourcePanelMenuType } from '@constants/DesignResourcePanelMenu';

export const getIsDesignResourceInRestrictedTabType = (
  disabledTabs: DesignResourcePanelMenuType[],
  designResourceType: DesignResourceType
) => {
  switch (designResourceType) {
    case 'PICTURE':
    case 'EXTERNAL_PICTURE':
      return disabledTabs.includes('PICTURE');
    case 'VIDEO':
    case 'EXTERNAL_VIDEO':
      return disabledTabs.includes('VIDEO');
    case 'BACKGROUND_PATTERN':
    case 'BACKGROUND_PICTURE':
      return disabledTabs.includes('BACKGROUND');
    case 'INSTRUMENTAL':
    case 'VOCAL':
    case 'SFX':
    case 'RECORD':
    case 'EXTERNAL_INSTRUMENTAL':
    case 'EXTERNAL_VOCAL':
    case 'EXTERNAL_SFX':
    case 'EXTERNAL_RECORD':
    case 'AUDIO':
    case 'EXTERNAL_AUDIO':
      return disabledTabs.includes('AUDIO');
    case 'TEMPLATE':
      return disabledTabs.includes('TEMPLATE');
    default:
      return disabledTabs.includes('ELEMENTS');
  }
};
