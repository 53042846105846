"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PAYMENT_STATUS = void 0;
var PAYMENT_STATUS = exports.PAYMENT_STATUS = {
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
  CANCEL: 'CANCEL'
};