/**
 * 목적어에 따라서 조사인 '을' 또는 '를'을 반환함
 * https://doishalf.tistory.com/28
 *
 * https://github.com/toss/slash/blob/main/packages/common/hangul/src/josa.ts
 * 위 링크의 코드를 보고 을/를 뿐만 아니라 다른 조사에 대한 처리도 범용성 있게 할 수 있을 수 있을것 같다.
 */
export const get을또는를ByText = (text: string) => {
  // 빈 스트링이거나, 마지막 음절이 한글이 아닐 경우
  if (!text || !/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(text[text.length - 1])) {
    return '';
  }

  //text의 마지막 음절의 유니코드(UTF-16)
  const charCode = text.charCodeAt(text.length - 1);

  //유니코드의 한글 범위 내에서 해당 코드의 받침 확인
  const consonantCode = (charCode - 44032) % 28;

  if (consonantCode === 0) {
    //0이면 받침 없음 -> 를
    return `를`;
  }
  //1이상이면 받침 있음 -> 을
  return `을`;
};
