export const NamespaceEnum = {
  EDITOR: 'editor',
  SHARED: 'shared',
  WORKSPACE: 'workspace',
  HOMEPAGE: 'homepage',
} as const;

export const DEFAULT_NAMESPACE = NamespaceEnum.SHARED;

export type NamespaceType = ValueOf<typeof NamespaceEnum>;
