import { AudioDesignResourceType } from '@miri-unicorn/miricanvas-api-client';
import { DesignResourceTabType } from '@business/design_resource_bar/tab/_constants/designResourceTabType';

export type AudioTabType = DesignResourceTabType<AudioTabKey> & {
  listHeaderName: string;
  designResourceTypes: AudioDesignResourceType[];
};
export type AudioTabKey = 'INSTRUMENTAL' | 'SFX';

export const AUDIO_TAB_LIST: AudioTabKey[] = ['INSTRUMENTAL', 'SFX'];

export const AUDIO_TAB_TYPE: Record<AudioTabKey, AudioTabType> = {
  INSTRUMENTAL: {
    tabKey: 'INSTRUMENTAL',
    tabName: 'EDITOR.REMAIN_363',
    listHeaderName: 'EDITOR.REMAIN_367',
    designResourceTypes: ['INSTRUMENTAL', 'VOCAL', 'RECORD'],
  },
  SFX: {
    tabKey: 'SFX',
    tabName: 'EDITOR.REMAIN_364',
    listHeaderName: 'EDITOR.REMAIN_365',
    designResourceTypes: ['SFX'],
  },
};
