import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { GAParam } from '@configs/google_analytics/types/GACommonTypes';
import { GTMTriggerEventParams } from '@configs/google_analytics/types/GTMTriggerEventParams';

export const makeGAParamsViewTemplateTypeSurvey: GAParameterMakerFnType<
  'ACTION_VIEW_TEMPLATE_TYPE_SURVEY'
> = ({ data: { location1 } }) => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1,
    location2: '고객정보수집팝업',
    location3: '1단계',
    action1: '뷰',
    label1: `고객정보수집팝업_타입선택_뷰`,
  } as TemplateTypeSurveyReturnPayload;
};

type TemplateTypeSurveyReturnPayload = GAParam<GTMTriggerEventParams>;
