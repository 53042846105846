import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { GAParam } from '@configs/google_analytics/types/GACommonTypes';
import { GTMTriggerEventParams } from '@configs/google_analytics/types/GTMTriggerEventParams';

export const makeGAParamsClickTutorialTooltipNextButton: GAParameterMakerFnType<
  'ACTION_CLICK_TUTORIAL_TOOLTIP_NEXT_BUTTON'
> = ({ data: { location2, location3, location4 } }) => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터', // 에디터
    location2, // inflowType
    location3, // templateType
    location4, // tooltipType
    location5: 'CTA_다음', // buttonName
    action1: 'click',
    label1: `에디터_{튜토리얼진입점}_{템플릿타입명}_{툴팁명}_{버튼명}_{액션타입}`,
  } as TutorialTooltipNextButtonReturnPayload;
};

type TutorialTooltipNextButtonReturnPayload = GAParam<GTMTriggerEventParams>;
