import { LogUtil } from './log_util';

export const prefetchErrorLog = (error: Error, prefetchInfo: string) => {
  const stack = error?.stack?.split?.('\n')?.[1]?.trim(); // 에러 스택에서 파일 위치 추출
  const errorTime = new Date().toISOString();

  /**
   * axios error - web2 레포에는 axios 의존성이 없기에 임시로 이렇게 처리.
   * 추후 api-client에서 axios error를 참조할 수 있게하거나.. 다른 방법 생각 필요
   */
  if ('code' in error && 'response' in error) {
    LogUtil.error.dev(`${errorTime} ${prefetchInfo} API 에러 ${error.code} \n`, stack);
    return;
  }

  LogUtil.error.dev(`${errorTime} ${prefetchInfo} prefetch 로직 중 에러 ${error} \n`, stack);
};
