import deepEqual from 'fast-deep-equal';
import { atom, useAtomValue, useSetAtom } from 'jotai';
import { selectAtom } from 'jotai/utils';
import { PagePropsPayload } from '@miri-unicorn/miricanvas-editor';
import { getRootStore } from '@configs/jotai/RootProvider';

export const viewedPageAtom = atom<PagePropsPayload | undefined>(undefined);
viewedPageAtom.debugLabel = 'viewedPageAtom';

export const viewedPageIdxAtom = atom((get) => get(viewedPageAtom)?.idx);
viewedPageIdxAtom.debugLabel = 'viewedPageIdxAtom';

export const getViewedPageIdxSnapshotValue = () => getRootStore().get(viewedPageIdxAtom);

export const viewedPageThumbnailAtom = atom((get) => get(viewedPageAtom)?.thumbnailPath);
viewedPageThumbnailAtom.debugLabel = 'viewedPageThumbnailAtom';

export const selectedPageAtom = atom<PagePropsPayload | undefined>(undefined);
selectedPageAtom.debugLabel = 'selectedPageAtom';

export const useUpdatePageProps = () => {
  const setViewedPage = useSetAtom(viewedPageAtom);
  const setSelectedPage = useSetAtom(selectedPageAtom);

  return {
    setViewedPage,
    setSelectedPage,
  };
};

const deepViewedPageAtom = selectAtom(viewedPageAtom, (page) => page, deepEqual);
deepViewedPageAtom.debugLabel = 'deepViewedPageAtom';

export const useViewedPage = () => {
  return useAtomValue(deepViewedPageAtom);
};

export const useSelectedPage = () => {
  const selectedPage = useAtomValue(selectedPageAtom);
  return selectedPage;
};

export const getViewedPage = () => getRootStore().get(viewedPageAtom);

const pageCountAtom = selectAtom(viewedPageAtom, (page) => page?.pagesLength ?? 1);
pageCountAtom.debugLabel = 'pageCountAtom';

export const usePageCount = () => {
  return useAtomValue(pageCountAtom);
};
