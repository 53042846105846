/**
 * 특정 좌패널 > 필터의 색상 선택 컴포넌트에서 사용하는 util들
 */

/** 무지개색 버튼의 id */
export type CustomColorId = 'CustomColorId';

/**
 * @param colorList 색상칩들의 리스트
 * @param customColor 무지개색 버튼에서 선택한 색상
 * @param idx 무지개색 버튼을 선택한 경우 CustomColorId, 나머지 색상을 선택한 경우 number로 들어옴
 * @returns
 */
export const getFilterColorById = (
  colorList: HexColor[],
  customColor: string | null,
  idx: number | CustomColorId | null
) => {
  if (idx === null) {
    return null;
  }
  return idx === 'CustomColorId' ? customColor : colorList[idx];
};
