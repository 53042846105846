import { DesignResourceType } from '@miri-unicorn/miricanvas-api-client';
import { useDisabledMenuTabs } from '@business/editor/editor_feature_limitations/_services/_hooks/useDisabledMenuTabs';
import { getIsDesignResourceInRestrictedTabType } from '@business/editor/editor_feature_limitations/_services/_pures/getIsDesignResourceInRestrictedTabType';

export const useRestrictedTabType = () => {
  const { disabledTabs } = useDisabledMenuTabs();

  const getIsRestrictedType = (designResourceType: DesignResourceType) =>
    getIsDesignResourceInRestrictedTabType(disabledTabs, designResourceType);

  return {
    getIsRestrictedType,
  };
};
