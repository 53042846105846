const resetStyle = '\x1b[0m';

export const consoleStyles = {
  font: {
    bold: (s: string = '') => `\x1b[1m${s}${resetStyle}`,
    italic: (s: string = '') => `\x1b[3m${s}${resetStyle}`,
    underline: (s: string = '') => `\x1b[4m${s}${resetStyle}`,
  },
  color: {
    green: (s: string = '') => `\x1b[32m${s}${resetStyle}`,
    yellow: (s: string = '') => `\x1b[33m${s}${resetStyle}`,
    red: (s: string = '') => `\x1b[31m${s}${resetStyle}`,
    cyan: (s: string = '') => `\x1b[36m${s}${resetStyle}`,
    purple: (s: string = '') => `\x1b[35m${s}${resetStyle}`,
    gray: (s: string = '') => `\x1b[90m${s}${resetStyle}`,
  },
  reset: (s: string = '') => `${resetStyle}${s}`,
} as const;
