import { DesignResourceType, UserDesignResourceType } from '@miri-unicorn/miricanvas-api-client';
import { DesignResourcePanelMenuType } from '@constants/DesignResourcePanelMenu';
import { SearchTraceKey } from '@configs/google_analytics/features/design_resource/_stores/designResourceSearchTraceAtomFamily';
import {
  SimilarResourceByUserResourceSupportedPanelType,
  similarResourceByUserResourceTargetAtomFamily,
} from '@ui/design/_common/design_resource_panel/_stores/_common/subpage/similar_resource/similarResourceByUserResourceTargetAtomFamily';
import { getRootStore } from '@configs/jotai/RootProvider';
import { getRecommendResourceRowTargetAtom } from '@ui/design/_common/design_resource_panel/_stores/_common/useRecommendResourceRowTargetStore';
import {
  SimilarResourceSupportedPanelType,
  similarResourceTargetAtomFamily,
} from '@ui/design/_common/design_resource_panel/_stores/_common/subpage/similar_resource/similarResourceTargetAtomFamily';

// 비슷한 리소스 찾기의 타겟이 되는 리소스 정보
type BeginningResource = {
  /**
   * 미캔 내외부 리소스 => 리소스의 idx 값
   * 사용자 리소스 => 리소스의 key 값
   */
  key: string | number;
  resourceType: DesignResourceType | UserDesignResourceType;
};

export const getBeginningResource = (
  panelType: DesignResourcePanelMenuType,
  entryType: SearchTraceKey
): BeginningResource | null => {
  switch (entryType) {
    case 'SIMILAR_RESOURCE_PREVIEW':
      const target = getRootStore().get(getRecommendResourceRowTargetAtom(panelType));
      return target
        ? {
            key: target.idx!,
            resourceType: target.designResourceType,
          }
        : null;
    case 'SIMILAR_RESOURCE_SUBPAGE':
      const similarResourceTarget = getRootStore().get(
        similarResourceTargetAtomFamily(panelType as SimilarResourceSupportedPanelType)
      );
      if (similarResourceTarget) {
        return {
          key: similarResourceTarget.idx!,
          resourceType: similarResourceTarget.designResourceType,
        };
      }

      const similarResourceByUserResourceTarget = getRootStore().get(
        similarResourceByUserResourceTargetAtomFamily(
          panelType as SimilarResourceByUserResourceSupportedPanelType
        )
      );
      if (similarResourceByUserResourceTarget) {
        return {
          key: similarResourceByUserResourceTarget.resourceKey,
          resourceType: similarResourceByUserResourceTarget.resourceType,
        };
      }
      return null;
    default:
      return null;
  }
};
