"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _on_change_screen_orientation_lock = require("./on_change_screen_orientation_lock");
Object.keys(_on_change_screen_orientation_lock).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _on_change_screen_orientation_lock[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _on_change_screen_orientation_lock[key];
    }
  });
});
var _on_click_link = require("./on_click_link");
Object.keys(_on_click_link).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _on_click_link[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _on_click_link[key];
    }
  });
});
var _on_click_social_login = require("./on_click_social_login");
Object.keys(_on_click_social_login).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _on_click_social_login[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _on_click_social_login[key];
    }
  });
});
var _on_click_subscribe = require("./on_click_subscribe");
Object.keys(_on_click_subscribe).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _on_click_subscribe[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _on_click_subscribe[key];
    }
  });
});
var _on_request_native_system_open = require("./on_request_native_system_open");
Object.keys(_on_request_native_system_open).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _on_request_native_system_open[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _on_request_native_system_open[key];
    }
  });
});
var _on_complete_download = require("./on_complete_download");
Object.keys(_on_complete_download).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _on_complete_download[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _on_complete_download[key];
    }
  });
});
var _on_click_origin_downalod = require("./on_click_origin_downalod");
Object.keys(_on_click_origin_downalod).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _on_click_origin_downalod[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _on_click_origin_downalod[key];
    }
  });
});
var _on_share_design_link = require("./on_share_design_link");
Object.keys(_on_share_design_link).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _on_share_design_link[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _on_share_design_link[key];
    }
  });
});
var _on_init_payment_configure = require("./on_init_payment_configure");
Object.keys(_on_init_payment_configure).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _on_init_payment_configure[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _on_init_payment_configure[key];
    }
  });
});
var _on_request_copy = require("./on_request_copy");
Object.keys(_on_request_copy).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _on_request_copy[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _on_request_copy[key];
    }
  });
});
var _on_toggle_native_tab_show = require("./on_toggle_native_tab_show");
Object.keys(_on_toggle_native_tab_show).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _on_toggle_native_tab_show[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _on_toggle_native_tab_show[key];
    }
  });
});
var _on_change_native_bottom_tab = require("./on_change_native_bottom_tab");
Object.keys(_on_change_native_bottom_tab).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _on_change_native_bottom_tab[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _on_change_native_bottom_tab[key];
    }
  });
});
var _on_change_user_setting = require("./on_change_user_setting");
Object.keys(_on_change_user_setting).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _on_change_user_setting[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _on_change_user_setting[key];
    }
  });
});
var _on_click_with_feedback = require("./on_click_with_feedback");
Object.keys(_on_click_with_feedback).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _on_click_with_feedback[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _on_click_with_feedback[key];
    }
  });
});