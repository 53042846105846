import { doNothing } from '@miri-unicorn/miricanvas-utils/functions';
import { PrimitiveAtom, atom } from 'jotai';
import { atomFamily } from 'jotai/utils';
import { SetStateAction } from 'react';

/**
 * 특정 키에 대해서만 atom을 생성해주는 atomFamily를 생성하는 함수
 * @param allowedKeyList
 * @param createAtom
 * @param fallback atomFamily에 허용되지 않은 key가 들어왔을 때 반환할 fallback 값
 */
export const atomFamilyWithAllowedKeys = <Key extends string, AtomValue, Fallback>(
  allowedKeyList: readonly Key[],
  createAtom: (key: Key, ...params: unknown[]) => PrimitiveAtom<AtomValue>,
  fallback: Fallback
) => {
  const dummyAtom = atom<Fallback, [SetStateAction<AtomValue>], void>(fallback, doNothing);

  const rawFamily = atomFamily(createAtom);

  // 허용되지 않은 key가 들어올 경우 atomFamily에서 프로퍼티를 새로 만들지 않게 하기 위해 감쌈
  const familyWithValidation = (key: Key) => {
    if (!allowedKeyList.includes(key)) return dummyAtom;
    return rawFamily(key);
  };

  /**
   * 특정 키의 atom에 값을 세팅하는 아톰
   * 훅 실행 시점이 아니라 콜백 실행 시점에 key를 넘겨주고 싶을 경우 사용
   *
   * @example
   * const useSomeHookA = (panelKey: DesignResourceMenuType) => {
   *   // atomFamily에 useSetAtom 할 경우 -- useSetAtom 호출 시점에 panelKey를 알아야 함
   *   const setValue = useSetAtom(atomFamily(panelKey));
   *
   *   const onSomeAction = (resource: Resource) => {
   *     setValue(resource);
   *   }
   * }
   *
   * const useSomeHookB = () => {
   *   const setValue = useSetAtom(writeOnlyByKeyAtom);
   *
   *   const onSomeAction = (panelKey: DesignResourceMenuType, resource: Resource) => {
   *     // panelKey에 해당하는 atomFamily atom에 값이 세팅됨
   *     setValue({ key: panelKey, value: resource });
   *   }
   * }
   */
  const writeOnlyByKeyAtom = atom<null, [{ key: Key; value: AtomValue }], void>(
    null,
    (_, set, { key, value }) => {
      if (!allowedKeyList.includes(key)) return;
      const foundAtom = rawFamily(key);
      set(foundAtom, value);
    }
  );

  return { atomFamily: familyWithValidation, writeOnlyByKeyAtom };
};
