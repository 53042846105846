/**
 * @param min
 * @param max
 * @param excludeList min과 max 범위 내에서 제외할 숫자 리스트
 * @returns min과 max의 범위 내에 가능한 숫자가 없다면 항상 0을 반환합니다.
 */
export const getRandomNumber = ({
  min,
  max,
  excludeList = [],
}: {
  min: number;
  max: number;
  excludeList?: number[];
}): number => {
  const possibleNumbers = Array.from({ length: max - min + 1 }, (_, index) => min + index);
  const remainingNumbers = possibleNumbers.filter((num) => !excludeList.includes(num));
  const randomNumIdx = Math.floor(Math.random() * remainingNumbers.length);
  return remainingNumbers[randomNumIdx];
};

/** 양의실수 배열을 받아서, 각 실수를 min과 max사이로 정규화하는 메서드. */
export const normalize = (array: number[], min = 0, max = 1): number[] => {
  const pear = Math.max(...array);
  const multiplier = Math.pow(pear, -(max - min));
  return array.map((n) => n * multiplier + min);
};

// 린트 에러 임시 추가
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const validateNumber = (input?: any): boolean => {
  if (typeof input === 'number' && !isNaN(input) && isFinite(input)) {
    return true;
  }
  return false;
};

// 린트 에러 임시 추가
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const returnValidateNumber = (input?: any): number => {
  const result: number = validateNumber(input) ? input : -1;
  return result;
};

/** 숫자를 min과 max를 초과하지 않도록 조절 */
export const clampValue = (value: number, min: number, max: number) =>
  Math.min(Math.max(value, min), max);

/**
 * 숫자를 3자리마다 콤마를 찍어서 반환.
 * @param number 1000
 * @returns string 1,000
 */
export const formatNumberWithComma = (number: number): string => {
  return new Intl.NumberFormat().format(number);
};
