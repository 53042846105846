import { useUserQuery } from '@business/user/_queries/useUserQuery';
import { BuildEnv } from '@configs/environments/BuildEnv';
import { GAParameterMakerMap } from '@configs/google_analytics/GAParameterMakerMap';
import { optimizeGAParams } from '@configs/google_analytics/functions/optimizeGAParams';
import { emitWebViewEvent } from '@configs/webview/emitWebViewEvent';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { useEventsListener } from '@event/_hooks/useEventsListener';
import { getEventTypesAll } from '@event/_types/EventType';
import { Login200Data } from '@miri-unicorn/miricanvas-api-client';
import { LogUtil } from '@miri-unicorn/miricanvas-utils/functions';
import { WebViewEvent } from '@miri-unicorn/miricanvas-webview';
import { useIsomorphicLayoutEffect } from '@utils/hooks';
import { useEffect } from 'react';
import packageJson from '../../../package.json';
import {
  AI_CREATION_SCRIPT_URL,
  API_URL,
  BIZHOWS_API_URL,
  BIZHOWS_IMAGE_SERVER,
  getPrintoutModuleUrl,
  MIRICANVAS_URL,
  PAYMENT_GATEWAY_API_URL,
  S3,
} from '@configs/environments/base_url.ts';

export const useDatadog = () => {
  const { data } = useUserQuery();

  const datadogSetUser = (data: Login200Data) => {
    datadogRum.setUser({
      ...data,
      id: data.accountId.toString(),
      name: data.name,
      email: data.email,
    });
  };

  const datadogClearUser = () => {
    datadogRum.clearUser();
  };

  const ddCustomAction = (action: string, payload: Record<string, unknown>) => {
    if (BuildEnv.IS_LOCAL) return;
    datadogRum.addAction(action, payload);
  };

  useEffect(() => {
    if (BuildEnv.IS_LOCAL) return;
    if (!data) {
      datadogClearUser();
      emitWebViewEvent(WebViewEvent.ON_LOGOUT, undefined);
      return;
    }
    datadogSetUser(data.data!);
    emitWebViewEvent(WebViewEvent.ON_LOGIN, data?.data);
  }, [data]);

  useEventsListener(getEventTypesAll(), (type, payload) => {
    const gaHandler = GAParameterMakerMap[type];

    if (!gaHandler) {
      datadogLogs.logger.warn(
        `${type}에 대한 GA가 정의되어 있지 않습니다. 필요 시 GAParameterMakerMap에 정의해주세요.`
      );
      return;
    }

    const originParams = gaHandler(payload);

    const needOptimize = originParams.event !== 'AI_DRAWING';
    const params = needOptimize ? optimizeGAParams(originParams) : originParams;

    ddCustomAction(params.event, params);
  });

  useIsomorphicLayoutEffect(() => {
    if (BuildEnv.IS_LOCAL) return;
    if (!process.env.NEXT_PUBLIC_DATADOG_APP_ID || !process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN) {
      LogUtil.error.dev('DATADOG_APP_ID or DATADOG_CLIENT_TOKEN is not set');
      return;
    }
    // 알려진 봇 인스턴스와 일치하는 regex 패턴:
    const BOT_PATTERN =
      '(googlebot/|bot|Googlebot-Mobile|Googlebot-Image|Google favicon|Mediapartners-Google|bingbot|slurp|java|wget|curl|Commons-HttpClient|Python-urllib|libwww|httpunit|nutch|phpcrawl|msnbot|jyxobot|FAST-WebCrawler|FAST Enterprise Crawler|biglotron|teoma|convera|seekbot|gigablast|exabot|ngbot|ia_archiver|GingerCrawler|webmon |httrack|webcrawler|grub.org|UsineNouvelleCrawler|antibot|netresearchserver|speedy|fluffy|bibnum.bnf|findlink|msrbot|panscient|yacybot|AISearchBot|IOI|ips-agent|tagoobot|MJ12bot|dotbot|woriobot|yanga|buzzbot|mlbot|yandexbot|purebot|Linguee Bot|Voyager|CyberPatrol|voilabot|baiduspider|citeseerxbot|spbot|twengabot|postrank|turnitinbot|scribdbot|page2rss|sitebot|linkdex|Adidxbot|blekkobot|ezooms|dotbot|Mail.RU_Bot|discobot|heritrix|findthatfile|europarchive.org|NerdByNature.Bot|sistrix crawler|ahrefsbot|Aboundex|domaincrawler|wbsearchbot|summify|ccbot|edisterbot|seznambot|ec2linkfinder|gslfbot|aihitbot|intelium_bot|facebookexternalhit|yeti|RetrevoPageAnalyzer|lb-spider|sogou|lssbot|careerbot|wotbox|wocbot|ichiro|DuckDuckBot|lssrocketcrawler|drupact|webcompanycrawler|acoonbot|openindexspider|gnam gnam spider|web-archive-net.com.bot|backlinkcrawler|coccoc|integromedb|content crawler spider|toplistbot|seokicks-robot|it2media-domain-crawler|ip-web-crawler.com|siteexplorer.info|elisabot|proximic|changedetection|blexbot|arabot|WeSEE:Search|niki-bot|CrystalSemanticsBot|rogerbot|360Spider|psbot|InterfaxScanBot|Lipperhey SEO Service|CC Metadata Scaper|g00g1e.net|GrapeshotCrawler|urlappendbot|brainobot|fr-crawler|binlar|SimpleCrawler|Livelapbot|Twitterbot|cXensebot|smtbot|bnf.fr_bot|A6-Indexer|ADmantX|Facebot|Twitterbot|OrangeBot|memorybot|AdvBot|MegaIndex|SemanticScholarBot|ltx71|nerdybot|xovibot|BUbiNG|Qwantify|archive.org_bot|Applebot|TweetmemeBot|crawler4j|findxbot|SemrushBot|yoozBot|lipperhey|y!j-asr|Domain Re-Animator Bot|AddThis)';
    const regex = new RegExp(BOT_PATTERN, 'i');
    //  userAgent가 botPatterns와 패턴이 일치하면 var conditionalSampleRate를 0으로 설정
    // 그 외에는 conditionalSampleRate를 100으로 정의
    const conditionalSampleRate = !BuildEnv.IS_PRODUCTION ? 100 : regex.test(navigator.userAgent) ? 0 : 5;
    const version = packageJson.version.split('hotfix')[0];

    try {
      datadogRum.init({
        applicationId: process.env.NEXT_PUBLIC_DATADOG_APP_ID,
        clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
        site: 'datadoghq.com',
        service: 'miricanvas-web',
        version: version,
        env: process.env.NEXT_PUBLIC_ENV,
        defaultPrivacyLevel: 'allow',
        sessionSampleRate: conditionalSampleRate,
        sessionReplaySampleRate: conditionalSampleRate,
        telemetrySampleRate: conditionalSampleRate,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        silentMultipleInit: true,
        usePartitionedCrossSiteSessionCookie: true,
        trackSessionAcrossSubdomains: true,
        useSecureSessionCookie: true,
        compressIntakeRequests: true,
        // TODO 24.924에 반영
        // allowedTracingUrls: [
        //   MIRICANVAS_URL,
        //   API_URL,
        //   S3.webPrefix,
        //   getPrintoutModuleUrl(),
        //   PAYMENT_GATEWAY_API_URL,
        //   BIZHOWS_API_URL,
        //   BIZHOWS_IMAGE_SERVER,
        //   AI_CREATION_SCRIPT_URL,
        // ],
      });
      datadogLogs.init({
        clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
        site: 'datadoghq.com',
        service: 'miricanvas-web',
        version: version,
        forwardErrorsToLogs: true,
        silentMultipleInit: true,
        sessionSampleRate: conditionalSampleRate,
        telemetrySampleRate: conditionalSampleRate,
        usePartitionedCrossSiteSessionCookie: true,
        trackSessionAcrossSubdomains: true,
        useSecureSessionCookie: true,
        forwardReports: 'all',
        forwardConsoleLogs: ['warn', 'error'],
      });
      LogUtil.info.dev('[DATADOG] Datadog initialized');
    } catch (e) {
      LogUtil.error.dev('Failed to initialize Datadog', e);
    }
  });

  return {
    ddCustomAction,
  };
};
