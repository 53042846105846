import { calculateRatio, RowInfo } from './masonry_util';

export type GridListOption = {
  columnCount: number;
  rowHeight: number;
  rowWidth: number;
  columnGap: number;
  rowGap: number;
};

/**
 * 데이터를 gridList로 그리기 위한 row 배열로 변환. ListView 컴포넌트와 함께 사용
 * @param rawDataList 리스트에 포함시킬 데이터 배열
 * @param columnCount 열 개수
 * @param rowHeight 행의 높이
 * @param rowWidth 행의 너비
 * @param columnGap 열 간의 간격
 */
export const createGridList = <ContentData>({
  rawDataList,
  columnCount,
  rowHeight,
  rowWidth,
  columnGap,
}: {
  rawDataList: ContentData[];
} & GridListOption): RowInfo<ContentData>[] => {
  const rows = [];
  let renderedCount = 0;

  const calculatedCellWidth = (rowWidth - columnGap * (columnCount - 1)) / columnCount;
  const itemRatio = calculateRatio(calculatedCellWidth, rowHeight);

  while (renderedCount < rawDataList.length) {
    const rowContents = rawDataList.slice(renderedCount, renderedCount + columnCount);
    const row: RowInfo<ContentData> = {
      rowHeight,
      contents: rowContents.map((content) => ({
        item: content,
        ratio: itemRatio,
      })),
    };
    renderedCount += columnCount;
    rows.push(row);
  }

  return rows;
};
