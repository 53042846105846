import { atomFamilyWithAllowedKeys } from '@configs/jotai/utils/atomFamilyWithAllowedKeys';
import { DesignResourcePanelMenuType } from '@constants/DesignResourcePanelMenu';
import { atom } from 'jotai';

export const searchKeywordSupportedPanelList = [
  'PICTURE',
  'ELEMENTS',
  'TEXT',
  'DATA',
  'VIDEO',
  'AUDIO',
  'BACKGROUND',
  'TEMPLATE',
  'CREATOR',
  'UPLOAD',
  'THEME',
] as const satisfies DesignResourcePanelMenuType[];

export type SearchKeywordSupportedPanel = (typeof searchKeywordSupportedPanelList)[number];

export const isSearchKeywordSupportedPanel = (
  panel: string
): panel is SearchKeywordSupportedPanel =>
  searchKeywordSupportedPanelList.includes(panel as SearchKeywordSupportedPanel);

export const {
  atomFamily: panelSearchKeywordAtomFamily,
  writeOnlyByKeyAtom: setSearchKeywordToPanelAtom,
} = atomFamilyWithAllowedKeys(searchKeywordSupportedPanelList, () => atom(''), '');
