import { atom, useAtom } from 'jotai';
import { GetCurrentTemplateTypePayload } from '@miri-unicorn/miricanvas-editor';
import { getRootStore } from '@configs/jotai/RootProvider';

/**
 * 선택된 템플릿 유형
 */
const currentTemplateTypeAtom = atom<Nullable<GetCurrentTemplateTypePayload>>(null);
currentTemplateTypeAtom.debugLabel = 'currentTemplateTypeAtom';

export const useTemplateTypeStore = () => {
  const [currentTemplateType, setCurrentTemplateType] = useAtom(currentTemplateTypeAtom);

  return { currentTemplateType, setCurrentTemplateType };
};

export const getTemplateTypeSnapshotValue = () => getRootStore().get(currentTemplateTypeAtom);
