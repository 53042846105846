type ExcludeUndefinedProperties<T> = Exclude<T, undefined>;

export const removeUndefinedProperties = <T extends object>(
  obj: T
): ExcludeUndefinedProperties<T> => {
  return Object.entries(obj)
    .filter(([, v]) => v !== undefined)
    .reduce<ExcludeUndefinedProperties<T>>(
      (acc, [k, v]) => ({ ...acc, [k]: v }),
      {} as ExcludeUndefinedProperties<T>
    );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const tryGetValue = (obj: any, path: (string | number)[]) => {
  try {
    return path.reduce((acc, key) => acc?.[key], obj);
  } catch (e) {
    return undefined;
  }
};
