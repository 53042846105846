"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _NativeEventType = require("./NativeEventType");
Object.keys(_NativeEventType).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _NativeEventType[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _NativeEventType[key];
    }
  });
});
var _payloads = require("./payloads");
Object.keys(_payloads).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _payloads[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _payloads[key];
    }
  });
});
var _NativeEventRegistry = require("./NativeEventRegistry");
Object.keys(_NativeEventRegistry).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _NativeEventRegistry[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _NativeEventRegistry[key];
    }
  });
});