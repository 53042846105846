import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';

export const makeGaParamsActionClickJapanTemplateRecommendBanner: GAParameterMakerFnType<
  'ACTION_CLICK_JAPAN_TEMPLATE_RECOMMEND_BANNER'
> = ({ data: { location1, location2 } }) => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: location1,
    location2: location2,
    action1: '클릭',
    label1: `${location1}_${location2}_템플릿추천팝업`,
  };
};
