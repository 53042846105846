"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WebViewEvent = void 0;
/**
 * 웹에서 발생하는 이벤트 타입
 * */
var WebViewEvent = exports.WebViewEvent = {
  /** @description 개발 환경 세팅 버튼 클릭 */
  ON_CLICK_DEPLOY_SETTING: 'ON_CLICK_DEPLOY_SETTING',
  /** @description 인앱 결제 테스트 버튼 클릭 */
  ON_CLICK_APP_PAYMENT_TEST: 'ON_CLICK_APP_PAYMENT_TEST',
  /** @description 로그인이 필요한 새창 - 브랜드키트 워크스페이스 열기 등 */
  ON_CLICK_LINK: 'ON_CLICK_LINK',
  /**@description 소셜로그인을 클릭 */
  ON_CLICK_SOCIAL_LOGIN: 'ON_CLICK_SOCIAL_LOGIN',
  /** @description 파일 다운로드 */
  ON_COMPLETE_FILE_DOWNLOAD: 'ON_COMPLETE_FILE_DOWNLOAD',
  /** @description 파일 공유 */
  ON_COMPLETE_FILE_SHARE: 'ON_COMPLETE_FILE_SHARE',
  /**
   * @description 인앱 구독 결제
   * 정책상 인앱결제는 요금제 변경이 불가하고 결제만 가능합니다.
   */
  ON_CLICK_SUBSCRIBE: 'ON_CLICK_SUBSCRIBE',
  /** @description 구독 상품 목록 요청 */
  ON_REQUEST_SUBSCRIBE_LIST: 'ON_REQUEST_SUBSCRIBE_LIST',
  /** @description 화면 회전 잠금 설정 변경 */
  ON_CHANGE_SCREEN_ORIENTATION_LOCK: 'ON_CHANGE_SCREEN_ORIENTATION_LOCK',
  /**
   * @description native system 기능 요청
   */
  ON_REQUEST_NATIVE_SYSTEM_OPEN: 'ON_REQUEST_NATIVE_SYSTEM_OPEN',
  /** @description 구독 설정 페이지 열기 */
  ON_CLICK_SUBSCRIPTION_SETTING_OPEN: 'ON_CLICK_SUBSCRIPTION_SETTING_OPEN',
  /** @description 원본 다운로드 */
  ON_CLICK_ORIGIN_DOWNLOAD: 'ON_CLICK_ORIGIN_DOWNLOAD',
  /** @description 디자인 링크 복사 */
  ON_SHARE_DESIGN_LINK: 'ON_SHARE_DESIGN_LINK',
  /** @description 인앱결제 최초 설정 */
  ON_INIT_PAYMENT_CONFIGURE: 'ON_INIT_PAYMENT_CONFIGURE',
  /** @description 앱 권한 설정 */
  ON_REQUEST_SET_PERMISSIONS: 'ON_REQUEST_SET_PERMISSIONS',
  /** @description 디바이스 정보( 모델(iPhone14), RAM(4) ) 요청 */
  ON_REQUEST_DEVICE_INFO: 'ON_REQUEST_DEVICE_INFO',
  /** @description 복사 */
  ON_REQUEST_COPY: 'ON_REQUEST_COPY',
  /** @description 네이티브 뒤로가기 요청 */
  ON_REQUEST_NATIVE_BACK: 'ON_REQUEST_NATIVE_BACK',
  /** @description 네이티브 바텀 탭 노출이 토글되도록 요청 */
  ON_TOGGLE_NATIVE_HOME_TAB_SHOW: 'ON_TOGGLE_NATIVE_HOME_TAB_SHOW',
  ON_CHANGE_NATIVE_BOTTOM_TAB: 'ON_CHANGE_NATIVE_BOTTOM_TAB',
  ON_CHANGE_USER_SETTING: 'ON_CHANGE_USER_SETTING',
  ON_CLEAR_COOKIE: 'ON_CLEAR_COOKIE',
  ON_LOGIN: 'ON_LOGIN',
  ON_LOGOUT: 'ON_LOGOUT',
  ON_WORKSPACE_LOAD_COMPLETE: 'ON_WORKSPACE_LOAD_COMPLETE'
};