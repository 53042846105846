import { GAParam } from '@configs/google_analytics/types/GACommonTypes';
import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { GTMTriggerEventParams } from '@configs/google_analytics/types/GTMTriggerEventParams';

export const makeGAParamsClickTutorialTooltipMenuOpenButton: GAParameterMakerFnType<
  'ACTION_CLICK_TUTORIAL_TOOLTIP_MENU_OPEN_BUTTON'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: '수동',
    action1: '클릭',
    label1: `에디터_튜토리얼진입점`,
  } as TutorialTooltipOpenButtonReturnPayload;
};

type TutorialTooltipOpenButtonReturnPayload = GAParam<GTMTriggerEventParams>;
