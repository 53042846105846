type BrowserType =
  | 'IE'
  | 'Edge'
  | 'Whale'
  | 'Chrome'
  | 'Firefox'
  | 'Safari'
  | 'Etc'
  | 'MobileDefault';

export const isMobileIOS = (): boolean => {
  let isMobileIOS = false;
  try {
    const platform = navigator && navigator.platform;
    const maxTouchPoints = navigator && navigator.maxTouchPoints;
    isMobileIOS =
      (/iPad|iPhone|iPod/.test(platform) || (platform === 'MacIntel' && maxTouchPoints > 1)) &&
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- ci 에러로 lint disable 처리
      !(window as any).MSStream;
  } catch (ignore) {
    // ios 13+ pc버전보기 on 상태에 대응하기위한 코드
  }
  return isMobileIOS;
};

export const isMobileDevice = (): boolean => {
  // 서버 사이드에서 window 접근 방어
  if (typeof window === 'undefined') {
    return false;
  }

  const agent = navigator && navigator.userAgent.toLowerCase();
  // 삼성브라우저는 스마트폰/태블릿용 모바일 브라우저이기에 추가
  const isMobileDevice = /iphone|ipod|ipad|android|blackberry|fennec|samsungbrowser/.test(agent);
  return isMobileDevice;
};

/**
 * @see 1.0 BrowserChecker.getBrowserEnvironment()
 */
export const getDesktopBrowserEnvironment = (): BrowserType => {
  // 서버 사이드에서 window 접근 방어
  if (typeof window === 'undefined') {
    return 'Etc';
  }

  const agent = navigator && navigator.userAgent.toLowerCase();
  const isMobile = isMobileDevice();
  let isMobileIOS = false;
  try {
    isMobileIOS =
      (/iPad|iPhone|iPod/.test(navigator.platform) ||
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- ci 에러로 lint disable 처리
      !(window as any).MSStream;
  } catch (ignore) {
    // ios 13+ pc버전보기 on 상태에 대응하기위한 코드
  }

  if (isMobile || isMobileIOS) {
    return 'MobileDefault';
  }
  if (agent.indexOf('msie') > -1) {
    return 'IE';
  }
  if (agent.indexOf('edg') > -1) {
    return 'Edge';
  }
  if (agent.indexOf('whale') > -1) {
    return 'Whale';
  }
  if (agent.indexOf('chrome') > -1) {
    // 실제 크롬이 아닐 수도 있음
    return 'Chrome';
  }
  if (agent.indexOf('firefox') > -1) {
    return 'Firefox';
  }
  if (agent.indexOf('safari') > -1) {
    return 'Safari';
  }

  return 'Etc';
};

export const isPortrait = () => {
  if (typeof window === 'undefined') return false;
  return window.innerWidth < window.innerHeight;
};

export const getIOSsafeAreaInset = () => {
  if (typeof window === 'undefined') return { top: 0, bottom: 0, left: 0, right: 0 };

  return {
    top: parseInt(getComputedStyle(document.documentElement).getPropertyValue('--sat')),
    bottom: parseInt(getComputedStyle(document.documentElement).getPropertyValue('--sab')),
    left: parseInt(getComputedStyle(document.documentElement).getPropertyValue('--sal')),
    right: parseInt(getComputedStyle(document.documentElement).getPropertyValue('--sar')),
  };
};
