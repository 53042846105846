import { useCallback } from 'react';
import { DesignResourceType } from '@miri-unicorn/miricanvas-api-client';
import { DesignResourceDetailData } from '@business/design_resource/_types/designResourceDetailData';
import { useRestrictedTabType } from '@business/editor/editor_feature_limitations/_services/_hooks/useRestrictedTabType';

/**
 * 비슷한 리소스 찾기를 할 수 있는가?
 * - 리소스에 키워드가 1개 이상 설정되어 있어야 함
 * - 리소스가 속한 패널이 제약되어 있지 않음
 */
export const useCanFindSimilarResource = () => {
  const { getIsRestrictedType } = useRestrictedTabType();
  return useCallback(
    ({ resourceInfo }: { resourceInfo: DesignResourceDetailData | null }) => {
      if (!resourceInfo) {
        return false;
      }

      const designResourceType = resourceInfo.designResourceType;

      // 현재 제약된 탭에 속한 리소스인지 확인
      const isRestrictedType = getIsRestrictedType(designResourceType);
      // 키워드 1개 이상 있는지 확인
      const keywords = resourceInfo.keywords;
      const hasKeyword = keywords && Number(keywords.length) > 0 && keywords.join('') !== '';

      return Boolean(
        !isRestrictedType &&
          !HIDE_FIND_SIMILAR_RESOURCE_DESIGN_RESOURCE_TYPE_LIST.includes(designResourceType) &&
          hasKeyword
      );
    },
    [getIsRestrictedType]
  );
};

const HIDE_FIND_SIMILAR_RESOURCE_DESIGN_RESOURCE_TYPE_LIST: DesignResourceType[] = [
  'STYLE_THEME_COLOR',
];
