import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';

export const makeGAParamsActionClickMyTemplatePopupTab: GAParameterMakerFnType<
  'ACTION_CLICK_MY_TEMPLATE_POPUP_TAB'
> = (payload) => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '템플릿페이지',
    location2: '관심템플릿',
    location3: '탭',
    action1: '클릭',
    label1: payload.data.label1,
  };
};
