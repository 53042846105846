import { AspectRatioType } from '@business/design_resource/_types/AspectRatioType';
import {
  toParameterColor,
  toParameterRatio,
} from '@business/design_resource_bar/_services/_pures/filterToParameterMapper';
import { PictureDesignResourceType, Tier } from '@miri-unicorn/miricanvas-api-client';
import { isNull } from '@miri-unicorn/miricanvas-utils/functions';
import { SearchFilterColorList } from '@ui/common/_constants/SearchFilterColorList';
import { CustomColorId, getFilterColorById } from '@ui/common/_utils/color_filter_util';
import { isSearchingRecentPanelAtomFamily } from '@ui/design/_common/design_resource_panel/_stores/_common/isSearchingRecentInPanelAtomFamily';
import { panelSearchKeywordAtomFamily } from '@ui/design/_common/design_resource_panel/_stores/_common/panelSearchKeywordAtomFamily';
import { atom, useAtom, useAtomValue } from 'jotai';
import { useMemo } from 'react';

const keywordAtom = panelSearchKeywordAtomFamily('PICTURE');
const typeListAtom = atom<PictureDesignResourceType[]>(['PICTURE']);
const isSearchingRecentAtom = isSearchingRecentPanelAtomFamily('PICTURE');
const aspectRatioAtom = atom<AspectRatioType | null>(null);
const tierListAtom = atom<Tier[]>([]);
const filteredColorIdxAtom = atom<number | CustomColorId | null>(null);
const filteredCustomColorAtom = atom<string | null>(null);

export const pictureSearchAtom = atom((get) => ({
  keyword: get(keywordAtom),
  typeList: get(typeListAtom),
  color:
    getFilterColorById(
      SearchFilterColorList,
      get(filteredCustomColorAtom),
      get(filteredColorIdxAtom)
    )?.replace(/^#/g, '') ?? '',
  aspectRatio: get(aspectRatioAtom) ?? undefined,
  tierList: get(tierListAtom),
}));

export const usePicturePanelSearchParam = () => {
  const pictureSearch = useAtomValue(pictureSearchAtom);
  // TODO: pictureSearchAtom 안에서 처리할 것
  return useMemo(
    () => ({
      ...pictureSearch,
      color: toParameterColor(pictureSearch.color ?? ''),
      aspectRatio: toParameterRatio(pictureSearch.aspectRatio ?? ''),
    }),
    [pictureSearch]
  );
};

export const usePicturePanelSearchStore = () => {
  const [searchKeyword, setSearchKeyword] = useAtom(keywordAtom);
  const [isSearchingRecent, setIsSearchingRecent] = useAtom(isSearchingRecentAtom);
  const [aspectRatio, setAspectRatio] = useAtom(aspectRatioAtom);
  const [tierList, setTierList] = useAtom(tierListAtom);
  const [filteredColorIdx, setFilteredColorIdx] = useAtom(filteredColorIdxAtom);
  const [filteredCustomColor, setFilteredCustomColor] = useAtom(filteredCustomColorAtom);

  const pictureSearch = useAtomValue(pictureSearchAtom);

  const isSearching = useMemo(() => {
    return (
      Boolean(searchKeyword) ||
      isSearchingRecent ||
      Boolean(aspectRatio) ||
      Boolean(tierList.length) ||
      !isNull(filteredColorIdx) ||
      Boolean(filteredCustomColor)
    );
  }, [
    aspectRatio,
    filteredColorIdx,
    filteredCustomColor,
    isSearchingRecent,
    searchKeyword,
    tierList.length,
  ]);
  const isAppliedFilter = useMemo(() => {
    return (
      Boolean(aspectRatio) ||
      Boolean(tierList.length) ||
      !isNull(filteredColorIdx) ||
      Boolean(filteredCustomColor)
    );
  }, [aspectRatio, filteredColorIdx, filteredCustomColor, tierList.length]);

  return {
    pictureSearch: {
      ...pictureSearch,
      color: toParameterColor(pictureSearch.color ?? ''),
      aspectRatio: toParameterRatio(pictureSearch.aspectRatio ?? ''),
    },
    searchKeyword,
    setSearchKeyword,
    isSearchingRecent,
    setIsSearchingRecent,
    isSearching,
    setAspectRatio,
    setTierList,
    aspectRatio,
    tierList,
    isAppliedFilter,
    filteredColorIdx,
    setFilteredColorIdx,
    filteredCustomColor,
    setFilteredCustomColor,
  };
};
