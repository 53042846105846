import { useEffect } from 'react';
import { EventPayload } from '../_types/EventPayload';
import { EventType } from '../_types/EventType';
import { eventSubject } from '../eventSubject';

export const useEventListener = <EVENT_TYPE extends EventType>(
  eventType: EVENT_TYPE,
  handler: (eventPayload: EventPayload<EVENT_TYPE>) => void
) => {
  useEffect(() => {
    const subscription = eventSubject.subscribe((subjectValue) => {
      if (subjectValue.type !== eventType) {
        return;
      }
      handler(subjectValue.payload as EventPayload<EVENT_TYPE>);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [eventType, handler]);
};
