import {
  RecentlyViewedTemplateObj,
  RecentlyViewedTemplateParams,
} from '@domain/template/recently_viewed/_business/_types/RecentlyViewedTemplateTypes';
import { insertRecentlyViewedTemplate } from '@domain/template/recently_viewed/_business/_utils/recently_viewed_utils';
import { usePostRecentlyViewedTemplateMutation } from '@domain/template/recently_viewed/_business/_queries/recentlyViewedTemplateQuery';
import { useLocalStorage } from '@utils/hooks/useLocalStorage';
import { useGetCurrentUserSnapshotValue } from '@business/user/_services/_hooks/useGetCurrentUserSnapshotValue';
import { RECENTLY_VIEWED_TEMPLATE_LIST_MAX_COUNT } from '@domain/template/recently_viewed/_business/_constants/RecentlyViewedTemplateConstants';

/**
 * 로그인 한 유저의 최근 본 템플릿을 DB에 저장하는 함수들을 반환
 */
export const useAuthenticatedUserRecentlyViewedTemplate = () => {
  const { postRecentlyViewedTemplateMutation } = usePostRecentlyViewedTemplateMutation();
  const [localStorageValue, setLocalStorageValue] = useLocalStorage<RecentlyViewedTemplateObj[]>(
    'RECENTLY_VIEWED_TEMPLATE',
    []
  );
  const { getCurrentUserSnapshotValue } = useGetCurrentUserSnapshotValue();

  /**
   * 비로그인 시 저장해놨던 "최근 본" 템플릿 정보들을 로그인 후 로그인 계정에 저장시키는 요청을 실행한다.
   * BE api 요청이 성공한 후에는 저장해놨던 로컬 스토리지를 비워준다.
   */
  const updateAfterLogin = () => {
    const { isLogin } = getCurrentUserSnapshotValue();
    if (!isLogin) return;

    const filteredNewList = localStorageValue ?? [];

    postRecentlyViewedTemplateMutation({
      data: filteredNewList,
      onSuccess: () => {
        setLocalStorageValue([]);
      },
      maxSliceCount: RECENTLY_VIEWED_TEMPLATE_LIST_MAX_COUNT,
    });
  };

  /**
   * 로그인 한 유저가 새로운 템플릿을 확인할 경우, 해당 템플릿을 DB에 저장
   */
  const update = ({ target }: { target: RecentlyViewedTemplateParams }) => {
    const { isLogin } = getCurrentUserSnapshotValue();
    if (!isLogin) return;
    const filteredNewList = insertRecentlyViewedTemplate({
      origin: [],
      target,
    });

    postRecentlyViewedTemplateMutation({
      data: filteredNewList,
      maxSliceCount: RECENTLY_VIEWED_TEMPLATE_LIST_MAX_COUNT,
    });
  };

  return { update, updateAfterLogin };
};
