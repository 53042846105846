import { capitalize } from './lodash_util';

export const filterXss = (targetString: string): string => {
  return targetString?.replace(/</g, '&lt;').replace(/>/g, '&gt;');
};

/***
 * 문자열이 공백만 존재하는 지 여부
 */
export const hasOnlySpaces = (str: string): boolean => {
  return str.trim().length === 0;
};

/**
 * 문자열에서 모든 공백을 제거하는 함수
 **/
export const removeAllWhiteSpaces = (str: string) => str.replaceAll(' ', '');

/**
 * 문자열에서 모든 공백을 제거하고 소문자로 변환하는 함수
 **/
export const removeSpacesAndToLower = (str: string) =>
  removeAllWhiteSpaces(str).toLocaleLowerCase();

/**
 * 받침 여부에 따라 목적어 조사를 반환하는 함수
 * @param hasFinalConsonant
 * @returns 받침이 있으면 을, 없으면 를
 */
export const getKorObjectPostposition = (hasFinalConsonant: boolean): string => {
  return hasFinalConsonant ? '을' : '를';
};

/**
 * @see 1.0 StringUtil.ts 내 padStart()
 */
export const padStart = (target: string, length: number, padString = ' '): string => {
  length = length >> 0;
  if (target.length > length) {
    return String(target);
  } else {
    length = length - target.length;
    if (length > padString.length) {
      padString += padString.repeat(length / padString.length);
    }
    return padString.slice(0, length) + String(target);
  }
};

export const escapeBrTag = (str: string): string => {
  return str.replace(/<br>|<br\/>|<\/br>/g, '\n');
};

/**
 * 숫자로만 이루어진 문자열에서 숫자를 앞에서 부터 X자리씩 끊고 그 사이에 공백을 넣어줌.
 * @param numberText 숫자로만 이루어진 문자열
 * @returns 앞에서 부터 X 자리씩 끊은 문자열
 * @example
 * const text = '1000000';
 * splitEveryXNumbers(text, 4); // '1000 000'
 */
export const splitByCharacterEveryXNumbers = (
  numberText: string,
  X: number,
  character = ' '
): string => {
  if (!numberText) return '';
  return (numberText.match(new RegExp(`.{0,${X}}`, 'g')) as RegExpMatchArray)
    .join(character)
    .slice(0, -1);
};

export const removeNonDigit = (textWithDigitAndNonDigit: string): string => {
  return textWithDigitAndNonDigit?.replace(/\D+/g, '');
};

export const startCase = (str: string): string => {
  const temp = str.toLowerCase();
  return temp.charAt(0).toUpperCase() + temp.slice(1);
};

export const getEllipsisText = (email: string, maxLength = 45) => {
  return email.length > maxLength ? email.slice(0, maxLength) + '...' : email;
};

export const b64EncodeUnicode = (str: string) => {
  return btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (_, p1) => {
      return String.fromCharCode(Number('0x' + p1));
    })
  );
};
/** base64 형식의 string decode */
export const b64DecodeUnicode = (str: string) => {
  return decodeURIComponent(
    atob(str)
      .split('')
      .map((c) => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );
};

/**
 * 문자열에 공백이 포함되었을 때 큰따옴표로 둘러싸는 함수
 */
export const surroundWithQuotesWhenIncludesSpace = (value: string) => {
  return value.includes(' ') ? `"${value}"` : value;
};

/**
 * 둘러싸는 큰따옴표 제거
 * @example
 * - '"value"' -> 'value'
 * - '"va"l"ue"' -> 'va"l"ue'
 * - '"value' -> '"value'
 */
export const removeSurroundingQuotes = (value: string) => {
  return value.replace(/^"(.*)"$/, '$1');
};

/**
 * `CONSTANT_CASE`를 `ConstantCase` 로 변환
 * @param constantCase
 */
export const constantCaseToPascalCase = <const T extends string>(
  constantCase: T
): ConstantCaseToPascalCase<T> => {
  return constantCase
    .toLowerCase()
    .split('_')
    .map(capitalize)
    .join('') as ConstantCaseToPascalCase<T>;
};

/**
 * web 1.0의 StringUtil.random()
 * @param length
 */
export const getRandomString = (length = 10) => {
  let randomStr = '';
  while (randomStr.length < length) {
    const newStr = Math.random().toString(36).slice(2);
    randomStr += newStr.slice(0, Math.min(newStr.length, length - randomStr.length));
  }
  return randomStr.toUpperCase();
};

export const isNonEmptyString = (value: unknown): value is string => {
  return !!value && typeof value === 'string';
};

/** 해당 정규식에 해당하는 부분 코드에서 제거 */
export const removePatternFromText = (text: string, pattern: RegExp): string => {
  return text.replace(pattern, '');
};

/** 이모지 제거 */
export const removeEmoji = (text: string): string => {
  const emojiPattern =
    /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g;
  return removePatternFromText(text, emojiPattern);
};

/** string 을 Uint8Array 로 변환 */
export const encodeString = (str: string): Uint8Array => {
  const encoder = new TextEncoder();

  return encoder.encode(str);
};

/** Uint8Array 를 string 으로 변환 */
export const decodeString = (unit8Array: Uint8Array): string => {
  const decoder = new TextDecoder('utf-8');

  return decoder.decode(unit8Array);
};

export const replaceSpaceWithHyphen = (text: string) => {
  return text?.trim().replace(/ /gi, '-');
};
