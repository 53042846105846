import {
  AUDIO_M4A,
  AUDIO_MP3,
  IMAGE_GIF,
  IMAGE_JPEG,
  IMAGE_JPG,
  IMAGE_PNG,
  IMAGE_SVG_XML,
  VIDEO_MP4,
} from './FileMimeTypes';

// TODO UNICORN-38319 FileMimeTypes 타입 정의를 수정하면서 key가 string이 아니라 리터럴 타입을 가지도록 수정
export const mimeToExtensionMap: { [key: string]: string } = {
  [IMAGE_JPEG]: 'jpg',
  [IMAGE_JPG]: 'jpg',
  [IMAGE_PNG]: 'png',
  [IMAGE_GIF]: 'gif',
  [AUDIO_MP3]: 'mp3',
  [AUDIO_M4A]: 'm4a',
  [VIDEO_MP4]: 'mp4',
  [IMAGE_SVG_XML]: 'svg',
};
