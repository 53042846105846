import { NamespaceEnum, NamespaceType } from '@configs/i18next/_types/NameSpace';
import { filterXss, LogUtil } from '@miri-unicorn/miricanvas-utils/functions';
import { InterpolationOptions } from 'i18next';
import { useTranslation as _useTranslation } from 'next-i18next';
import { useCallback } from 'react';

export type TranslationFunction = ReturnType<typeof useTranslation>['translate'];

const interpolation = {
  prefix: '%{',
  suffix: '}',
  escape: filterXss,
} as const satisfies InterpolationOptions;

/**
 * 각각의 namespace에 맞는 키값만 번역이 적용되기 때문에 일부 하드 코딩 과정을 거쳤음
 * 추후에 namespace를 적절하게 적용 할 수 있는 방식을 국제화 라우팅 작업을 진행할때 translate 함수를 구체화 할 필요가 있음
 */

/**
 * ## useTranslate hook
 * #### bindHook 내부에서 기본으로 반환해주는 번역 관련 함수입니다.
 * #### bindHook 을 사용하지 않을시 다음과 같이 사용할 수 있습니다.
 * @example ```tsx
 *
 *  const { translate } = useTranslate();
 *  <div>{translate('SHARED.TRANSLATE_KEY')}</div>
 * ```
 */
export const useTranslation = () => {
  const { t: sharedTranslate } = _useTranslation<NamespaceType>(NamespaceEnum.SHARED);
  const { t: editorTranslate } = _useTranslation<NamespaceType>(NamespaceEnum.EDITOR);
  const { t: workspaceTranslate } = _useTranslation<NamespaceType>(NamespaceEnum.WORKSPACE);
  const { t: homepageTranslate } = _useTranslation<NamespaceType>(NamespaceEnum.HOMEPAGE);

  // TODO workspace,homepage

  // default translateFunction
  const { t } = _useTranslation();

  const translate = useCallback(
    (key: string, value?: Record<string, Nullable<string | number>>): string => {
      /** @todo 임시 로직 */
      if (key?.startsWith(NamespaceEnum.SHARED.toUpperCase())) {
        return sharedTranslate(key, {
          ...value,
          interpolation,
        });
      }

      if (key?.startsWith(NamespaceEnum.EDITOR.toUpperCase())) {
        return editorTranslate(key, {
          ...value,
          interpolation,
        });
      }

      if (key?.startsWith(NamespaceEnum.WORKSPACE.toUpperCase())) {
        LogUtil.log.dev('enter workspace', key);
        return workspaceTranslate(key, {
          ...value,
          interpolation,
        });
      }

      if (key?.startsWith(NamespaceEnum.HOMEPAGE.toUpperCase())) {
        return homepageTranslate(key, {
          ...value,
          interpolation,
        });
      }
      // default = shared
      return t(key, value ?? {});
    },
    [editorTranslate, sharedTranslate, workspaceTranslate, homepageTranslate, t]
  );

  return { translate };
};
