import {
  ElementDesignResourceType,
  KeywordCategoryEntityType,
} from '@miri-unicorn/miricanvas-api-client';
import { DesignResourceTabType } from '@business/design_resource_bar/tab/_constants/designResourceTabType';
import { LauncherParamEditorFeature } from '@business/editor/launcher_param/_types/editorFeatureSchema';

/**
 * @deprecated 에디터 제약이 적용되지 않았습니다 {@link useElementTabTypes}를 사용해주세요
 */
export const ELEMENT_TAB_TYPE: ElementTabType[] = [
  {
    tabKey: 'ELEMENTALL',
    tabName: 'EDITOR.LEFT_MENU.EDITOR_FEATURE.TAB_ELEMENTALL',
    designResourceTypes: [
      'ILLUST',
      'BITMAP',
      'FIGURE',
      'LINE',
      'ANI',
      'ELEMENT_COLLECTION',
      'DESIGNRESOURCE_COLLECTION',
      'FRAME',
      'PRESET_FRAME',
      'MOCKUP_GRID',
      'MOCKUP_TEXT',
      'CHART',
      'EXTERNAL_ILLUST',
      'EXTERNAL_BITMAP',
      'EXTERNAL_ANI',
    ],
    keywordCategoryType: 'ELEMENT',
    keywordCategoryResourceTypes: [],
    editorFeatureKey: 'TAB_ELEMENTALL',
  },
  {
    tabKey: 'ILLUSTRATION',
    tabName: 'EDITOR.LEFT_MENU.EDITOR_FEATURE.TAB_ILLUSTRATION',
    designResourceTypes: ['ILLUST', 'BITMAP', 'EXTERNAL_ILLUST', 'EXTERNAL_BITMAP'],
    keywordCategoryType: 'ILLUST_GROUP',
    keywordCategoryResourceTypes: ['ILLUST_GROUP'],
    editorFeatureKey: 'TAB_ILLUSTRATION',
    supportElementTypeFilter: true,
  },
  {
    tabKey: 'FIGURE',
    tabName: 'EDITOR.LEFT_MENU.EDITOR_FEATURE.TAB_FIGURE',
    designResourceTypes: ['FIGURE', 'PRESET_FIGURE'],
    keywordCategoryType: 'FIGURE',
    keywordCategoryResourceTypes: ['FIGURE'],
    editorFeatureKey: 'TAB_FIGURE',
  },
  {
    tabKey: 'LINE',
    tabName: 'EDITOR.LEFT_MENU.EDITOR_FEATURE.TAB_LINE',
    designResourceTypes: ['LINE'],
    keywordCategoryType: 'LINE',
    keywordCategoryResourceTypes: ['LINE'],
    editorFeatureKey: 'TAB_LINE',
  },
  {
    tabKey: 'ELEMENT_KEYWORD_ICON',
    tabName: 'EDITOR.LEFT_MENU.EDITOR_FEATURE.TAB_ELEMENT_KEYWORD_ICON',
    designResourceTypes: ['ILLUST', 'BITMAP', 'EXTERNAL_ILLUST', 'EXTERNAL_BITMAP'],
    defaultKeyword: 'EDITOR.LEFT_MENU.EDITOR_FEATURE.TAB_ELEMENT_KEYWORD_ICON',
    keywordCategoryResourceTypes: [],
    defaultKeywordUsage: 'ALL',
    editorFeatureKey: 'TAB_ELEMENT_KEYWORD_ICON',
  },
  {
    tabKey: 'ANI',
    tabName: 'EDITOR.LEFT_MENU.EDITOR_FEATURE.TAB_ANI',
    designResourceTypes: ['ANI', 'EXTERNAL_ANI'],
    keywordCategoryType: 'ANI',
    keywordCategoryResourceTypes: ['ANI'],
    editorFeatureKey: 'TAB_ANI',
  },
  {
    tabKey: 'COLLECTION',
    tabName: 'EDITOR.LEFT_MENU.EDITOR_FEATURE.TAB_COLLECTION',
    designResourceTypes: ['ELEMENT_COLLECTION'],
    keywordCategoryType: 'DESIGNRESOURCE_COLLECTION',
    keywordCategoryResourceTypes: ['ELEMENT_COLLECTION'],
    editorFeatureKey: 'TAB_ELEMENT_COLLECTION',
  },
  {
    tabKey: 'FRAME',
    tabName: 'EDITOR.LEFT_MENU.EDITOR_FEATURE.TAB_FRAME',
    designResourceTypes: ['FRAME', 'PRESET_FRAME'],
    keywordCategoryType: 'FRAME',
    keywordCategoryResourceTypes: ['FRAME'],
    editorFeatureKey: 'TAB_FRAME',
  },
  {
    tabKey: 'GRID',
    tabName: 'EDITOR.LEFT_MENU.EDITOR_FEATURE.TAB_GRID',
    designResourceTypes: ['MOCKUP_GRID'],
    keywordCategoryType: 'MOCKUP_GRID',
    keywordCategoryResourceTypes: ['MOCKUP_GRID'],
    editorFeatureKey: 'TAB_GRID',
  },
  {
    tabKey: 'CHART',
    tabName: 'EDITOR.LEFT_MENU.EDITOR_FEATURE.TAB_CHART',
    designResourceTypes: ['CHART'],
    keywordCategoryType: 'CHART',
    keywordCategoryResourceTypes: ['CHART'],
    defaultKeyword: '기본 차트',
    defaultKeywordUsage: 'LIST',
    editorFeatureKey: 'TAB_CHART',
  },
  {
    tabKey: 'COMBINATION',
    tabName: 'EDITOR.LEFT_MENU.EDITOR_FEATURE.TAB_COMBINATION',
    designResourceTypes: ['MOCKUP_TEXT'],
    keywordCategoryType: 'MOCKUP_TEXT',
    keywordCategoryResourceTypes: ['MOCKUP_TEXT'],
    editorFeatureKey: 'TAB_COMBINATION',
  },
];

export type DefaultKeywordUsage = 'LIST' | 'ALL';
export type ElementTabType = DesignResourceTabType<ElementTabKey> & {
  keywordCategoryType?: Exclude<
    KeywordCategoryEntityType,
    'TEMPLATE' | 'WEB' | 'PRINT' | 'VIDEO_TEMPLATE' | 'ELEMENT_COLLECTION'
  >;
  designResourceTypes: ElementDesignResourceType[];
  keywordCategoryResourceTypes: ElementDesignResourceType[];
  /**
   * 사용자가 입력한 검색어 외에 기본적으로 추가할 키워드
   * ex. 요소 > 아이콘 탭: '감자' 검색 시, 실제 api 요청은 '아이콘 감자' 키워드로 요청
   * ! 내부 검색 시에만 defaultKeyword 사용하게 되어 있음. useDesignResourceSearchParam 참고
   */
  defaultKeyword?: string;
  /**
   * defaultKeyword가 사용되는 범위
   * - LIST: 리스트에서만 사용
   * - ALL: 모든 곳에서 사용 (캐러셀, 검색, 리스트 등)
   */
  defaultKeywordUsage?: DefaultKeywordUsage;
  editorFeatureKey: LauncherParamEditorFeature;
  /**
   * 검색 필터 > '유형' 필터를 제공하는지 여부
   * ! 실제로 이 값으로 필터 표시 여부를 결정하진 않고, 필터 선택 여부 판단을 위해 사용함
   * TODO: 이 값 여부로 유형 필터 보여줄지 결정
   */
  supportElementTypeFilter?: boolean;
};

/**
 * 특정 디자인 리소스와 매칭되는 것이 아닌 탭
 * ex. 전체 탭, 아이콘 탭
 */
export const NOT_SPECIFIC_RESOURCE_ELEMENT_TAB_KEYS: ElementTabKey[] = [
  'ELEMENTALL',
  'ELEMENT_KEYWORD_ICON',
];

export type ElementTabKey =
  | 'ELEMENTALL'
  | 'ILLUSTRATION'
  | 'FIGURE'
  | 'LINE'
  | 'ELEMENT_KEYWORD_ICON'
  | 'ANI'
  | 'COLLECTION'
  | 'FRAME'
  | 'GRID'
  | 'CHART'
  | 'COMBINATION';
