import { atom, useAtom, useSetAtom } from 'jotai';
import {
  TEXT_TAB_TYPE,
  TextTabKey,
} from '@business/design_resource_bar/tab/_constants/textTabType';
import { getRootStore } from '@configs/jotai/RootProvider';

const selectedTabAtom = atom<TextTabKey>('STYLE');

export const useTextPanelTabStore = () => {
  const [selectedTab, setSelectedTab] = useAtom(selectedTabAtom);

  const currentTabType =
    TEXT_TAB_TYPE.find((tab) => tab.tabKey === selectedTab) ?? TEXT_TAB_TYPE[0];

  return {
    selectedTab,
    setSelectedTab,
    currentTabType,
  };
};

export const useSetTextPanelTab = () => useSetAtom(selectedTabAtom);

export const getTextTabKeySnapshotValue = () => getRootStore().get(selectedTabAtom);
