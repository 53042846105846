import { BuildEnv } from '@configs/environments/BuildEnv';

const DEVELOP_RESOURCE_URL = 'https://resource-develop.miricanvas.com/';
const STAGING_RESOURCE_URL = 'https://resource-staging.miricanvas.com/';
const PRODUCTION_RESOURCE_URL = 'https://resource.miricanvas.com/';

export const MIRICANVAS_RESOURCE_DOMAIN = BuildEnv.IS_DEVELOP
  ? DEVELOP_RESOURCE_URL
  : BuildEnv.IS_STAGING
  ? STAGING_RESOURCE_URL
  : PRODUCTION_RESOURCE_URL;
