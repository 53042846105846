// 해당 비트에 해당하는 플래그의 값이 true인지 확인
export const isFlagOn = (flag: number, bit: number) => (flag & bit) === bit;

// 해당 비트에 해당하는 플래그의 값을 설정
export const setFlag = (flag: number, bit: number) => flag | bit;

export const clearFlag = (flag: number, bit: number) => flag & ~bit;

/**
 * Priority Bit를 리턴하는 함수
 * 켜진 비트가 없을 경우 0을 리턴
 */
export const findPriorityBit = (flag: number) => {
  if (flag === 0) return 0;
  let bit = 0;
  while ((flag >>= 1)) bit++;

  return 1 << bit;
};
