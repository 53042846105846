import { useCallback } from 'react';
import { DesignResourceType } from '@miri-unicorn/miricanvas-api-client';
import { DesignResourceDetailData } from '@business/design_resource/_types/designResourceDetailData';
import { convertDesignResourceTypeToPanelMenuType } from '@business/design_resource_bar/_services/_pures/designResourceMenuUtil';
import { useCanFindSimilarResource } from '@ui/design/_common/design_resource/_hooks/useCanFindSimilarResource';
import { isRecommendResourceRowSupportedPanelType } from '@ui/design/_common/design_resource_panel/_stores/_common/useRecommendResourceRowTargetStore';

/**
 * 비슷한 리소스 자동추천이 지원되지 않는 리소스 타입
 * - 해당 타입이 속한 패널이 자동추천을 지원하게 되더라도, 아래 배열에 속한 타입은 자동추천을 지원하지 않음
 */
const RECOMMEND_SIMILAR_RESOURCE_UNSUPPORTED_RESOURCE_TYPES = [
  'DESIGNRESOURCE_COLLECTION',
  'ELEMENT_COLLECTION',
] as const satisfies DesignResourceType[];

export const useIsRecommendationSupportedResource = () => {
  const canFindSimilarResource = useCanFindSimilarResource();

  return useCallback(
    ({ resourceInfo }: { resourceInfo: DesignResourceDetailData | null }) => {
      if (!resourceInfo) return false;

      const panelType = convertDesignResourceTypeToPanelMenuType(resourceInfo?.designResourceType);

      return Boolean(
        canFindSimilarResource({ resourceInfo }) &&
          isRecommendResourceRowSupportedPanelType(panelType) &&
          !RECOMMEND_SIMILAR_RESOURCE_UNSUPPORTED_RESOURCE_TYPES.includes(
            resourceInfo.designResourceType
          )
      );
    },
    [canFindSimilarResource]
  );
};
