const ratioMap: Record<string, string> = {
  square: '1',
  rectangle_horizontal: '1.75',
  rectangle_vertical: '0.725-',
  rectangle_horizontal_long: '2.5+',
};

export const toParameterRatio = (ratio: string) => {
  return ratio ? ratioMap[ratio] : undefined;
};

export const toParameterColor = (color: string): string | undefined => {
  return color !== '' ? color.replace('#', '').toLowerCase() : undefined;
};
