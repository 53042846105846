import { match } from 'ts-pattern';
import { DesignResourceType } from '@miri-unicorn/miricanvas-api-client';
import { designResourcePanelBarMenuEnum } from '@constants/DesignResourcePanelMenu';

export const convertDesignResourceTypeToPanelMenuType = (
  designResourceType: DesignResourceType
) => {
  return match<DesignResourceType>(designResourceType)
    .with('PICTURE', 'EXTERNAL_PICTURE', () => designResourcePanelBarMenuEnum.PICTURE)
    .with('VIDEO', 'EXTERNAL_VIDEO', () => designResourcePanelBarMenuEnum.VIDEO)
    .with(
      'BACKGROUND_PATTERN',
      'BACKGROUND_PICTURE',
      () => designResourcePanelBarMenuEnum.BACKGROUND
    )
    .with(
      'INSTRUMENTAL',
      'VOCAL',
      'SFX',
      'RECORD',
      'EXTERNAL_INSTRUMENTAL',
      'EXTERNAL_VOCAL',
      'EXTERNAL_SFX',
      'EXTERNAL_RECORD',
      'AUDIO',
      'EXTERNAL_AUDIO',
      () => designResourcePanelBarMenuEnum.AUDIO
    )
    .with('TEMPLATE', () => designResourcePanelBarMenuEnum.TEMPLATE)
    .with('TEXT_STYLE', 'COMBINATION', () => designResourcePanelBarMenuEnum.TEXT)
    .with('STYLE_THEME_COLOR', () => designResourcePanelBarMenuEnum.THEME)
    .otherwise(() => designResourcePanelBarMenuEnum.ELEMENTS);
};
