import {
  getViewedPage,
  viewedPageAtom,
} from '@business/canvas_props/page_property/_stores/usePagePropsStore';
import { getTemplateTypeSnapshotValue } from '@business/design_resource/template/template_type/_stores/useTemplateTypeStore';
import { getRootStore } from '@configs/jotai/RootProvider';
import { latestHistoryIdxAtom } from '@business/editor/_stores/useLatestHistoryIdxStore';
import { pictureSearchAtom } from '@ui/design/_common/design_resource_panel/_stores/picture/usePicturePanelSearchStore';
import { elementSearchAtom } from '@ui/design/_common/design_resource_panel/_stores/element/useElementPanelSearchStore';
import { getTextTabKeySnapshotValue } from '@ui/design/_common/design_resource_panel/_stores/text/useTextPanelTabStore';
import { getBackgroundTabSnapshotValue } from '@ui/design/_common/design_resource_panel/_stores/background/useBackgroundPanelSearchStore';
import { DesignResourceEventPanelType } from '@configs/google_analytics/features/design_resource/events/payloads/designResourceActionPayload';
import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import {
  AudioGAEventPayload,
  BackgroundGAEventPayload,
  DesignResourceActionEventPayload,
  DesignResourceGAEventCommonPayload,
  DesignResourceGAEventPayloadByPanel,
  DesignResourceIntegratedGAEventPayload,
  DesignResourceSearchEventPayload,
  DesignResourceViewEventPayload,
  ElementGAEventPayload,
  PictureGAEventPayload,
  TextGAEventPayload,
  VideoGAEventPayload,
} from '@configs/google_analytics/features/design_resource/_types/designResourceGAPayloadType';
import { selectedElementTabAtom } from '@ui/design/_common/design_resource_panel/_stores/element/useElementPanelTabStore';
import { selectedAudioTabAtom } from '@ui/design/_common/design_resource_panel/_stores/audio/useAudioPanelTabStore';
import { selectedElementTypeFilterAtom } from '@ui/design/_common/design_resource_panel/_stores/element/elementSearchFilterAtoms';
import {
  designResourceSearchTraceAtomFamily,
  SearchTraceKey,
} from '@configs/google_analytics/features/design_resource/_stores/designResourceSearchTraceAtomFamily';
import { getSearchStatus } from '@configs/google_analytics/features/design_resource/_makers/getSearchStatus';
import { getSearchTerm } from '@configs/google_analytics/features/design_resource/_makers/getSearchTerm';
import { getBeginningResource } from '@configs/google_analytics/features/design_resource/_makers/getBeginningResource';
import { SearchType } from '@configs/google_analytics/features/design_resource/_types/designResourceSearchType';

const makeDesignResourcePanelCommonPayload = (): DesignResourceGAEventCommonPayload => {
  const viewPageIdx = getRootStore().get(viewedPageAtom)?.idx;

  return {
    design_history_idx: getRootStore().get(latestHistoryIdxAtom),
    design_page_idx: viewPageIdx,
    source_template: getViewedPage()?.designPageReference?.templateKey,
    source_template_category: getTemplateTypeSnapshotValue()?.id,
    editor_version: '2.0',
  };
};

const PayloadByPanelMap: Record<DesignResourceEventPanelType, () => object> = {
  PICTURE: (): PictureGAEventPayload => {
    const pictureStore = getRootStore().get(pictureSearchAtom);
    return {
      search_photo_type: 'PICTURE',
      search_color: pictureStore.color,
      search_shape: pictureStore.aspectRatio,
    };
  },
  ELEMENTS: (): ElementGAEventPayload => {
    const elementStore = getRootStore().get(elementSearchAtom);

    return {
      search_element_type: getRootStore().get(selectedElementTabAtom),
      search_file_type: getRootStore().get(selectedElementTypeFilterAtom) ?? undefined,
      search_color: elementStore.color,
    };
  },
  TEXT: (): TextGAEventPayload => {
    return {
      search_text_type: getTextTabKeySnapshotValue(),
    };
  },
  BACKGROUND: (): BackgroundGAEventPayload => {
    return {
      search_background_type: getBackgroundTabSnapshotValue(),
    };
  },
  AUDIO: (): AudioGAEventPayload => {
    return {
      search_audio_category: getRootStore().get(selectedAudioTabAtom),
    };
  },
  VIDEO: () => ({}),
};

const makePayloadByPanel = (
  panelType: DesignResourceEventPanelType,
  entry: SearchTraceKey
): DesignResourceGAEventPayloadByPanel => {
  const trace = getRootStore().get(
    designResourceSearchTraceAtomFamily({ panel: panelType, key: entry })
  );
  const searchTerm = getSearchTerm({ panelType });
  const beginningResource = getBeginningResource(panelType, entry);

  return {
    search_status: getSearchStatus({ searchTerm, searchType: trace.searchType }),
    search_term: searchTerm,
    search_type: trace.searchType,
    beginning_element: beginningResource?.key,
    beginning_element_type: beginningResource?.resourceType,
    source_tab: trace.sourceTab,
    ...(PayloadByPanelMap[panelType]?.() ?? {}),
  };
};

const makeGACommonPayload = (panelType: DesignResourceEventPanelType, entry: SearchTraceKey) => {
  return {
    ...makeDesignResourcePanelCommonPayload(),
    ...makePayloadByPanel(panelType, entry),
  };
};

const PanelTypeToEventNameMap: Record<DesignResourceEventPanelType, string> = {
  PICTURE: 'image',
  ELEMENTS: 'element',
  TEXT: 'text',
  BACKGROUND: 'background',
  AUDIO: 'audio',
  VIDEO: 'video',
};

const getEventName = (
  eventType: 'view' | 'action' | 'search',
  panelType: DesignResourceEventPanelType
) => {
  return `${eventType}_${PanelTypeToEventNameMap[panelType]}`;
};

export const makeViewDesignResourceEventPayload: GAParameterMakerFnType<
  'ACTION_VIEW_DESIGN_RESOURCE'
> = ({ data: { panelType, traceKey = 'DEFAULT', viewedResourceList, viewLoadCount } }) => {
  const eventName = getEventName('view', panelType);

  // 청크로 여기서 알아서 하면 좋겠지만 view count를 컨텍스트에서 관리해야 하니 이벤트 보내는 쪽에서 청크 쪼개서 보내야 할 듯..
  const payload: DesignResourceViewEventPayload = {
    ...makeGACommonPayload(panelType, traceKey),
    log_type: eventName,
    event: eventName,
    view_item_key: viewedResourceList.map((resource) => resource.resourceIdx).join(','),
    view_item_type: viewedResourceList.map((resource) => resource.resourceType).join(','),
    view_load_num: viewLoadCount,
  };

  return refinePayloadProperties(payload);
};

export const handleSearchDesignResourceEventPayload: GAParameterMakerFnType<
  'ACTION_SEARCH_DESIGN_RESOURCE'
> = ({
  data: { panelType, traceKey = 'DEFAULT', searchCase, sourceTab },
}): DesignResourceSearchEventPayload => {
  const searchTraceAtom = designResourceSearchTraceAtomFamily({ panel: panelType, key: traceKey });
  getRootStore().set(searchTraceAtom, {
    searchType: searchCase ?? getRootStore().get(searchTraceAtom).searchType,
    sourceTab,
  });

  const eventName = getEventName('search', panelType);
  return refinePayloadProperties({
    ...makeGACommonPayload(panelType, traceKey),
    log_type: eventName,
    event: eventName,
  });
};

export const makeAddDesignResourceEventPayload: GAParameterMakerFnType<
  'ACTION_ADD_DESIGN_RESOURCE_TO_CANVAS'
> = ({
  data: {
    actionType,
    panelType,
    traceKey = 'DEFAULT',
    resourceIdx,
    resourceType,
    groupSequence,
    groupItemSequence,
    searchKeywordCategory,
  },
}): DesignResourceActionEventPayload => {
  const eventName = getEventName('action', panelType);
  return refinePayloadProperties({
    ...makeGACommonPayload(panelType, traceKey),
    action_method: actionType,
    action_item: resourceIdx,
    action_item_type: resourceType,
    priority: groupSequence,
    priority2: groupItemSequence,
    search_keyword_category: searchKeywordCategory,
    log_type: eventName,
    event: eventName,
  });
};

// 비슷한 요소 찾기 시 제외할 프로퍼티 목록
const excludedPropertyNamesOnSearchSimilarResource = [
  'search_term',
  'search_color',
  'search_shape',
  'search_file_type',
  'search_photo_type',
  'search_element_type',
  'search_text_type',
  'search_background_type',
  'search_audio_category',
  'search_audio_type',
  'search_audio_tempo',
] satisfies (
  | keyof (DesignResourceIntegratedGAEventPayload &
      PictureGAEventPayload &
      ElementGAEventPayload &
      TextGAEventPayload &
      BackgroundGAEventPayload &
      AudioGAEventPayload &
      VideoGAEventPayload)
)[];

const refinePayloadProperties = <T extends DesignResourceIntegratedGAEventPayload>(
  payload: T
): T => {
  if (
    (['비슷한리소스찾기', '비슷한리소스찾기더보기'] satisfies SearchType[]).includes(
      payload.search_type
    )
  ) {
    return Object.entries(payload).reduce<T>(
      (acc, [k, v]) => ({
        ...acc,
        [k]: excludedPropertyNamesOnSearchSimilarResource.includes(k) ? undefined : v,
      }),
      {} as T
    );
  }
  return payload;
};
