import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';

export const makeGAParamsActionViewSignUpNudgePopup: GAParameterMakerFnType<
  'ACTION_VIEW_SIGN_UP_NUDGE_POPUP'
> = ({ data: { location1 } }) => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1,
    action1: '뷰',
    label1: '글로벌신규유입_가입유도팝업_뷰',
  };
};
