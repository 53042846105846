export const BuildEnv = {
  NEXT_PUBLIC_ENV: process.env.NEXT_PUBLIC_ENV,
  /**
   * 로컬이냐 vs 스테이징, 프로덕션이냐
   */
  IS_LOCAL: process.env.NODE_ENV !== 'production' || process.env.NEXT_PUBLIC_LOCAL_PROD === 'Y',
  /**
   * 프로덕션이냐 vs 로컬, 스테이징이냐
   */
  IS_PRODUCTION: process.env.NEXT_PUBLIC_ENV === 'production',
  /**
   * 스테이징이냐
   */
  IS_STAGING: process.env.NEXT_PUBLIC_ENV === 'staging',
  /**
   * 개발이냐
   */
  IS_DEVELOP: process.env.NEXT_PUBLIC_ENV === 'develop',
  NEXT_PUBLIC_LOCAL_PROD: process.env.NEXT_PUBLIC_LOCAL_PROD,

  DOMAIN: process.env.DOMAIN,

  NEXT_PUBLIC_USE_PROXY: process.env.NEXT_PUBLIC_USE_PROXY,

  NEXT_PUBLIC_PAYMENT_GATEWAY_API_URL: process.env.NEXT_PUBLIC_PAYMENT_GATEWAY_API_URL,

  NEXT_PUBLIC_PAYMENT_GATEWAY_SCRIPT_URL: process.env.NEXT_PUBLIC_PAYMENT_GATEWAY_SCRIPT_URL,
  NEXT_PUBLIC_APP_VERSION: process.env.NEXT_PUBLIC_APP_VERSION,
} as const;
