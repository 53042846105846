import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import { match } from 'ts-pattern';
import { useCallback } from 'react';
import { ElementDesignResourceType } from '@miri-unicorn/miricanvas-api-client';
import { ELEMENT_TAB_TYPE } from '@business/design_resource_bar/tab/_constants/elementTabType';
import { disabledLauncherParamEditorFeatureAtom } from '@business/editor/editor_feature_limitations/_stores/useEditorFeature';
import { getRootStore } from '@configs/jotai/RootProvider';
import { SearchFilterColorList } from '@ui/common/_constants/SearchFilterColorList';
import { CustomColorId, getFilterColorById } from '@ui/common/_utils/color_filter_util';
import { panelSearchKeywordAtomFamily } from '@ui/design/_common/design_resource_panel/_stores/_common/panelSearchKeywordAtomFamily';
import { isSearchingRecentPanelAtomFamily } from '@ui/design/_common/design_resource_panel/_stores/_common/isSearchingRecentInPanelAtomFamily';

const keywordAtom = panelSearchKeywordAtomFamily('ELEMENTS');
const typeListAtom = atom<ElementDesignResourceType[]>(ELEMENT_TAB_TYPE[0].designResourceTypes);
const filteredTypeListAtom = atom((get) => {
  const disabledFeatures = get(disabledLauncherParamEditorFeatureAtom);
  return get(typeListAtom).filter((type) =>
    match(type)
      .with('ANI', 'CONTRACTED_ANI', 'EXTERNAL_ANI', () => !disabledFeatures.has('TAB_ANI'))
      .otherwise(() => true)
  );
});
const filteredColorIdxAtom = atom<number | CustomColorId | null>(null);
const filteredCustomColorAtom = atom<string | null>(null);

export const elementSearchAtom = atom((get) => ({
  keyword: get(keywordAtom),
  typeList: get(filteredTypeListAtom),
  color:
    getFilterColorById(
      SearchFilterColorList,
      get(filteredCustomColorAtom),
      get(filteredColorIdxAtom)
    )?.replace(/^#/g, '') ?? '',
}));

const isSearchingRecentAtom = isSearchingRecentPanelAtomFamily('ELEMENTS');

export const useElementPanelSearchStore = () => {
  const [elementKeyword, setElementKeyword] = useAtom(keywordAtom);
  const elementTypeList = useAtomValue(filteredTypeListAtom);
  const setElementTypeList = useSetAtom(typeListAtom);
  const [filteredColorIdx, setFilteredColorIdx] = useAtom(filteredColorIdxAtom);
  const [filteredCustomColor, setFilteredCustomColor] = useAtom(filteredCustomColorAtom);

  const [isSearchingRecent, setIsSearchingRecent] = useAtom(isSearchingRecentAtom);

  const resetFilter = useCallback(
    (initialTypeList: ElementDesignResourceType[]) => {
      setElementTypeList(initialTypeList);
      setFilteredColorIdx(null);
      setFilteredCustomColor(null);
    },
    [setElementTypeList, setFilteredColorIdx, setFilteredCustomColor]
  );

  return {
    elementKeyword,
    setElementKeyword,
    elementTypeList,
    setElementTypeList,
    isSearchingRecent,
    setIsSearchingRecent,
    filteredColorIdx,
    setFilteredColorIdx,
    filteredCustomColor,
    setFilteredCustomColor,
    resetFilter,
  };
};

export const useElementSearchParam = () => useAtomValue(elementSearchAtom);

export const getElementSearchKeywordSnapshotValue = () => getRootStore().get(keywordAtom);
/** 요소 패널 필터에서 적용된, #rgb 색상 스트링 반환 */
export const getElementFilteredColorSnapshotValue = () =>
  getFilterColorById(
    SearchFilterColorList,
    getRootStore().get(filteredCustomColorAtom),
    getRootStore().get(filteredColorIdxAtom)
  )?.replace(/^#/g, '') ?? '';
export const getElementTypeListSnapshotValue = () => getRootStore().get(filteredTypeListAtom);
