import { IconName } from '@miri-unicorn/miricanvas-ds';
import { DesignResourcePanelMenuType } from '@constants/DesignResourcePanelMenu';

/** 디자인 리소스 메뉴 탭의 텍스트 Map */
export const designResourcePanelMenuTextMap: Record<DesignResourcePanelMenuType, string> = {
  TEMPLATE: 'EDITOR.LEFT_MENU.EDITOR_FEATURE.TAB_TEMPLATE',
  CREATOR: 'EDITOR.EDITOR_354',
  WORKSPACE: 'EDITOR.LEFT_MENU.EDITOR_FEATURE.TAB_WORK',
  PICTURE: 'EDITOR.LEFT_MENU.EDITOR_FEATURE.TAB_PHOTO_GROUP',
  ELEMENTS: 'EDITOR.LEFT_MENU.EDITOR_FEATURE.TAB_ELEMENT',
  TEXT: 'EDITOR.LEFT_MENU.EDITOR_FEATURE.TAB_TEXT',
  AUDIO: 'EDITOR.LEFT_MENU.EDITOR_FEATURE.TAB_AUDIO',
  UPLOAD: 'EDITOR.LEFT_MENU.EDITOR_FEATURE.TAB_UPLOAD',
  BACKGROUND: 'EDITOR.LEFT_MENU.EDITOR_FEATURE.TAB_BACKGROUND',
  VIDEO: 'EDITOR.LEFT_MENU.EDITOR_FEATURE.TAB_VIDEO',
  AIDRAWING: 'EDITOR.EDITOR_628',
  FAVORITES: 'EDITOR.LEFT_MENU.EDITOR_FEATURE.TAB_PICK',
  LOGO: 'EDITOR.LEFT_MENU.EDITOR_FEATURE.TAB_LOGO',
  QRBARCODE: 'EDITOR.LEFT_MENU.EDITOR_FEATURE.TAB_QRANDBARCODE',
  THEME: 'EDITOR.LEFT_MENU.EDITOR_FEATURE.TAB_THEME',
  DATA: 'EDITOR.CHART_LABEL_DATA',
  // 일본 온보딩 튜토리얼 아이콘
  TUTORIAL: '',
};

/** 디자인 리소스 메뉴 탭의 아이콘 Map */
export const designResourcePanelMenuIconMap: Record<DesignResourcePanelMenuType, IconName> = {
  TEMPLATE: 'template_twotone',
  CREATOR: 'icon_creator',
  WORKSPACE: 'box_twotone',
  PICTURE: 'image_twotone',
  ELEMENTS: 'element_2_twotone',
  UPLOAD: 'cloud_upload_twotone',
  TEXT: 'text_3_twotone',
  AUDIO: 'music_note_twotone',
  BACKGROUND: 'background_twotone',
  VIDEO: 'play_circle_twotone',
  AIDRAWING: 'ai_tools',
  FAVORITES: 'heart_twotone',
  LOGO: 'brand_twotone',
  QRBARCODE: 'qr_code_twotone',
  THEME: 'color_palette_twotone',
  DATA: 'chart_twotone',
  // 일본 온보딩 튜토리얼 아이콘
  TUTORIAL: 'graduation_cap_badge',
};

export const designResourcePanelMenuKorMap: Record<DesignResourcePanelMenuType, string> = {
  TEMPLATE: '템플릿',
  CREATOR: '크리에이터',
  WORKSPACE: '작업공간',
  PICTURE: '사진',
  ELEMENTS: '요소',
  UPLOAD: '업로드',
  TEXT: '텍스트',
  AUDIO: '오디오',
  BACKGROUND: '배경',
  VIDEO: '비디오',
  AIDRAWING: 'AI 드로잉',
  FAVORITES: '찜하기',
  LOGO: '로고',
  QRBARCODE: 'QR/바코드',
  THEME: '테마',
  DATA: '데이터',
  // 일본 온보딩 튜토리알
  TUTORIAL: '튜토리얼',
};
