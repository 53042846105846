import { GAParam } from '@configs/google_analytics/types/GACommonTypes';
import { GAParameterMakerFnType } from '@configs/google_analytics/types/GAParameterMakerFnType';
import { GTMTriggerEventParams } from '@configs/google_analytics/types/GTMTriggerEventParams';

type ActionClickEditorResizeButtonParams = GAParam<GTMTriggerEventParams>;

export const makeGAParamsActionClickAILayoutEntry: GAParameterMakerFnType<
  'ACTION_CLICK_AI_LAYOUT_ENTRY'
> = () => {
  return {
    event: 'gtm_trigger_event',
    log_type: 'generic_event_mican',
    location1: '에디터',
    location2: 'AI 플로팅',
    location3: 'AI 레이아웃',
    action1: '클릭',
    label1: '에디터_AI플로팅_AI레이아웃',
  } satisfies ActionClickEditorResizeButtonParams;
};
