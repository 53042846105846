import { LogUtil } from '@miri-unicorn/miricanvas-utils/functions';
import { WebViewEventRegistry, WebViewEventType } from '@miri-unicorn/miricanvas-webview';

/**
 * 웹 -> 앱
 * @description 웹에서 이벤트가 발생하면 앱으로 데이터 전달하는 함수
 */
export const emitWebViewEvent = <
  EVENT_TYPE extends WebViewEventType,
  PAYLOAD extends WebViewEventRegistry[EVENT_TYPE]
>(
  type: EVENT_TYPE,
  payload: PAYLOAD
) => {
  LogUtil.log.dev('emitWebViewEvent', { type, payload });
  if (typeof window === 'undefined' || !window?.ReactNativeWebView) {
    return;
  }
  window?.ReactNativeWebView?.postMessage(JSON.stringify({ type, payload }));
};
